import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";

import { SnackbarContent, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import CustomModal from "~/common/components/Modal";
import { useRegisterServiceWorker } from "~/common/hooks/useRegisterServiceWorker";

import { useIsStaffApp } from "@/common/hooks";

import * as colors from "../../../common/theming/colors";
import CustomButton from "../TrackedComponents/Button";

const intervalMinutes = (import.meta.env.VITE_SERVICE_WORKER_UPDATE_INTERVAL_MINUTES ||
  5) as number;
const intervalMS = 1000 * 60 * intervalMinutes;

const ReloadPromptModal = () => {
  const { needRefresh, updateServiceWorker } = useRegisterServiceWorker();

  const isStaff = useIsStaffApp();

  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [skipRefresh, setSkipRefresh] = useState(false);
  const [forceRefreshCheck, setForceRefreshCheck] = useState(false);

  useEffect(() => {
    if (needRefresh) {
      // If the user is staff, show the modal and interrupt the user immediately
      // Otherwise, show a snackbar at the top and don't interrupt the user unless they so choose
      isStaff ? setShowRefreshModal(true) : setShowSnackbar(!showRefreshModal);
      setForceRefreshCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh, forceRefreshCheck]);

  useEffect(() => {
    if (skipRefresh) {
      Sentry.addBreadcrumb({
        message: `Skipping service worker refresh for ${intervalMinutes} minutes`,
      });
      setTimeout(() => {
        setSkipRefresh(false);
        setForceRefreshCheck(true);
        Sentry.addBreadcrumb({
          message: `Re-enabling service worker refresh via setTimeout`,
          data: { skipRefresh, forceRefreshCheck },
        });
      }, intervalMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipRefresh]);

  const closeModal = () => {
    setSkipRefresh(true);
    setShowRefreshModal(false);
  };

  const closeSnackbar = () => {
    setSkipRefresh(true);
    setShowSnackbar(false);
  };

  const confirmRefresh = () => {
    setShowRefreshModal(false);
    setShowSnackbar(false);
    void updateServiceWorker(true);
  };

  return (
    <>
      <CustomModal
        isOpen={showRefreshModal}
        primaryBtnText="Update"
        secondaryBtnText="Later"
        onSecondaryBtnClick={closeModal}
        closeDisabled={false}
        onClose={closeModal}
        modalHeaderText={"M7 Update Available"}
        primaryDisabled={false}
        onSubmit={confirmRefresh}
        variant={!isStaff ? "warning" : undefined}
        variantText={!isStaff ? "You will lose unsaved changes when you update." : undefined}
        withBlurBackground={true}
        modalContent={
          <>
            <Typography sx={{ marginTop: 3 }}>
              A new version of M7 is available! Please update your app to get the latest.
            </Typography>
            <Typography sx={{ marginTop: 3 }}>
              If you choose to update later you will be prompted again in {intervalMinutes} minutes.
            </Typography>
          </>
        }
      />
      <Snackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={intervalMS * 0.9}
      >
        <SnackbarContent
          sx={{ backgroundColor: colors.darkPurple, color: colors.white }}
          message="A new version of M7 is available!"
          action={
            <CustomButton
              color="error"
              sx={{ color: colors.white }}
              size="small"
              onClick={() => {
                setShowRefreshModal(true);
                setShowSnackbar(false);
              }}
            >
              Update Now
            </CustomButton>
          }
        />
      </Snackbar>
    </>
  );
};

export default ReloadPromptModal;
