import React from "react";

import { Dayjs } from "dayjs";

import { ISchedule, IStaffShift, StaffDetails } from "~/api";
import { EStaffTypeName, KeyBy, Uuid } from "~/common/types";
import { IShift, IStaffAttributeCustomSelect } from "~/routes/api/types";

import { DeprecatedTShift } from "#/features/SchedulerGrid/types";

import { ICreateShiftSwapRequest } from "../ShiftSwapRequest/types";

export enum EStaffScheduleTypes {
  published = "current",
  /** @deprecated, use published instead */
  Current = "current",
  planning = "planning",
  /** @deprecated, use planning instead */
  Planning = "planning",
}

export interface IPreference {
  date: string;
  shiftTypeKey: DeprecatedTShift;
}

export interface IShiftDetailsSwappingProps {
  date: Dayjs;
}

export interface IPotentialSwapItemProps {
  scheduleId: string;
  requesterId: string;
  requesterShiftDate: Dayjs;
  deciderId: string;
  deciderShiftDate: Dayjs;
  shiftTypeKey: DeprecatedTShift;
  deciderName: string;
  deciderStaffType: EStaffTypeName;
  shiftToSwapWithData: ICreateShiftSwapRequest | null;
}

export interface ISubmitSwapPanelProps {
  shiftToSwapWithData: ICreateShiftSwapRequest | null;
  personToSwapWith: string | null;
  setSubmittingSwap: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface IStaffListProps {
  staffTypeSelected: string;
  staffShifts: IStaffShift[];
  staffsDetails: KeyBy<StaffDetails.DTO, "userId">;
  staffAttributesByKeyInUnit: Record<string, IStaffAttributeCustomSelect>;
  selectedShiftType: IShift;
}

export interface ICalendar {
  [index: string]: {
    days: Dayjs[];
  };
}

export interface IOpenShifts {
  shiftType: IShift["key"];
  status: "open" | "pending";
  date: string;
  id: string;
  notes?: string;
  requestId?: string;
  unitId?: Uuid;
  unitName?: string;
  scheduleId: Uuid;
}

export interface ISwitcherProps {
  schedules: (IFutureSchedule | ISchedule)[];
  defaultScheduleDurationInWeeks: number;
}

export interface ISelectedOpenShiftData {
  id: string;
  dateKey: string;
  scheduleId: string;
  shiftTypeKey: DeprecatedTShift;
  requestId?: string;
}

export type IFutureSchedule = Pick<
  ISchedule,
  "staffsInputDeadline" | "startDay" | "endDay" | "status" | "employmentTypesOpenTo"
>;

export type ICalendarState = {
  scheduleType: EStaffScheduleTypes;
  selectedTiles: string[];
  selectedSchedule: ISchedule | IFutureSchedule | undefined;
  displayOpenShifts: boolean;
  shiftDetailsOpen: string | null;
  areShiftNotesExpanded: boolean;
  isRequestOpenShiftModalOpen: boolean;
  selectedOpenShiftData: ISelectedOpenShiftData | null;
  isUnrequestOpenShiftModalOpen: boolean;
  dropShiftData: {
    isConfirmModalOpen: boolean;
  };
  swapShiftData: {
    swapping: boolean;
    submittingSwap: boolean;
    dateToSwapWith: Dayjs | null;
    shiftToSwapWithData: ICreateShiftSwapRequest | null;
    personToSwapWith: string | null;
  };
  timeOffRequestModal: {
    isOpen: boolean;
  };
};
