import { Action, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { ICommonState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  selectedUnit: null,
  unitModalType: "",
};

interface RehydrateAction extends Action<typeof REHYDRATE> {
  payload: ICommonState;
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSelectedUnit: (state, action: PayloadAction<ICommonState["selectedUnit"]>) => {
      state.selectedUnit = action.payload;
    },
    setUnitModal: (state, action: PayloadAction<ICommonState["unitModalType"]>) => {
      state.unitModalType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      state = { ...action.payload };
    });
  },
});

export const { setSelectedUnit, setUnitModal } = commonSlice.actions;

const persistConfig = {
  key: "common",
  storage,
};

export default persistReducer(persistConfig, commonSlice.reducer);
