/* eslint-disable no-console */

import { memo, useMemo } from "react";

import dayjs from "dayjs";

import { Grid } from "@mui/material";

import { StaffShift } from "~/api";
import Shift from "~/common/components/Shift";
import Tag from "~/common/components/Tag";
import { FLOATING_ICON, iconComponentForShift } from "~/common/constants";
import { multipleShiftsColor, workingAwayStaffColor } from "~/common/theming/colors";
// eslint-disable-next-line deprecate/import
import { YyyyMmDd } from "~/common/types";

import { backgroundColorForUpdateOnSchedulerGrid } from "@/common/components/NoteUpdateTag/helpers";

export const _SchedulerScheduleTableStaffShiftCell = memo(
  ({
    staffId,
    date: formattedDate,
    shiftInfo,
    scheduleId,
  }: {
    staffId: string;
    date: YyyyMmDd;
    shiftInfo: StaffShift.DTO[] | undefined;
    scheduleId: string;
  }) => {
    const hasMultipleShifts = shiftInfo && shiftInfo.length > 1;
    const firstStatus = shiftInfo && shiftInfo.find((shift) => shift.status)?.status;
    const firstShift = shiftInfo && shiftInfo[0];
    const shiftKey = firstShift?.shiftTypeKey;
    const isWorkingHome = firstShift?.scheduleId === scheduleId;
    const MultiIcon = iconComponentForShift("Multi");

    const classNames = useMemo(() => {
      const names = [
        "shift-cell",
        "staff-id-" + staffId,
        "day-" + formattedDate,
        shiftKey ? "shift-type-" + shiftKey : "_NO_SHIFT_",
      ];
      if (dayjs(formattedDate).day() === 6) names.push("sunday");

      return names;
    }, [staffId, shiftKey, formattedDate]);

    return (
      <td
        className={classNames.join(" ")}
        key={`${formattedDate} - ${staffId}-${shiftKey || "no-shift"}`}
        data-date={formattedDate}
        data-staff-id={staffId}
        data-shift-key={shiftKey || "__no-shift__"}
      >
        <div className="shift-tile-cell-wrapper">
          <div className="inside-cell">
            <div className={"working-shift"}>
              {hasMultipleShifts && (
                <Tag
                  backgroundColor={multipleShiftsColor}
                  className="shift-tile"
                  icon={<MultiIcon />}
                  miniView
                />
              )}
              {!hasMultipleShifts &&
                shiftKey &&
                (!isWorkingHome ? (
                  <Shift
                    fullWidth
                    scheduleId={firstShift?.scheduleId}
                    miniView={true}
                    customLabel={"Working Away"}
                    customColor={workingAwayStaffColor}
                    customIcon={<FLOATING_ICON />}
                    option={"_FLOATED_SHIFT_"}
                  />
                ) : (
                  <Shift
                    fullWidth
                    miniView={true}
                    scheduleId={scheduleId}
                    option={shiftKey}
                    hoursVariant="short"
                  />
                ))}
              {firstStatus && (
                <Grid
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: 10,
                    height: 10,
                    borderRadius: 0.8,
                    backgroundColor: backgroundColorForUpdateOnSchedulerGrid(firstStatus),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </td>
    );
  },
);
