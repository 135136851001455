import { PayloadAction } from "@reduxjs/toolkit";

import { IScheduleShiftType, IStaffDetails, IStaffShift, IWorkingHours, StaffDetails } from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState } from ".";

export type PageData = {
  staffShifts: IStaffShift[];
  shiftTypes: Record<IScheduleShiftType["scheduleId"], KeyBy<IScheduleShiftType, "key">>;
  staffDetails: KeyBy<IStaffDetails, "userId">;
  staffWorkingHoursByStaffId: KeyBy<IWorkingHours, "staffId">;
  metadataByStaffId: KeyBy<StaffDetails.ShiftMetadataDTO, "staffId">;
};

export const pageDataInitState: PageData = {
  staffShifts: [],
  shiftTypes: {},
  staffDetails: {},
  staffWorkingHoursByStaffId: {},
  metadataByStaffId: {},
};

export const PageDataActions = {
  setData: (state: THouseViewState, action: PayloadAction<Partial<PageData>>) => {
    state.pageData = { ...state.pageData, ...action.payload };
  },

  setShiftTypes: ({ pageData }: THouseViewState, action: PayloadAction<PageData["shiftTypes"]>) => {
    pageData.shiftTypes = action.payload;
  },

  setStaffShifts: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["staffShifts"]>,
  ) => {
    pageData.staffShifts = action.payload;
  },

  setStaffDetails: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["staffDetails"]>,
  ) => {
    pageData.staffDetails = action.payload;
  },
};
