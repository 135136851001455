import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { setCurrentRole } from "~/features/User/store";

import { ERoles } from "#/features/User/types";

/**
 * Defines the priority order for default roles upon login.
 * Lower numbers indicate higher priority.
 *
 * @type {Record<ERoles, number>}
 */
const defaultRoleOnLogInPriority: Record<ERoles, number> = {
  [ERoles.Admin]: 0,
  [ERoles.Scheduler]: 1,
  [ERoles.Staff]: 2,
  [ERoles.Kiosk]: 3,
};

export const useSetCurrentRole = () => {
  const dispatch = useAppDispatch();
  const { currentRole, roles } = useAppSelector((state) => ({
    currentRole: state.user.currentRole,
    roles: state.user.userData.roles,
  }));

  useEffect(() => {
    const sortedRoles = [...roles].sort(
      (aRole, bRole) => defaultRoleOnLogInPriority[aRole] - defaultRoleOnLogInPriority[bRole],
    );
    // if don't yet have a current role, set the correct role in order of priority
    // else if the current role is not in the list of roles, set the correct role in order of priority (eg if someone is removed from a role)
    if (!currentRole) {
      dispatch(setCurrentRole(sortedRoles[0]));
    } else if (!roles.includes(currentRole)) {
      dispatch(setCurrentRole(sortedRoles[0]));
    }
  }, [roles, currentRole, dispatch]);
};
