import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Grid, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker, TimeValidationError } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UsePickerValueBaseProps } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";

import CustomCollapse from "~/common/components/Collapse";
import CustomButton from "~/common/components/TrackedComponents/Button";
import CustomSwitch from "~/common/components/TrackedComponents/Switch";
import { black, darkOrange } from "~/common/theming/colors";
import { TimeString, seconds } from "~/common/types";
import { dateToMilitary, dateToTimeString, msToMilitary, timeAdd } from "~/common/utils/dates";
import { IShift } from "~/routes/api/types";

import { IShiftType, IStaffShift } from "@/api";

type ShiftUpdates = {
  customStartTime?: TimeString | null;
  customDuration?: seconds | null;
};

const t24HoursInMs = 24 * 60 * 60 * 1000;

export const PartialShiftTimePicker = ({
  staffShift,
  shiftType,
  readonly = false,
  onChange,
  withActions = false,
  alwaysOn = false,
}: {
  staffShift: ShiftUpdates | IStaffShift | undefined | null;
  shiftType: IShift | IShiftType | undefined | null;
  onChange: (shift: ShiftUpdates, isValid: boolean) => void;
  readonly?: boolean;
  withActions?: boolean;
  alwaysOn?: boolean;
}) => {
  // Shift basic start and end times
  const shiftFrom = dayjs(`2022-04-17T${shiftType?.startTime || "00:00:00.000"}`);
  const shiftTo = shiftFrom.add(shiftType?.durationSeconds || 0, "seconds");

  // defaults for time pickers
  const startTime = shiftType?.startTime || "00:00:00.000";
  const defaultFrom = useMemo(
    () => dayjs(`2022-04-17T${staffShift?.customStartTime || startTime}`),
    [staffShift?.customStartTime, startTime],
  );
  const defaultTo = useMemo(() => {
    const sameDayTo = dayjs(
      `2022-04-17T${timeAdd(
        staffShift?.customStartTime || startTime,
        staffShift?.customDuration || shiftType?.durationSeconds || 0,
      )}`,
    );
    if (sameDayTo.isBefore(defaultFrom)) {
      return sameDayTo.add(1, "day");
    } else {
      return sameDayTo;
    }
  }, [
    defaultFrom,
    shiftType?.durationSeconds,
    staffShift?.customDuration,
    staffShift?.customStartTime,
    startTime,
  ]);

  const [isPartialShift, setIsPartialShift] = useState(
    !!(staffShift?.customStartTime || staffShift?.customDuration || alwaysOn),
  );
  const [fromTimeValue, setFromTimeValue] = useState(defaultFrom);
  const [toTimeValue, setToTimeValue] = useState(defaultTo);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [hasRegularTimes, setHasRegularTimes] = useState(false);

  // Update a shift
  const shiftEditHandler = useCallback(
    (updates: ShiftUpdates, isValid: boolean) => onChange({ ...staffShift, ...updates }, isValid),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(staffShift), onChange],
  );

  const setPartialShift = useCallback(
    (setTo: boolean) => {
      setIsPartialShift(setTo);

      if (!setTo) {
        shiftEditHandler(
          {
            customStartTime: null,
            customDuration: null,
          },
          true,
        );
      } else if (!withActions) {
        shiftEditHandler(
          {
            customStartTime: startTime,
            customDuration: shiftType!.durationSeconds as seconds,
          },
          false,
        );
      }
    },
    [shiftEditHandler, withActions, shiftType, startTime],
  );

  const timeValues = {
    from: fromTimeValue,
    to: toTimeValue,
    setFrom: setFromTimeValue,
    setTo: setToTimeValue,
  };

  const timePickers = helpers({
    timeValues,
    readonly,
  });

  useEffect(() => {
    if (isPartialShift) {
      let isNewTimeInvalid = false;

      let delta = toTimeValue.diff(fromTimeValue);
      if (delta === 0) delta = t24HoursInMs;

      const start = dateToMilitary(fromTimeValue);

      if (!shiftType?.durationSeconds || !shiftType?.startTime) {
        isNewTimeInvalid = true;
      } else {
        let from = dayjs(`2022-04-17T${start}`);
        if (from.isBefore(shiftFrom)) {
          from = from.add(1, "day");
        }
        const to = from.add(delta, "ms");

        // If start time is before shift start time, out of boundaries
        if (from.isBefore(shiftFrom)) isNewTimeInvalid = true;

        // If end time is after shift end time, out of boundaries
        if (to.isAfter(shiftTo)) isNewTimeInvalid = true;

        // If ent times and start times are the same, invalid
        if (from.isSame(to)) isNewTimeInvalid = true;
      }

      setIsInvalid(isNewTimeInvalid);
    } else {
      setIsInvalid(false);
    }
  }, [
    alwaysOn,
    isPartialShift,
    fromTimeValue,
    toTimeValue,
    shiftType?.durationSeconds,
    shiftType?.startTime,
    defaultFrom,
    defaultTo,
    staffShift,
    shiftFrom,
    shiftTo,
  ]);

  // Reset state when shift type changes
  useEffect(() => {
    if (!defaultFrom.isSame(fromTimeValue)) {
      setFromTimeValue(defaultFrom);
    }
    if (!defaultTo.isSame(toTimeValue)) {
      setToTimeValue(defaultTo);
    }
    setIsPartialShift(!!(staffShift?.customStartTime || staffShift?.customDuration || alwaysOn));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    alwaysOn,
    defaultFrom.format("dd HH:mm"),
    defaultTo.format("dd HH:mm"),
    Boolean(staffShift?.customDuration),
    Boolean(staffShift?.customStartTime),
  ]);

  useEffect(() => {
    if (fromTimeValue.isSame(defaultFrom) && toTimeValue.isSame(defaultTo)) {
      setIsSame(true);
    } else {
      setIsSame(false);
    }
    if (fromTimeValue.isSame(shiftFrom) && toTimeValue.isSame(shiftTo)) {
      setHasRegularTimes(true);
    } else {
      setHasRegularTimes(false);
    }
  }, [
    defaultFrom.format("dd HH:mm"),
    defaultTo.format("dd HH:mm"),
    fromTimeValue.format("dd HH:mm"),
    toTimeValue.format("dd HH:mm"),
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const refreshValues = useCallback(() => {
    setFromTimeValue(defaultFrom);
    setToTimeValue(defaultTo);
  }, [defaultFrom, defaultTo]);

  const notCustom = fromTimeValue.isSame(shiftFrom) && toTimeValue.isSame(shiftTo) && !alwaysOn;

  const saveCustomTimes = useCallback(() => {
    if (!isPartialShift) return;
    if (isInvalid && withActions) return;
    if (isSame && !alwaysOn) return;

    const customStartTime = dateToTimeString(fromTimeValue);
    const customDuration = (toTimeValue.diff(fromTimeValue) / 1000) as seconds;

    if (notCustom) {
      if (withActions) {
        shiftEditHandler(
          {
            customStartTime: null,
            customDuration: null,
          },
          !isInvalid,
        );
      } else {
        shiftEditHandler(
          {
            customStartTime: customStartTime,
            customDuration: customDuration,
          },
          false,
        );
      }
    } else {
      shiftEditHandler(
        {
          customStartTime: customStartTime,
          customDuration: customDuration,
        },
        !isInvalid,
      );
    }
  }, [
    alwaysOn,
    fromTimeValue,
    toTimeValue,
    isPartialShift,
    isInvalid,
    isSame,
    shiftEditHandler,
    withActions,
    notCustom,
  ]);

  // Automatic save on each change if no actions
  useEffect(() => {
    if (!withActions) {
      saveCustomTimes();
    }
  }, [fromTimeValue, toTimeValue, saveCustomTimes, withActions]);

  return (
    <>
      <Grid item container xs={12} justifyContent="flex-end" alignContent={"center"}>
        <Grid item container xs={12} justifyContent="flex-end" alignContent={"center"}>
          <Grid item xs={6} container justifyContent="flex-start" alignContent={"center"}>
            {isPartialShift && !alwaysOn && (
              <Typography fontSize={15}>
                {msToMilitary(toTimeValue.diff(fromTimeValue))
                  .slice(0, -7)
                  .replace(/:/, "h ")
                  .replace(/^0/, "")}
                m
              </Typography>
            )}
          </Grid>
          {!alwaysOn && (
            <Grid item xs={6} container justifyContent="flex-end" alignContent={"center"}>
              <CustomSwitch
                style={{
                  alignSelf: "flex-end",
                  mr: 0,
                }}
                disabled={readonly || !shiftType}
                checked={isPartialShift}
                label="Partial shift"
                name="partialShift"
                onChange={setPartialShift}
              />
            </Grid>
          )}
        </Grid>
        <CustomCollapse
          hideToggle={true}
          styles={{
            mb: 0,
            mt: 0,
            ".MuiCollapse-root": { marginLeft: 0 },
          }}
          isOpen={isPartialShift && !!shiftType}
          id="is-partial-shift"
          label=""
          onChange={(_id, isExpanded) => setIsPartialShift(isExpanded)}
          content={
            !isPartialShift || !shiftType ? (
              <></>
            ) : (
              <Grid item container xs={12} display={"flex"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid
                    item
                    xs={6}
                    mt={1}
                    sx={{ paddingRight: 1, width: 0, input: { fontSize: "13px", p: 1.5 } }}
                  >
                    {timePickers.fromTimePicker()}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    mb={1}
                    mt={1}
                    sx={{ paddingLeft: 1, width: 0, input: { fontSize: "13px", p: 1.5 } }}
                  >
                    {timePickers.toTimePicker()}
                  </Grid>
                </LocalizationProvider>
                {!readonly && (
                  <Grid item xs={12} width={0} justifyContent={"end"} display={"flex"} mt={1}>
                    <Grid item container xs={4} alignItems={"center"}>
                      <Typography
                        variant="small"
                        color={notCustom ? darkOrange : "error"}
                        sx={{ opacity: (isInvalid && shiftType) || notCustom ? 1 : 0 }}
                      >
                        {isInvalid ? "Invalid time" : "Adjust time"}
                      </Typography>
                    </Grid>
                    {withActions && (
                      <>
                        <CustomButton
                          style={{
                            minWidth: "35px",
                            padding: "0px 0px !important",
                            ".MuiButton-startIcon": { marginRight: 0 },
                          }}
                          startIcon={<RefreshIcon />}
                          variant="text"
                          onClick={refreshValues}
                          trackingLabel="Reset shift details"
                        />
                        <CustomButton
                          style={{ marginLeft: "10px" }}
                          label={hasRegularTimes && !isSame ? "Remove partial" : "Update shift"}
                          onClick={saveCustomTimes}
                          disabled={isInvalid || isSame}
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            )
          }
        />
      </Grid>
    </>
  );
};

const helpers = ({
  timeValues,
  readonly,
}: {
  timeValues: {
    from: Dayjs;
    to: Dayjs;
    setFrom: (newVal: Dayjs) => void;
    setTo: (newVal: Dayjs) => void;
  };
  readonly: boolean;
}) => {
  return {
    basicTimePicker: function ({
      label,
      value,
      onChange,
    }: {
      label: string;
      value: dayjs.Dayjs;
      onChange: UsePickerValueBaseProps<dayjs.Dayjs | null, TimeValidationError>["onChange"];
    }) {
      return (
        <TimePicker
          slotProps={{ layout: { classes: { root: "hide-disabled-options" } } }}
          minutesStep={30}
          label={label}
          value={value}
          onChange={onChange}
          closeOnSelect={false}
          readOnly={readonly}
          disabled={readonly}
          sx={{
            input: { paddingRight: "0px" },
            ".MuiIconButton-root": { paddingLeft: "0px" },
            ...(readonly ? { "input.Mui-readOnly": { "-webkit-text-fill-color": black } } : {}),
          }}
        />
      );
    },

    fromTimePicker: function () {
      return this.basicTimePicker({
        label: "Start time",
        value: timeValues.from,
        onChange: (date, _context) => {
          if (date && dayjs(date).isValid()) {
            if (timeValues.to.diff(date) === 0) {
              timeValues.setFrom(date.add(1, "day"));
            } else if (timeValues.to.diff(date) > t24HoursInMs) {
              timeValues.setFrom(date.add(1, "day"));
            } else if (timeValues.to.diff(date) < 0) {
              timeValues.setFrom(date.add(-1, "day"));
            } else {
              timeValues.setFrom(date);
            }
          }
        },
      });
    },

    toTimePicker: function () {
      return this.basicTimePicker({
        label: "End time",
        value: timeValues.to,
        onChange: (date, _context) => {
          if (date && dayjs(date).isValid()) {
            if (timeValues.from.diff(date) === 0) {
              timeValues.setTo(date.add(-1, "day"));
            } else if (date.diff(timeValues.from) > t24HoursInMs) {
              timeValues.setTo(date.add(-1, "day"));
            } else if (date.diff(timeValues.from) < 0) {
              timeValues.setTo(date.add(1, "day"));
            } else {
              timeValues.setTo(date);
            }
          }
        },
      });
    },
  };
};
