import { YyyyMmDd } from "@m7-health/shared-utils";

import { EShiftStatusOrNull } from "~/features/DailyReports/types";
import { IUser } from "~/features/User/types";
import { IShift } from "~/routes/api/types";

import { TDateFilter } from "@/common/types";

import { IStaff } from "../../features/Roster/types";
import { APIListResponse, SDTO } from "../_shared/types";
import { ISchedule } from "../schedule";
import { Unit } from "../unit";

export type INote = Note.DTO;

export namespace Note {
  /** @deprecated use StaffShift.EStatus instead */
  export enum EUpdate {
    calledIn = "Called In",
    onCall = "On Call",
    cancelled = "Cancelled",
    floated = "Floated",
    flexed = "Flexed",
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    date: YyyyMmDd;
    shiftTypeKey: IShift["key"];
    userId?: IUser["id"];
    scheduleId?: ISchedule["id"];
    update: EUpdate | null;
    content: string;
  }

  export namespace API {
    export const ROOT_PATH = "/note";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        staffIds?: IStaff["id"][];
        date?: {
          value: YyyyMmDd;
          operator?: "eq" | "gte" | "lte";
        }[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace DownloadUpdateReport {
      export const PATH = ROOT_PATH + "/download-update-report";
      export interface QueryParams {
        date?: TDateFilter[];
        unitIds?: Unit.DTO["id"][];
        updateTypes?: EShiftStatusOrNull[];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
