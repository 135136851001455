import { EPermissionVerbs, EUnitPermissionAreas } from "@m7-health/shared-utils";

import { IRosterItem } from "#/features/Roster/types";
import { BULK_DELETE_KEY } from "@/common/constants";

import { APIListResponse } from "../_shared/types";
import { User } from "../user";

export type IPermissions = Permissions.DTO;

export namespace Permissions {
  export interface DTO {
    id: string;
    rosterId: string;
    area: EUnitPermissionAreas;
    permission: EPermissionVerbs;
    roster: IRosterItem;
  }

  export namespace API {
    export const ROOT_PATH = "/roster-permission";
    export namespace List {
      export const PATH = "/roster-permission";
      export interface QueryParams {
        staffIds?: User.DTO["id"][];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Update {
      export const PATH = `${ROOT_PATH}/bulk-save`;
      export type QueryParams = {
        rosterPermissions: TBulkSaveRosterPermissions;
      };

      export type Response = APIListResponse<DTO>;

      export type TRosterPermissionToDelete = {
        id: DTO["id"];
        [BULK_DELETE_KEY]: boolean;
      };

      export type TRosterPermissionToUpdate = Pick<DTO, "id" | "area" | "permission">;

      export type TRosterPermissionToCreate = Pick<DTO, "area" | "permission" | "rosterId">;

      export type TBulkSaveRosterPermissions = (
        | TRosterPermissionToCreate
        | TRosterPermissionToUpdate
        | TRosterPermissionToDelete
      )[];
    }
  }
}
