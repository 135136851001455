import { useMemo, useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { capitalize, isBoolean, upperFirst } from "lodash";

import { AddBoxOutlined, Article, ContentCopy } from "@mui/icons-material";
import { Box, Grid, IconButton, Popover, Tooltip, Typography } from "@mui/material";

import Avatar from "~/common/components/Avatar";
import MeatballsMenu from "~/common/components/MeatballsMenu";
import CustomSwitch from "~/common/components/TrackedComponents/Switch";
import { useAppDispatch } from "~/common/hooks/useRedux";
import { black, darkGray, nightShift, rosterIconsGrey } from "~/common/theming/colors";
import { getFullName } from "~/common/utils/user";
import { ERoles } from "~/features/User/types";
import { useStaffAttributesByKeyForUnit } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { SendSingleSms } from "@/common/components/SendSingleSms/SendSingleSms";
import { useIsAdmin, useIsScheduler } from "@/common/hooks";

import {
  setEditUserModalId,
  setEditUserModalUserType,
  setIsEditUserModalOpen,
  setIsNoteModalOpen,
  setIsRestoredModalOpen,
  setIsStatusChangeModalOpen,
  setIsSuspendedModalOpen,
  setNoteModalData,
  setNoteModalVariant,
  setRestoredModalData,
  setStatusChangeModalData,
  setSuspendedModalData,
} from "../../store";
import { EStaffStatus, IRosterItem } from "../../types";
import { EUserTypes } from "../../UserModalDeprecated/SchedulerDataForm/types";

const columnHelper = createColumnHelper<IRosterItem>();

const notApplicable = <Box color={darkGray}>N/A</Box>;

export const useColumns = (isSuspended: boolean) => {
  const staffAttributesByKeyInUnit = useStaffAttributesByKeyForUnit();

  const [emailPopoverAnchors, setEmailPopoverAnchors] = useState<
    Record<string, { x: number; y: number } | null>
  >({});

  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();
  const isScheduler = useIsScheduler();

  const getFormattedDate = (date?: string) => {
    if (!date) {
      return;
    }
    return dayjs(date).format("D MMM, YYYY");
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => {
          const { firstName, id, lastName, userType } = info.row.original;

          const isEditingStaff = userType === ERoles.Staff;

          const menuItems = isSuspended
            ? [
                {
                  label: "Restore Account",
                  onClick: () => {
                    dispatch(setIsRestoredModalOpen(true));
                    dispatch(setRestoredModalData({ id, name: `${lastName} ${firstName}` }));
                  },
                },
              ]
            : [
                {
                  label: "Edit user",
                  onClick: () => {
                    dispatch(setIsEditUserModalOpen(true));
                    dispatch(setEditUserModalId(id));
                    dispatch(
                      setEditUserModalUserType(
                        userType as unknown as EUserTypes.Staff | EUserTypes.Scheduler | null,
                      ),
                    );
                  },
                },
                {
                  label: "Suspend Account",
                  onClick: () => {
                    dispatch(setIsSuspendedModalOpen(true));
                    dispatch(setSuspendedModalData({ id, name: `${lastName} ${firstName}` }));
                  },
                },
              ];

          return (
            <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
              <Grid item ml={2} flexGrow={1}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Avatar
                    firstName={firstName}
                    lastName={lastName}
                    height="28px"
                    width="28px"
                    style={{ fontSize: "0.8rem", background: nightShift, color: black }}
                  />
                  {getFullName(firstName, lastName, true)}
                  <SendSingleSms.Button
                    data={{ firstName, lastName, id }}
                    sx={{ fontSize: "20px" }}
                  />
                </Box>
              </Grid>
              {isAdmin || (isScheduler && isEditingStaff) ? (
                <Grid item ml={1}>
                  <MeatballsMenu
                    meatballIconStyles={meatballStyles}
                    menuItemStyles={menuItemStyles}
                    items={menuItems}
                  />
                </Grid>
              ) : null}
            </Grid>
          );
        },
        meta: {
          pinned: true,
        },
      }),
      columnHelper.accessor("note", {
        header: "Notes",
        cell: (info) => {
          const { firstName, id, lastName, note, userType } = info.row.original;

          const isStaff = userType === ERoles.Staff;

          const onClick = () => {
            dispatch(setIsNoteModalOpen(true));
            dispatch(setNoteModalData({ firstName, id, lastName, note: note || "" }));
            note ? dispatch(setNoteModalVariant("edit")) : dispatch(setNoteModalVariant("add"));
          };

          return (
            <>
              {isStaff && (
                <>
                  {note ? (
                    <Tooltip placement="top" title={note}>
                      <Article onClick={onClick} sx={noteIconStyles} />
                    </Tooltip>
                  ) : (
                    <AddBoxOutlined onClick={onClick} sx={noteIconStyles} />
                  )}
                </>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("staffTypeName", {
        header: "Staff Type",
        cell: (info) =>
          info.getValue() ? info.getValue() : <Box color={darkGray}>N/A (Scheduler)</Box>,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => {
          if (info.row.original.userType !== ERoles.Staff) return notApplicable;

          return isSuspended ? (
            <CustomSwitch
              checked={false}
              onChange={() => void 0}
              label={upperFirst(info.getValue())}
              name={`status_${info.row.original.id}`}
              style={{ margin: 0 }}
              disabled
            />
          ) : (
            <CustomSwitch
              checked={info.getValue() === EStaffStatus.Active}
              onChange={(value) => {
                dispatch(
                  setStatusChangeModalData({
                    name: `${info.row.original.lastName} ${info.row.original.firstName}`,
                    id: info.row.original.id,
                    status: value ? EStaffStatus.Active : EStaffStatus.Inactive,
                    postDatedExistingStatus: null,
                    postDatedExistingDate: null,
                  }),
                );
                dispatch(setIsStatusChangeModalOpen(true));
              }}
              label={upperFirst(info.getValue())}
              name={`status_${info.row.original.id}`}
              style={{ margin: 0 }}
            />
          );
        },
      }),
      columnHelper.accessor("email", {
        header: "E-mail",
        cell: (info) => {
          const popperId = info.cell.id + "-copy";
          const handleClose = () => {
            setEmailPopoverAnchors((prev) => ({ ...prev, [popperId]: null }));
          };
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            const position = {
              x: event.clientX,
              y: event.clientY,
            };
            setEmailPopoverAnchors((prev) => ({ ...prev, [popperId]: position }));
            setTimeout(() => handleClose(), 2000);
            void navigator.clipboard.writeText(info.getValue());
          };

          const position = emailPopoverAnchors[popperId];

          return (
            <>
              {info.getValue()}
              {isAdmin && (
                <>
                  <IconButton
                    aria-describedby={popperId}
                    sx={{ ml: 1, ":hover": { color: black } }}
                    onClick={handleClick}
                  >
                    <ContentCopy sx={{ color: position ? black : "inherit" }} fontSize="small" />
                  </IconButton>
                  {position && (
                    <Popover
                      id={popperId}
                      open={true}
                      sx={{
                        position: "fixed",
                        top: position.y,
                        left: position.x,
                      }}
                      onClose={handleClose}
                    >
                      <Typography sx={{ p: 2 }}>Copied to clipboard.</Typography>
                    </Popover>
                  )}
                </>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("phoneNumber", {
        header: "Phone number",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("shiftType", {
        header: "Shift type",
        cell: (info) => (info.getValue() ? capitalize(info.getValue()) : notApplicable),
      }),
      // only show positions that are eligible for this unit
      columnHelper.accessor("attributes", {
        header: "Position eligibility",
        cell: (info) =>
          (info.getValue()?.length ?? 0) > 0
            ? info
                .getValue()
                ?.filter((attributeKey) => attributeKey in staffAttributesByKeyInUnit)
                ?.map((attributeKey) => staffAttributesByKeyInUnit[attributeKey]?.name)
                .sort()
                .join(", ")
            : notApplicable,
      }),
      columnHelper.accessor("employmentType", {
        header: "Employment",
        cell: (info) => info.getValue() || notApplicable,
      }),
      columnHelper.accessor("totalShiftsRequiredPerSchedule", {
        header: "Total shifts required",
        cell: (info) => info.getValue() || notApplicable,
      }),
      columnHelper.accessor("totalWeekendShiftsRequiredPerSchedule", {
        header: "Weekend shifts required",
        cell: (info) => info.getValue() || notApplicable,
      }),
      columnHelper.accessor("maxRequestsOffPerSchedule", {
        header: "Maximum block requests",
        cell: (info) => info.getValue() || notApplicable,
      }),
      columnHelper.accessor("experience", {
        header: "Experience",
        cell: (info) => (info.getValue() ? capitalize(info.getValue()) : notApplicable),
      }),
      columnHelper.accessor("onOrientation", {
        header: "On orientation",
        cell: (info) => {
          const { orientationEndDate } = info.row.original;
          return isBoolean(info.getValue())
            ? info.getValue() === true
              ? `${getFormattedDate(orientationEndDate)}`
              : "No"
            : notApplicable;
        },
      }),
      columnHelper.accessor("preceptor", {
        header: "Preceptor",
        cell: (info) =>
          isBoolean(info.getValue()) ? (info.getValue() === true ? "Yes" : "No") : notApplicable,
      }),
      columnHelper.accessor("minShiftsPerWeek", {
        header: "Min per week",
        cell: (info) => {
          const { minShiftsPerWeek } = info.row.original;
          return minShiftsPerWeek ? `${minShiftsPerWeek}` : notApplicable;
        },
      }),
      columnHelper.accessor("moreThanOneDayBetweenShifts", {
        header: "Days btwn shifts?",
        cell: (info) =>
          isBoolean(info.getValue()) ? (info.getValue() === true ? "Yes" : "No") : notApplicable,
      }),
      columnHelper.accessor("maxConsecutiveShifts", {
        header: "Max consecutive shifts",
        cell: (info) => info.getValue() ?? notApplicable,
      }),
      columnHelper.accessor("contractEndDate", {
        header: "Contract expiration",
        cell: (info) => {
          const value = info.getValue();
          return value ? getFormattedDate(value) : notApplicable;
        },
      }),
      ...(isSuspended
        ? [
            columnHelper.accessor("softDeletedDate", {
              header: "Suspended Date",
              cell: (info) => {
                const value = info.getValue();
                return value ? getFormattedDate(value) : notApplicable;
              },
            }),
          ]
        : []),
    ],
    [dispatch, isSuspended, isAdmin, isScheduler, staffAttributesByKeyInUnit, emailPopoverAnchors],
  );

  return { columns };
};

const meatballStyles = {
  border: "none",
  color: darkGray,
  width: "36px",
  height: "36px",
  "&:hover": {
    color: black,
    border: `1px solid ${black}`,
    borderRadius: "4px",
  },
};

const menuItemStyles = {
  width: "188px",
};
const noteIconStyles = {
  color: rosterIconsGrey,
  cursor: "pointer",
  "&:hover": {
    color: black,
  },
};
