import { createContext, useCallback, useContext } from "react";

// eslint-disable-next-line deprecate/import -- It's ok to use it here
import { useAnalytics } from "~/common/utils/analytics";

import { useDeepMemo, useM7SimpleContext } from "@/common/hooks";

import { Mxp } from "./types";

// Define the context type
type MixpanelPropertyValue = string;
export type MixpanelProperties = Partial<{
  [K in Mxp.TProperty]: MixpanelPropertyValue | MixpanelPropertyValue[];
}>;
type TContext = {
  properties: MixpanelProperties;
};

// Create the context with an initial empty context
export const MixpanelContext = createContext<TContext>({ properties: {} });

// Custom hook for consuming the context
export const useMPContext = () => useContext(MixpanelContext);

// Hook to retrieve m7 context as Mixpanel properties
export const useMixpanelM7Context = () => {
  const m7Context = useM7SimpleContext();

  return useDeepMemo(() => {
    const noValue = "__NO_VALUE__";
    return {
      properties: {
        [Mxp.Property.m7Context.currentRole]: m7Context.currentRole || noValue,
        [Mxp.Property.m7Context.currentUnit]: m7Context.currentUnitName || noValue,
        [Mxp.Property.m7Context.currentUnitId]: m7Context.currentUnitId || noValue,
        [Mxp.Property.m7Context.currentFacility]: m7Context.currentFacilityName || noValue,
        [Mxp.Property.m7Context.currentFacilityId]: m7Context.currentFacilityId || noValue,
      },
    };
  }, [m7Context]);
};

export const useAppTracking = () => {
  const analytics = useAnalytics();
  const mxpContext = useContext(MixpanelContext);
  const m7Context = useMixpanelM7Context();

  return useCallback(
    (eventName: Mxp.TEvent, properties: MixpanelProperties) => {
      analytics.track(eventName, {
        ...mxpContext.properties,
        ...properties,
        ...m7Context.properties,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analytics, JSON.stringify(mxpContext.properties), m7Context],
  );
};
