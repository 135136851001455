import { YyyyMmDd } from "@m7-health/shared-utils";

import { IUnitBasic } from "~/routes/api/types";

import { IStaff } from "../../features/Roster/types";
import { APIListResponse, SDTO } from "../_shared/types";
import { IRoster } from "../roster";
import { IShiftType } from "../shiftType";
import { IUser } from "../user";

export type IPreferenceRequest = PreferenceRequest.DTO;

export namespace PreferenceRequest {
  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    roster: IRoster;
    shiftTypeKey: IShiftType["key"];
    date: YyyyMmDd;
  }

  export namespace API {
    export const ROOT_PATH = "/preference-request";

    export namespace List {
      export const PATH = ROOT_PATH;

      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        staffIds?: IStaff["id"][];
        date?: {
          value: YyyyMmDd;
          operator?: "eq" | "gt" | "lt" | "gte" | "lte";
        }[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Create {
      export const PATH = ROOT_PATH;

      export interface QueryParams {
        staffId: IUser["id"];
        shiftTypeKey: IShiftType["key"];
        date: YyyyMmDd;
      }

      export type Response = DTO;
    }

    export namespace Delete {
      export const PATH = ({ id }: QueryParams) => `${ROOT_PATH}/${id}`;

      export interface QueryParams {
        id: DTO["id"];
      }

      export type Response = Record<string, never>;
    }

    export namespace Update {
      export const PATH = ({ id }: QueryParams) => `${API.ROOT_PATH}/${id}`;

      export interface QueryParams {
        id: DTO["id"];
        shiftTypeKey: IShiftType["key"];
      }

      export type Response = DTO;
    }
  }
}
