import { _SchedulerScheduleTableHeaderRow } from "./TableParts/_HeaderRow";
import { _SchedulerScheduleTableStaffNameHeaderCell } from "./TableParts/_SchedulerScheduleTableStaffNameHeaderCell";
import { _SchedulerScheduleTableStaffRows } from "./TableParts/_SchedulerScheduleTableStaffRows";
import { _SchedulerScheduleTableStaffShiftCell } from "./TableParts/_SchedulerScheduleTableStaffShiftCell";
import { TotalCountHeader } from "./TableParts/totalCountHeader";

export const TableParts = {
  HeaderRow: _SchedulerScheduleTableHeaderRow,
  StaffRows: _SchedulerScheduleTableStaffRows,
  StaffNameHeaderCell: _SchedulerScheduleTableStaffNameHeaderCell,
  StaffShiftCell: _SchedulerScheduleTableStaffShiftCell,
  TotalCountHeader,
};
