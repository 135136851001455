import { useCallback, useEffect, useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { TextField } from "@mui/material";

import { useListNotesQuery } from "~/api";

import { updateNoteApi } from "#/features/DailyReports/api";
import { useSelectedSchedule } from "#/features/HouseView/hooks/useSelectedSchedule";
import { useCheckUserPermission, useIsKiosk, useToast } from "@/common/hooks";
import { useErrors } from "@/common/hooks/useErrors";
import { useAppSelector } from "@/common/hooks/useRedux";

import { HouseViewSideBar } from "..";
import { HouseViewSideBarHooks } from "../hooks";
import { THouseViewSideBar } from "../types";

const hooks = HouseViewSideBarHooks;

export const SelectedUnitNote = ({ selectedUnit }: Pick<THouseViewSideBar, "selectedUnit">) => {
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);

  const queryClient = useQueryClient();
  const selectedDate = useAppSelector((state) => state.houseView.pageFilters.selectedDate)!;
  const scheduleId = useSelectedSchedule()?.id;

  const anonymousNotes = hooks.updateNotes.useAnonymousNotes();
  const anonymousNote = scheduleId ? anonymousNotes?.[scheduleId] : undefined;
  const [updatedAnonymousNote, setUpdatedAnonymousNote] = useState(anonymousNote?.content || "");
  useEffect(() => {
    setUpdatedAnonymousNote(anonymousNote?.content || "");
  }, [anonymousNote]);
  const [dirtyNoteState, setDirtyNoteState] = useState(false);
  const { mutate: updateNote, isPending: noteIsSaving } = useMutation({
    mutationFn: updateNoteApi,
    onSuccess: () => {
      showSuccess("Note for the day saved successfully");
      setDirtyNoteState(false);
    },
    onError: handleErrors,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [useListNotesQuery.queryKey] });
    },
  });
  const saveMainNoteAndUpdates = useCallback(() => {
    const date = selectedDate;
    const liveAnonymousNote = anonymousNote;
    const isAnonymousNoteDirty = updatedAnonymousNote !== (liveAnonymousNote?.content || "");
    isAnonymousNoteDirty &&
      scheduleId &&
      updateNote({
        content: updatedAnonymousNote,
        date,
        scheduleId,
        userId: null,
        updatedAt: liveAnonymousNote?.updatedAt,
      });
  }, [anonymousNote, scheduleId, selectedDate, updateNote, updatedAnonymousNote]);

  const resetMainNote = useCallback(() => {
    setUpdatedAnonymousNote(anonymousNote?.content || "");
    setDirtyNoteState(false);
  }, [anonymousNote?.content]);

  if (!selectedUnit || !scheduleId || isKioskUser || !canManage) return <></>;

  return (
    <HouseViewSideBar.Helpers.BottomActions
      title={"Unit Shift Note"}
      actionButton={{
        disabled: noteIsSaving || !dirtyNoteState,
        label: "Save",
        action: saveMainNoteAndUpdates,
      }}
      children={
        <TextField
          key={"update-notes-anonymous-note-" + selectedDate + selectedUnit.id}
          disabled={noteIsSaving}
          sx={{ ".MuiInputBase-root": { p: 1 } }}
          fullWidth
          label={"*Staff cannot see this"}
          InputLabelProps={{ shrink: true }}
          placeholder={`Enter unit shift note here...`}
          InputProps={{
            multiline: true,
            minRows: 2,
            maxRows: 7,
          }}
          value={updatedAnonymousNote || ""}
          onChange={(event) => {
            setUpdatedAnonymousNote(event.target.value || "");
            if (!dirtyNoteState) setDirtyNoteState(true);
          }}
        />
      }
      cancelButton={{
        disabled: noteIsSaving || !dirtyNoteState,
        label: "Cancel",
        action: resetMainNote,
      }}
    />
  );
};
