import { useMemo, useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { keyBy, pick, sortBy } from "lodash";

import { Box } from "@mui/material";

import { IShiftType, StaffShift, useShiftTypesForSchedule } from "~/api";
import { PartialShiftTimePicker } from "~/common/components/PartialShiftTimePicker/PartialShiftTimePicker";
import ShiftTypeFilterDropdown from "~/common/components/ShiftTypeFilterDropdown";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { TimeString, seconds } from "~/common/types";
import { useSelectedSchedule } from "~/features/HouseView/hooks/useSelectedSchedule";
import { houseViewStore } from "~/features/HouseView/store";

import { useCheckUserPermission, useCurrentTimezone, useIsKiosk } from "@/common/hooks";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { HouseViewSideBar, STAFF_ITEMS_LAYOUTS } from "..";
import { StaffItem } from "../..";
import { splitOrEditShift } from "../helpers/splitOrEditShift";
import { THouseViewSideBar } from "../types";

export const FloatToUnitV2 = ({
  selectedUnit,
  shifts,
  staffDetails,
  notes,
  staffDatesMetadata,
}: THouseViewSideBar) => {
  const [partialShiftAttributes, setPartialShiftAttributes] = useState<{
    customStartTime: TimeString | null;
    customDuration: seconds | null;
  }>({
    customStartTime: null,
    customDuration: null,
  });
  const [partialShiftIsValid, setPartialShiftIsValid] = useState(true);

  const dispatch = useAppDispatch();
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const timezone = useCurrentTimezone(selectedUnit.id);
  const {
    floating: { staffShift },
    pageFilters: { selectedStaffCategory },
  } = useAppSelector((state) => state.houseView);
  const actionIsDirty = useAppSelector((state) => state.houseView.sidebarCurrentAction.isDirty);
  const selectedSchedule = useSelectedSchedule();
  const [selectedShiftType, setSelectedShiftType] = useState<IShiftType["key"] | undefined>(
    staffShift?.shiftTypeKey,
  );
  const selectedStaffIdToFloat = staffShift?.staffId;
  const selectedShiftIdToFloat = staffShift?.id;
  const shiftTypes = useShiftTypesForSchedule(selectedSchedule?.id || NOT_EXISTING_UUID);
  let eligibleShiftsToFloat = useMemo(() => {
    const attributesByKey = keyBy(selectedUnit.attributes, "key");

    return Object.values(shifts || {})
      .flat()
      .filter(({ staffId, status, scheduleId }) => {
        if (voidingShiftStatus(status)) return false;

        const currentStaffDetails = staffDetails?.[staffId];
        if (!currentStaffDetails) return false;

        const categoryEligible =
          currentStaffDetails.staffType.staffCategoryKey === selectedStaffCategory;
        const positionEligible = currentStaffDetails.attributeKeys?.find(
          (key) => attributesByKey[key]?.name.toLowerCase() === selectedStaffCategory,
        );
        if (!categoryEligible && !positionEligible) return false;

        if (!currentStaffDetails.user.rosters?.some(({ unitId }) => unitId === selectedUnit.id))
          return false;

        if (scheduleId === selectedSchedule?.id) return false;

        return true;
      });
  }, [shifts, staffDetails, selectedUnit, selectedSchedule, selectedStaffCategory]);

  const shiftToFloat = eligibleShiftsToFloat.find((shift) => shift.id === selectedShiftIdToFloat);
  const originalShiftTypes = useShiftTypesForSchedule(
    shiftToFloat?.scheduleId || NOT_EXISTING_UUID,
  );

  eligibleShiftsToFloat = sortBy(
    eligibleShiftsToFloat,
    (shift) => new Date(staffDatesMetadata?.[shift.staffId]?.lastFloatedAt || 0),
  );

  if (!eligibleShiftsToFloat || !staffDetails || !notes) return null;

  const startEditWithShift = () => {
    const newShiftType = shiftTypes.find(({ key }) => key === selectedShiftType);
    const originalShiftType = originalShiftTypes.find(
      ({ key }) => key === shiftToFloat?.shiftTypeKey,
    );
    if (
      !selectedStaffIdToFloat ||
      !shiftToFloat ||
      !selectedSchedule ||
      !newShiftType ||
      !originalShiftType
    )
      return;

    const positionAsCategory = selectedUnit.attributes?.find(
      (attribute) => attribute.name.toLowerCase() === selectedStaffCategory,
    );

    const workingAwayFromHomeUnit =
      staffDetails[selectedStaffIdToFloat]?.homeUnitId !== selectedUnit.id;

    dispatch(
      houseViewStore.state.startEditShifts({
        staffId: selectedStaffIdToFloat,
        autoSave:
          partialShiftAttributes.customDuration === shiftToFloat.customDuration &&
          partialShiftAttributes.customStartTime === shiftToFloat.customStartTime,
        shifts: splitOrEditShift({
          shiftToSplitAndFloat: shiftToFloat,
          selectedSchedule,
          originalShiftType,
          newShiftType,
          partialShiftAttributes,
          timezone,
          targetedShiftParams: {
            ...(positionAsCategory ? { attributes: [positionAsCategory.key] } : {}),
            isWorkingAway: workingAwayFromHomeUnit,
            status: workingAwayFromHomeUnit ? StaffShift.EStatus.floated : null,
          },
        }),
      }),
    );
  };

  return (
    <>
      <Box sx={{ overflowY: "auto" }}>
        {eligibleShiftsToFloat.length > 0 &&
          eligibleShiftsToFloat.map((shift) => (
            <StaffItem
              sx={{ padding: "5px", margin: "1px" }}
              shift={shift}
              note={notes[shift.staffId]}
              unitId={selectedUnit.id}
              onClick={
                isKioskUser || !canManage
                  ? undefined
                  : () => {
                      dispatch(houseViewStore.state.startFloating());
                      dispatch(
                        houseViewStore.state.setFloatShift(
                          pick(shift, ["staffId", "customStartTime", "customDuration", "id"]),
                        ),
                      );
                    }
              }
              selectable={!isKioskUser && canManage}
              selected={shift.id === selectedShiftIdToFloat}
              layout={STAFF_ITEMS_LAYOUTS.floating}
              lastStatusType={StaffShift.EStatus.floated}
            />
          ))}
      </Box>
      <Box flexGrow={1} />
      {selectedStaffIdToFloat && (
        <HouseViewSideBar.Helpers.BottomActions
          title="Shift Details"
          actionButton={{
            label: `Float Staff to ${selectedUnit.name}`,
            trackingLabel: "Float Staff to Unit",
            disabled: !selectedShiftType || !partialShiftIsValid,
            action: startEditWithShift,
          }}
          cancelButton={{
            label: "Cancel",
            disabled: !actionIsDirty,
            action: () => {
              setPartialShiftAttributes({
                customDuration: null,
                customStartTime: null,
              });
              dispatch(houseViewStore.state.endAction());
            },
          }}
        >
          <ShiftTypeFilterDropdown
            shiftTypes={shiftTypes.filter((shiftType) => shiftType.isWorkingShift)}
            selectedOption={shiftTypes.find((shiftType) => shiftType.key === selectedShiftType)}
            selectOption={(shiftType) => {
              setSelectedShiftType(shiftType?.key as IShiftType["key"]);
              if (shiftToFloat) {
                const originalShiftType = originalShiftTypes.find(
                  ({ key }) => key === shiftToFloat.shiftTypeKey,
                );
                const start = shiftToFloat.customStartTime || originalShiftType?.startTime;
                const duration = shiftToFloat.customDuration || originalShiftType?.durationSeconds;
                if (start && duration && shiftType) {
                  if (start !== shiftType.startTime || duration !== shiftType.durationSeconds)
                    return setPartialShiftAttributes({
                      customStartTime: start,
                      customDuration: duration as seconds,
                    });
                }
              }

              setPartialShiftAttributes({
                customDuration: null,
                customStartTime: null,
              });
            }}
            isMultiSelect={false}
          />
          {selectedShiftType && (
            <PartialShiftTimePicker
              alwaysOn={true}
              key={selectedShiftType}
              shiftType={
                (selectedShiftType && shiftTypes.find(({ key }) => key === selectedShiftType)) ||
                null
              }
              staffShift={partialShiftAttributes}
              onChange={(shiftAttributes, isValid) => {
                if (isValid) {
                  setPartialShiftAttributes({
                    customDuration: null,
                    customStartTime: null,
                    ...shiftAttributes,
                  });
                  setPartialShiftIsValid(true);
                } else {
                  setPartialShiftIsValid(false);
                }
              }}
            />
          )}
        </HouseViewSideBar.Helpers.BottomActions>
      )}
    </>
  );
};
