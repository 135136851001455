import { PayloadAction } from "@reduxjs/toolkit";

import { TStoreSlice } from "~/store";

import { IAttribute, IShiftType } from "@/api";

import { THolidayScheduleState } from ".";

export type TFiltersState = {
  fullView: boolean;
  devMode: boolean;
  shiftType: IShiftType["key"][];
  showPreferences: boolean;
  showStatus: boolean;
  attributes: IAttribute["key"][];
  attributeEligibilityIconToShow: IAttribute["key"] | null;
  showPositionEligibility: boolean;
  showPositionAssignment: boolean;
  showShiftTargetLevels: boolean;
  showTargetLevels: boolean;
  showTimeTargetLevels: boolean;
};

export const FiltersInitState: TFiltersState = {
  attributeEligibilityIconToShow: null,
  fullView: false,
  devMode: false,
  shiftType: [],
  showPreferences: true,
  showStatus: true,
  attributes: [],
  showPositionEligibility: false,
  showPositionAssignment: false,
  showShiftTargetLevels: false,
  showTargetLevels: false,
  showTimeTargetLevels: false,
};

export const FiltersActions = {
  updateGridFilters: (state, { payload }: PayloadAction<Partial<TFiltersState>>) => {
    state.filters = {
      ...state.filters,
      ...payload,
    };
  },
  resetFilters: (state) => {
    state.filters = FiltersInitState;
  },
} satisfies TStoreSlice<THolidayScheduleState>;
