import { Action, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { ERoles, IUser, IUserState } from "../types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: IUserState = {
  userData: {
    email: "",
    emailVerified: false,
    id: "",
    firstName: "",
    roles: [],
    lastName: "",
    facilities: [],
    unitEntities: [],
    phoneNumber: "",
    homeUnitId: "",
    homeUnitName: "",
    isSoftDeleted: false,
  },
  changeEmailModal: {
    isOpen: false,
  },
  currentRole: undefined,
};

interface RehydrateAction extends Action<typeof REHYDRATE> {
  payload: IUserState;
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.userData = action.payload;
    },
    setIsChangeEmailModalOpen: (state, action: PayloadAction<boolean>) => {
      state.changeEmailModal.isOpen = action.payload;
    },
    setCurrentRole: (state, action: PayloadAction<ERoles | undefined>) => {
      state.currentRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      state = { ...action.payload };
    });
  },
});

const persistConfig = {
  key: "user",
  storage,
};

export const { setUser, setIsChangeEmailModalOpen, setCurrentRole } = userSlice.actions;

export default persistReducer(persistConfig, userSlice.reducer);
