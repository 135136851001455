import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { isInteger } from "lodash";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import CustomTable from "~/common/components/Table";
import { useAppSelector, useAppDispatch } from "~/common/hooks/useRedux";
import { useRosterQuery } from "~/features/Roster/queries";
import { setRosterTablePageSize } from "~/features/Roster/store";
import { IRosterItem } from "~/features/Roster/types";

import { useColumns } from "./useColumns";

export enum ERosterUserOptions {
  home = "home",
  other = "other",
  all = "all",
}

interface IRosterTableProps {
  // is showing home unit users
  userOption: ERosterUserOptions;
  // is showing suspended users
  isSuspended: boolean;
}

export const RosterTable = ({ userOption, isSuspended }: IRosterTableProps) => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState<number>();

  useEffect(() => {
    const pageParam = Number(searchParams.get("page"));
    const pageIndex =
      !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam - 1 : 0;
    setPageIndex(pageIndex);
  }, [searchParams]);

  const pageSize = useAppSelector((state) => state.roster.rosterTable.pageSize);

  const { data, isLoading } = useRosterQuery([isSuspended], userOption);

  const { data: items = [], total } = data || {};

  // same columns if home unit or not, the only differentiation is with suspended users
  const { columns } = useColumns(isSuspended);

  if (!data || isLoading) {
    return <CircularProgress />;
  }

  if (!isLoading && data.total === 0) {
    return (
      <Grid flexDirection="column" alignItems="center" container mt="15%">
        {isSuspended ? (
          <Typography mt={2} variant="shy">
            No suspended users
          </Typography>
        ) : (
          <>
            <Typography mt={2} variant="shy">
              This unit is empty.
            </Typography>
            <Typography variant="shy">Use “Add User” to start adding personnel.</Typography>
          </>
        )}
      </Grid>
    );
  }

  const handlePageChange = (pageIndex: number) => {
    setSearchParams({ page: (pageIndex + 1).toString() });
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(setRosterTablePageSize(rowsPerPage));
    setSearchParams({ page: "1" });
  };

  return (
    <Box width="100%" height="100%" minHeight={0} maxHeight="100%">
      <CustomTable<IRosterItem>
        columns={columns}
        data={items}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        pageIndex={pageIndex}
        pageSize={pageSize}
        showPagination
        total={total}
        stickyTableHead
        stretchVertically
        wrapHeaders
      />
    </Box>
  );
};
