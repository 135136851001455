import React from "react";
import { Provider } from "react-redux";
import { toast } from "react-toastify";

import ReactDOM from "react-dom/client";

import { Auth0Provider } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LDEvaluationDetail, asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import Alert from "@mui/material/Alert";

import { httpClient } from "~/common/packages/httpClient";

import App from "./App";
import { AnalyticsProvider } from "./common/utils/analytics";
import { isOnMobile } from "./common/utils/isOnMobile";
import { store } from "./store";

// TODO: change camel casing here (must make Lambda@Edge change)
interface TenantData {
  name?: string;
  apiHost?: string;
  auth0Domain?: string;
  auth0ClientId?: string;
}

function initializeSentry(tenantName: string | undefined) {
  if (import.meta.env.VITE_ENVIRONMENT !== "local") {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      integrations: [
        new Sentry.Replay({
          maskAllInputs: true,
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls:
            import.meta.env.VITE_ENVIRONMENT !== "production"
              ? [/^https:\/\/(app|api)(\.\w+)?\.m7health\.(dev|com)/]
              : [],
        }),
      ],
      environment: import.meta.env.VITE_ENVIRONMENT as string,
      release: import.meta.env.VITE_APP_VERSION as string,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
    });
    if (tenantName) {
      Sentry.setTag("tenantName", tenantName);
    }
  }
}

function initializeDatadog(tenantName: string | undefined) {
  if (import.meta.env.VITE_ENVIRONMENT !== "local") {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID as string,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN as string,
      site: "datadoghq.com",
      service: "frontend",
      env: import.meta.env.VITE_ENVIRONMENT as string,
      version: import.meta.env.VITE_APP_VERSION as string,
      allowedTracingUrls: [/^https:\/\/api(\.\w+)?\.m7health\.com/],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: "allow",
      enableExperimentalFeatures: ["feature_flags"],
    });
    if (tenantName) {
      datadogRum.setGlobalContextProperty("tenantName", tenantName);
    }
  }
}

async function initializeLaunchDarklyProvider(tenantName: string | undefined) {
  const ldContext = tenantName
    ? {
        kind: "tenant",
        key: tenantName,
      }
    : undefined;

  const ldProvider = await asyncWithLDProvider({
    clientSideID: (import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID as string) || "",
    context: ldContext,
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  return ldProvider;
}

// Wrap every query call and shows a toast depending on error that is received
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      const message: string = (error.message as string) || "Something went wrong";

      // Only show error toasts if we already have data in the cache, what indicates a failed background update.
      if (query.state.data !== undefined) {
        const options = {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          closeButton: false,
          draggable: false,
        };
        // if the error is not a network error (network error of this are returned by axios if BE connection is not working properly), show it
        // network errors are handled by the axios interceptor and are particularly alarming to the user with no real information
        // typically, network error we see contains all 3 of these strings in one message... but checking if the message contains any of them as if a message has any of these strings, it should be hidden
        // and will not be helpful to the user
        if (
          !message.includes("Cannot destructure property") &&
          !message.includes("statusCode") &&
          !message.includes("intermediateValue") &&
          !message.includes("Could not find any entity") &&
          (error.statusCode as number) !== 403
        ) {
          // alerts to show up as errors
          toast(
            <div>
              <Alert severity="error">{message}</Alert>
            </div>,
            options,
          );
        }
      }
    },
  }),
});

async function initializeTenantData() {
  // Check if multi-tenant support is enabled
  const multiTenantEnabled: boolean = import.meta.env.VITE_MULTI_TENANT_ENABLED === "true";
  if (!multiTenantEnabled) {
    return {
      name: undefined,
      auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
      auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
    };
  }

  // For local testing, we want to be able to override the returned tenant data
  const overrideTenantData: boolean = import.meta.env.VITE_OVERRIDE_TENANT_DATA === "true";
  if (overrideTenantData) {
    // eslint-disable-next-line no-console
    console.warn("Overriding tenant data");
    return {
      name: "test-tenant-1",
      apiHost: "test-tenant-1.api.dev.m7health.dev",
      auth0Domain: "tenant-test-1.us.auth0.com",
      auth0ClientId: "hjlJ9eqXoG3kbiycaq2V2LoT5bENv9QY",
    };
  }

  // Import tenant-specific data
  const tenantData = (await fetch("/tenant_data.json")
    .then((response) => response.json())
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      console.error("Did not properly receive tenant data", error);
      // TODO: how to throw this to Sentry before Sentry is initialized?
      return {};
    })) as TenantData;

  return {
    name: tenantData.name,
    apiHost: tenantData.apiHost,
    auth0Domain: tenantData.auth0Domain || (import.meta.env.VITE_AUTH0_DOMAIN as string),
    auth0ClientId: tenantData.auth0ClientId || (import.meta.env.VITE_AUTH0_CLIENT_ID as string),
  };
}

async function initializeApp() {
  // Initialize tenant data
  const { name: tenantName, apiHost, auth0Domain, auth0ClientId } = await initializeTenantData();

  // Initialize Sentry and Datadog
  initializeSentry(tenantName);
  initializeDatadog(tenantName);

  // Set tenant API host for API requests
  if (apiHost) httpClient.setTenantApiHost(apiHost);

  const LDProvider = await initializeLaunchDarklyProvider(tenantName);

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: `https://${auth0Domain}/api/v2/`,
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <LDProvider>
          <AnalyticsProvider
            token={import.meta.env.VITE_MIXPANEL_TOKEN as string}
            tenantName={tenantName}
          >
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <App />
                {!isOnMobile() && <ReactQueryDevtools />}
              </QueryClientProvider>
            </Provider>
          </AnalyticsProvider>
        </LDProvider>
      </Auth0Provider>
    </React.StrictMode>,
  );
}

void initializeApp();
