import { useMemo } from "react";

import { ERoles } from "~/features/User/types";

import { useAppSelector } from "./useRedux";

export const useIsStaffApp = (): boolean => {
  const currentRole = useAppSelector((state) => state.user.currentRole);

  return useMemo(() => {
    return currentRole === ERoles.Staff;
  }, [currentRole]);
};
