/* eslint-disable no-console */

import { memo } from "react";

import { Box } from "@mui/material";

import {
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToDelete,
  TRealTimeStaffingTargetToUpdate,
} from "~/api/realTimeStaffingTargets";
import { IUnitBasic } from "~/routes/api/types";

import { IStaffShift } from "@/api";

import { TableStyleContainer } from "./style";

import { TableParts } from ".";

export const UnitTable = memo(
  ({
    unitStaffingLevels,
    hasNewEntry,
    shiftsByUnit,
  }: {
    unitStaffingLevels: (
      | TRealTimeStaffingTargetToCreate
      | TRealTimeStaffingTargetToUpdate
      | TRealTimeStaffingTargetToDelete
    )[];
    hasNewEntry: boolean;
    shiftsByUnit?: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
  }) => {
    return (
      <Box
        mt={3}
        marginBottom="60px"
        boxSizing="border-box"
        display="flex"
        overflow={"auto"}
        flexDirection="column"
      >
        <TableStyleContainer>
          <table
            className={`scheduler-table regular-view v2`}
            style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
          >
            <tbody>
              <TableParts.HeaderRow />
              <TableParts.Rows
                unitStaffingLevels={unitStaffingLevels}
                hasNewEntry={hasNewEntry}
                shiftsByUnit={shiftsByUnit}
              />
            </tbody>
          </table>
        </TableStyleContainer>
      </Box>
    );
  },
);
