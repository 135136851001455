import { useMemo } from "react";

import { Timezone } from "@m7-health/shared-utils";

import { localDayJs } from "../packages/dayjs";

import { useCurrentFacility } from "./useCurrentFacilityId";
import { useCurrentRole } from "./useCurrentRole";
import { useCurrentUnit } from "./useCurrentUnitId";
import { useDeepMemo } from "./useDeepMemo";
import { useCurrentUser } from "./useUser";

export const useM7Context = () => {
  const currentUser = useCurrentUser();
  const currentUnit = useCurrentUnit();
  const currentFacility = useCurrentFacility();
  const currentRole = useCurrentRole();

  return useMemo(() => {
    return {
      currentUser,
      currentUnit,
      currentFacility,
      currentRole,
      currentUnitId: currentUnit?.id,
      currentFacilityId: currentFacility?.id,
      currentUserId: currentUser?.id,
      currentTimezone: (currentUnit?.timezone || currentFacility?.timezone) as Timezone | undefined,
    };
  }, [currentUser, currentUnit, currentFacility, currentRole]);
};
export type TM7Context = ReturnType<typeof useM7Context>;

// returned object only contains string values
export const useM7SimpleContext = () => {
  const currentUser = useCurrentUser();
  const currentUnit = useCurrentUnit();
  const currentFacility = useCurrentFacility();
  const currentRole = useCurrentRole();

  return useDeepMemo(() => {
    return {
      currentUserId: currentUser?.id,
      currentUserEmail: currentUser?.email,
      currentUnitName: currentUnit?.name,
      currentFacilityName: currentFacility?.name,
      currentRole,
      currentUnitId: currentUnit?.id,
      currentFacilityId: currentFacility?.id,
      // TODO: decide in current prefix vs not
      timezone: (currentUnit?.timezone ||
        currentFacility?.timezone ||
        localDayJs.tz.guess()) as Timezone,
      currentTimezone: (currentUnit?.timezone ||
        currentFacility?.timezone ||
        localDayJs.tz.guess()) as Timezone,
    };
  }, [currentUser, currentUnit, currentFacility, currentRole]);
};
export type TM7SimpleContext = ReturnType<typeof useM7SimpleContext>;
