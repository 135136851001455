import { useEffect, useMemo } from "react";

import { EPermissionVerbs, EUnitPermissionAreas } from "@m7-health/shared-utils";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useAppConfigQuery, useCurrentUserQuery } from "~/features/User/queries";
import { setUser } from "~/features/User/store";

import { IPermissions, useListStaffDetailsQuery } from "@/api";

import { useIsStaffApp } from "./useIsStaffApp";

export const useUser = () => {
  const dispatch = useAppDispatch();

  const { data } = useCurrentUserQuery();

  useEffect(() => {
    if (!data) {
      return;
    }
    dispatch(setUser(data));
  }, [data, dispatch]);
};

export const useCurrentUser = () => useAppSelector((state) => state.user.userData);
export const useCurrentUserId = () => useAppSelector((state) => state.user.userData?.id);
export const useCurrentStaffDetails = () => {
  const currentUser = useCurrentUser();
  const userIsStaff = useIsStaffApp();

  const { data } = useListStaffDetailsQuery(
    {
      staffIds: [currentUser?.id],
    },
    {
      skip: !currentUser?.id || !userIsStaff,
    },
  );

  return data?.[0];
};
export const useCurrentStaffHomeUnit = () => {
  const currentUserDetails = useCurrentStaffDetails();
  const units = useAppConfigQuery().data?.units;

  return useMemo(() => {
    if (!currentUserDetails) return null;

    return units?.find((unit) => unit.id === currentUserDetails.homeUnitId);
  }, [currentUserDetails, units]);
};
export const useCurrentStaffHomeUnitId = () => useCurrentStaffHomeUnit()?.id;

// to only be used inside useCheckUserPermission OR with schedulerRoster / houseView permissions
export const useCurrentUserPermissions = (): {
  permissionsDataByUnit: Record<string, Partial<Record<EUnitPermissionAreas, EPermissionVerbs>>>;
  schedulerRosterPermission: EPermissionVerbs | undefined;
  houseViewPermission: EPermissionVerbs | undefined;
} => {
  const permissions = useAppConfigQuery().data?.schedulerPermissions;
  return useMemo(() => {
    const permissionsDataByUnit: Record<
      string,
      Partial<Record<IPermissions["area"], IPermissions["permission"]>>
    > = {};
    //schedulerRosterPermission can have the values read-only or manage
    let schedulerRosterPermission: IPermissions["permission"] | undefined;
    let houseViewPermission: IPermissions["permission"] | undefined;
    permissions?.forEach((permissionData) => {
      if (permissionData.area === EUnitPermissionAreas.schedulerRoster)
        schedulerRosterPermission = permissionData.permission;
      else if (permissionData.area === EUnitPermissionAreas.houseView)
        houseViewPermission = permissionData.permission;
      else {
        permissionsDataByUnit[permissionData.roster.unitId as string] = {
          ...(permissionsDataByUnit[permissionData.roster.unitId as string] || {}),
          [permissionData.area]: permissionData.permission,
        };
      }
    });
    return { permissionsDataByUnit, schedulerRosterPermission, houseViewPermission };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(permissions)]);
};
