import { ReactNode, useCallback } from "react";

import { difference, filter, values } from "lodash";

import { Close, DarkMode, FilterAlt, LightMode } from "@mui/icons-material";
import { Box, Collapse, Typography, lighten } from "@mui/material";

import { ISchedule, IShiftType, IStaffCategory } from "~/api";
import CustomButton from "~/common/components/TrackedComponents/Button";
import Checkbox from "~/common/components/TrackedComponents/Checkbox";
import { useAppDispatch } from "~/common/hooks/useRedux";
import { black, darkPurple, mediumGray, white } from "~/common/theming/colors";
import { KeyBy } from "~/common/types";
import { THouseViewState, houseViewStore } from "~/features/HouseView/store";
import { EHVEmploymentTypeFilter } from "~/features/HouseView/store/findStaffToWorkActions";
import { EHVTimeRange } from "~/features/HouseView/store/pageFiltersActions";
import { IShift } from "~/routes/api/types";

import { useFindStaffToWorkFilters } from "../hooks/useFindStaffToWorkFilters";

export const FindStaffToWorkFilters = ({
  shiftTypeByScheduleByType,
  availableCategories,
  filteredInCount,
}: {
  shiftTypeByScheduleByType: Record<ISchedule["id"], KeyBy<IShift, "key">>;
  availableCategories: IStaffCategory[];
  filteredInCount: number;
}) => {
  const dispatch = useAppDispatch();

  const { filtersAreDirty, filters, updateFilters, resetFilters, shiftTypesFiltersToDisplay } =
    useFindStaffToWorkFilters({ shiftTypeByScheduleByType });

  const toggleFiltersCollapse = useCallback(
    (event: React.SyntheticEvent, forceOpenOrClose?: boolean) => {
      if (event.defaultPrevented) return;
      // Don't use updateFilters here, we don't want to set dirty
      event.stopPropagation();
      dispatch(
        houseViewStore.state.setFindStaffToWorkFilters((oldFilters) => {
          return {
            ...oldFilters,
            modalIsOpen: forceOpenOrClose ?? !oldFilters?.modalIsOpen,
          };
        }),
      );
    },
    [dispatch],
  );

  const updateEmploymentTypeFilters = useCallback(
    (toggledEmploymentType: EHVEmploymentTypeFilter) =>
      updateFilters((oldFilters) => {
        const allEmploymentTypesAreIncluded =
          oldFilters?.employmentTypes?.includes(toggledEmploymentType);

        if (allEmploymentTypesAreIncluded) {
          return {
            ...oldFilters,
            employmentTypes: oldFilters?.employmentTypes?.filter(
              (employmentType) => employmentType !== toggledEmploymentType,
            ),
          };
        } else {
          return {
            ...oldFilters,
            employmentTypes: [...(oldFilters?.employmentTypes || []), toggledEmploymentType],
          };
        }
      }),
    [updateFilters],
  );

  if (!filters) return <></>;

  return (
    <Box
      sx={{
        zIndex: 10,
        background: white,
        border: filters.modalIsOpen ? `2px solid ${black}` : `1px solid ${mediumGray}`,
        position: "relative",
        borderRadius: "4px",
        mb: 1,
      }}
      onClick={toggleFiltersCollapse}
    >
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} p={1}>
        {helpers.displayFilterButton(toggleFiltersCollapse, filteredInCount)}
        {helpers.displayActiveFilterTags(filters, availableCategories)}
        <Box flexGrow={1} />
        {filtersAreDirty && helpers.displayResetFilterButton(resetFilters)}
      </Box>
      <Collapse
        in={filters.modalIsOpen}
        sx={{
          zIndex: 10,
          background: white,
          position: "relative",
          ".MuiCheckbox-root": {
            padding: "4px !important",
          },
        }}
        // Prevents closing the date picker when interacting with content
        onClick={(event) => event.stopPropagation()}
      >
        <Box p={1}>
          {helpers.displayCloseFiltersButton(toggleFiltersCollapse)}

          {/* Staff category filters */}
          {helpers.filterSection({
            title: "Staff category",
            items: availableCategories.map((category) => ({
              checked: filters.staffCategories?.includes(category.key),
              key: category.key,
              label: category.name,
              action: () =>
                updateFilters((oldFilters) => {
                  const staffCategoryKeys = oldFilters?.staffCategories || [];
                  if (staffCategoryKeys.includes(category.key)) {
                    return {
                      ...oldFilters,
                      staffCategories: staffCategoryKeys.filter((key) => key !== category.key),
                    };
                  }
                  return {
                    ...oldFilters,
                    staffCategories: [...staffCategoryKeys, category.key],
                  };
                }),
            })),
          })}

          {/* Shift type filters */}
          {helpers.filterSection({
            title: "Staff with shift type",
            items: [null, ...shiftTypesFiltersToDisplay].map((shiftType) => ({
              checked: filters.shiftTypeKeys?.includes(
                (shiftType?.key as IShiftType["key"]) || null,
              ),
              key: shiftType?.key || "no-shift",
              label: shiftType?.name || "No shift",
              action: () =>
                updateFilters((oldFilters) => {
                  const shiftTypeKeys = oldFilters?.shiftTypeKeys || [];
                  if (shiftTypeKeys.includes((shiftType?.key as IShiftType["key"]) || null)) {
                    return {
                      ...oldFilters,
                      shiftTypeKeys: shiftTypeKeys.filter(
                        (key) => key !== (shiftType?.key || null),
                      ),
                    };
                  }
                  return {
                    ...oldFilters,
                    shiftTypeKeys: [
                      ...shiftTypeKeys,
                      (shiftType?.key as IShiftType["key"]) || null,
                    ],
                  };
                }),
            })),
          })}

          {/* Overtime filter */}
          {helpers.filterSection({
            title: "Overtime",
            items: [
              {
                key: "working-hours",
                label: "Hide staff with OT",
                checked: filters.hideOvertime,
                action: () =>
                  updateFilters((oldFilters) => ({
                    ...oldFilters,
                    hideOvertime: !oldFilters?.hideOvertime,
                  })),
              },
            ],
          })}

          {/* Employment type filter */}
          {helpers.filterSection({
            title: "Employment type",
            items: [
              {
                key: "employment-type-others",
                label: "FT/PT",
                checked: filters.employmentTypes?.includes(EHVEmploymentTypeFilter.other) || false,
                action: () => updateEmploymentTypeFilters(EHVEmploymentTypeFilter.other),
              },
              {
                key: "employment-type-PRN",
                label: "PRN",
                checked:
                  filters.employmentTypes?.includes(EHVEmploymentTypeFilter.perDiem) || false,
                action: () => updateEmploymentTypeFilters(EHVEmploymentTypeFilter.perDiem),
              },
              {
                key: "employment-type-TVL",
                label: "Travel staff",
                checked:
                  filters.employmentTypes?.includes(EHVEmploymentTypeFilter.travelStaff) || false,
                action: () => updateEmploymentTypeFilters(EHVEmploymentTypeFilter.travelStaff),
              },
            ],
          })}

          {/* Preferred Working hours filter */}
          {helpers.filterSection({
            title: "Preferred working hours",
            items: [
              {
                key: EHVTimeRange.day7A7P,
                label: (
                  <>
                    <LightMode sx={{ fontSize: "15px" }} /> 7A-7P
                  </>
                ),
                trackingLabel: "7A-7P",
                checked: filters.preferredWorkingHours?.includes(EHVTimeRange.day7A7P) || false,
                action: () =>
                  updateFilters((oldFilters) => {
                    const dayIsIncluded =
                      filters.preferredWorkingHours?.includes(EHVTimeRange.day7A7P) || false;
                    return {
                      ...oldFilters,
                      preferredWorkingHours: dayIsIncluded
                        ? oldFilters?.preferredWorkingHours?.filter(
                            (unit) => unit !== EHVTimeRange.day7A7P,
                          )
                        : [...(oldFilters?.preferredWorkingHours || []), EHVTimeRange.day7A7P],
                    };
                  }),
              },
              {
                key: EHVTimeRange.night7P7A,
                label: (
                  <>
                    <DarkMode sx={{ fontSize: "15px" }} /> 7P-7A
                  </>
                ),
                trackingLabel: "7P-7A",
                checked: filters.preferredWorkingHours?.includes(EHVTimeRange.night7P7A) || false,
                action: () =>
                  updateFilters((oldFilters) => {
                    const nightIsIncluded =
                      filters.preferredWorkingHours?.includes(EHVTimeRange.night7P7A) || false;
                    return {
                      ...oldFilters,
                      preferredWorkingHours: nightIsIncluded
                        ? oldFilters?.preferredWorkingHours?.filter(
                            (unit) => unit !== EHVTimeRange.night7P7A,
                          )
                        : [...(oldFilters?.preferredWorkingHours || []), EHVTimeRange.night7P7A],
                    };
                  }),
              },
            ],
          })}

          {/* Unit filter */}
          {helpers.filterSection({
            title: "Unit",
            items: [
              {
                key: "unit-HOME",
                label: "Home",
                checked: filters.unit?.includes("HOME") || false,
                action: () => {
                  updateFilters((oldFilters) => {
                    const HomeIsIncluded = filters.unit?.includes("HOME") || false;
                    return {
                      ...oldFilters,
                      unit: HomeIsIncluded
                        ? oldFilters?.unit?.filter((unit) => unit !== "HOME")
                        : [...(oldFilters?.unit || []), "HOME"],
                    };
                  });
                },
              },
              {
                key: "unit-OTHER",
                label: "Float eligible",
                checked: filters.unit?.includes("OTHER") || false,
                action: () => {
                  updateFilters((oldFilters) => {
                    const OtherIsIncluded = filters.unit?.includes("OTHER") || false;
                    return {
                      ...oldFilters,
                      unit: OtherIsIncluded
                        ? oldFilters?.unit?.filter((unit) => unit !== "OTHER")
                        : [...(oldFilters?.unit || []), "OTHER"],
                    };
                  });
                },
              },
            ],
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

const FilterTag = (content: string | ReactNode, index: string | number) => (
  <Typography
    key={"find-staff-for-work-filter-tag-" + index.toString()}
    variant="small"
    sx={{
      color: darkPurple,
      background: lighten(darkPurple, 0.9),
      ml: 0.5,
      textWrap: "nowrap",
      height: "12px",
      fontWeight: "500",
      padding: "2px 4px",
      fontSize: "11px",
      borderRadius: "4px",
      border: `1px solid ${darkPurple}`,
    }}
    children={content}
  />
);

const helpers = {
  displayFilterButton: (
    onClick: (event: React.SyntheticEvent) => void,
    filteredInCount: number,
  ) => (
    <>
      <CustomButton
        label="Filters"
        sx={{ width: "80px", height: "25px", ".MuiButton-startIcon": { mr: 0 } }}
        startIcon={<FilterAlt />}
        variant="text"
        onClick={onClick}
      />
      <Typography sx={{ position: "relative", right: "5px" }} children={`(${filteredInCount})`} />
    </>
  ),
  displayResetFilterButton: (resetFilters: () => void) => (
    <CustomButton
      label="Reset"
      sx={{ width: "80px", height: "25px", ".MuiButton-startIcon": { mr: 0 } }}
      variant="text"
      onClick={(e) => {
        e.preventDefault();
        resetFilters();
      }}
    />
  ),
  displayCloseFiltersButton: (
    toggleFiltersCollapse: (event: React.SyntheticEvent, forceOpenOrClose?: boolean) => void,
  ) => (
    <CustomButton
      trackingLabel="Close find staff to work Filters"
      size="small"
      variant="outlined"
      sx={{
        position: "absolute",
        right: "10px",
        top: "10px",
        border: `1px solid ${mediumGray}`,
      }}
      iconOnly
      startIcon={<Close />}
      onClick={(event) => toggleFiltersCollapse(event, false)}
    />
  ),
  displayActiveFilterTags: (
    filters: THouseViewState["findStaffToWork"]["filters"],
    availableCategories: IStaffCategory[],
  ) => {
    if (!filters) return <></>;

    const tagLimit = 3;
    const filterTagsToShow = [];

    if (filters.hideOvertime) {
      filterTagsToShow.push("-OT");
    }

    const selectedStaffCategoryCount = filters.staffCategories?.length || 0;
    const missingCategories = availableCategories.filter(
      (category) => !filters.staffCategories?.includes(category.key),
    );
    if (selectedStaffCategoryCount === 1) {
      const selectedCategory = availableCategories.find(
        (category) => category.key === filters.staffCategories![0],
      );
      if (selectedCategory) filterTagsToShow.push(selectedCategory.name);
    } else if (missingCategories.length === 1) {
      filterTagsToShow.push(`-${missingCategories[0]!.name}`);
    }

    if (filters.preferredWorkingHours?.length === 1) {
      switch (filters.preferredWorkingHours[0]) {
        case EHVTimeRange.day7A7P:
          filterTagsToShow.push(<LightMode sx={{ fontSize: "12px" }} />);
          break;
        case EHVTimeRange.night7P7A:
          filterTagsToShow.push(<DarkMode sx={{ fontSize: "12px" }} />);
          break;
      }
    }

    switch (filters.employmentTypes?.length) {
      case 0:
      case 3:
        break; // do nothing
      case 1:
        filterTagsToShow.push(`+${employmentTypeLabels[filters.employmentTypes[0]!]}`);
        break;
      case 2:
        const missing = difference(
          values(EHVEmploymentTypeFilter),
          filters.employmentTypes || [],
        )[0];
        missing && filterTagsToShow.push(`-${employmentTypeLabels[missing]}`);
        break;
    }

    if (filters.unit?.length) {
      const label =
        filters.unit.length === 2
          ? "all units"
          : filters.unit[0] === "HOME"
            ? "Home unit"
            : "-Home unit";
      filterTagsToShow.push(label);
    }
    if (filter(filters.shiftTypeKeys)?.length) {
      filterTagsToShow.push(`+${filter(filters.shiftTypeKeys).length} shift types`);
    }

    return (
      <>
        {filterTagsToShow.map((filterToShow, index) => {
          if (index < tagLimit) {
            return FilterTag(filterToShow, index);
          }
          if (index === tagLimit) {
            return FilterTag(`+${filterTagsToShow.length - tagLimit}`, index);
          }

          return null;
        })}
      </>
    );
  },

  filterSection: ({
    title,
    items,
  }: {
    title: string;
    items: ({
      key: string;
      checked: boolean | undefined;
      action: () => void;
    } & (
      | {
          label: string;
          trackingLabel?: string;
        }
      | {
          label: ReactNode;
          trackingLabel: string;
        }
    ))[];
  }) => {
    return (
      <Box key={"find-staff-for-work-filter" + title}>
        <Typography sx={{ pt: 1 }}>{title}</Typography>
        <Box display="grid" gridTemplateColumns={"1fr 1fr"}>
          {items.map(({ key, trackingLabel, label, checked, action }) => {
            // CF types above: if no tracking label is provided, label is a string;
            const stringTrackingLabel = trackingLabel || (label as string);

            return (
              <Box
                key={key}
                display={"flex"}
                sx={{
                  cursor: "pointer !important",
                  ":hover": { backgroundColor: lighten(mediumGray, 0.5) },
                }}
                alignItems={"center"}
                onClick={action}
              >
                <Checkbox checked={checked || false} trackingLabel={stringTrackingLabel} />
                <Typography>{label}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  },
};

const employmentTypeLabels: { [key in EHVEmploymentTypeFilter]: string } = {
  [EHVEmploymentTypeFilter.other]: "FT/PT",
  [EHVEmploymentTypeFilter.perDiem]: "PRN",
  [EHVEmploymentTypeFilter.travelStaff]: "TVL",
} as const;
