import { useCallback, useEffect, useMemo, useState } from "react";

import { EUnitPermissionAreas, Timezone } from "@m7-health/shared-utils";
import { groupBy, keys, map, orderBy, uniq } from "lodash";

import { QueryBuilder } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Chip, Stack, Tooltip, Typography, lighten } from "@mui/material";

import {
  INote,
  IStaffCategory,
  IStaffDetails,
  IStaffShift,
  ShiftTypeHelpers,
  StaffCategory,
  StaffDetails,
  useListScheduleShiftTypeQuery,
} from "~/api";
import CustomCollapse from "~/common/components/Collapse";
import CustomTabs from "~/common/components/Tabs";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { black, darkPurple, mediumGray, white } from "~/common/theming/colors";
import { KeyBy } from "~/common/types";
import { isOnMobile } from "~/common/utils/isOnMobile";
import { IUnitBasic } from "~/routes/api/types";

import { useAppConfigQuery } from "#/features/User/queries";
import { CustomButton } from "@/common/components";
import {
  useAppFlags,
  useCheckUserPermission,
  useFacilityTimezones,
  useIsKiosk,
} from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { dateToDateKey, timezoneLabel } from "@/common/utils/dates";

import { HouseViewHooks } from "../hooks";
import { houseViewStore } from "../store";

import { HouseView } from ".";

const { useStaffingTabs, useSortedStaffShifts } = HouseViewHooks;

const emptyCategories: IStaffCategory["key"][] = [];
const emptyShifts = [] as IStaffShift[];

const UnitCard = ({
  withPatientCount,
  unit,
  notes,
  staffDetails,
  staffDatesMetadata,
  shifts,
  onSelect,
  otherShifts,
  showNotes = false,
}: {
  unit: IUnitBasic;
  shifts: IStaffShift[];
  staffDatesMetadata?: KeyBy<StaffDetails.ShiftMetadataDTO, "staffId">;
  staffDetails: KeyBy<IStaffDetails, "userId">;
  notes: KeyBy<INote, "userId">;
  onSelect?: (unit: IUnitBasic) => void;
  otherShifts?: (category: StaffCategory.EKey) => React.ReactNode;
  showNotes?: boolean;
  withPatientCount?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { newStaffingTargetsModal, updateStaffingLevelInUnitCard } = useAppFlags();
  const facilityHasManyTimezones = useFacilityTimezones().length > 1;

  const selected = useAppSelector(
    (state) => state.houseView.pageFilters.selectedUnitId === unit.id,
  );
  const selectedDate = useAppSelector((state) => state.houseView.pageFilters.selectedDate)!;

  const dateBeforeToday = selectedDate < dateToDateKey(localDayJs());
  withPatientCount = withPatientCount && updateStaffingLevelInUnitCard;
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const hideUpdatePatientCountButton =
    isKioskUser ||
    !canManage ||
    isOnMobile() ||
    !withPatientCount ||
    (dateBeforeToday && !newStaffingTargetsModal);

  const floatToUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
  const [isExpandedByDefault, setIsExpandedByDefault] = useState(true);
  const floatToCategory = useAppSelector(
    (state) => state.houseView.pageFilters.selectedStaffCategory,
  );
  const actionIsInProgress = useAppSelector(
    (state) => !!state.houseView.sidebarCurrentAction.inProgress,
  );
  const actionIsDirty = useAppSelector((state) => state.houseView.sidebarCurrentAction.isDirty);
  const toastActionIsDirty = useCallback(() => {
    if (!selected) toast.showInfo("Cannot select a new unit, cancel action or save changes first");
  }, [toast, selected]);
  // Category for this unit
  const appConfig = useAppConfigQuery().data;
  const unitCategories =
    appConfig?.accessibleUnits.find(({ id }) => id === unit.id)?.staffCategoryKeys ||
    emptyCategories;
  const allAvailableCategories = appConfig?.staffCategories;

  // Filter out the house supervisor category
  const categories = useMemo(
    () =>
      orderBy(
        allAvailableCategories?.filter(
          ({ key }) => unitCategories.includes(key) && key !== "houseSupervisor",
        ),
        "name",
      ),
    [unitCategories, allAvailableCategories],
  );

  // Staff Category filter
  const [selectedStaffCategory, __setSelectedStaffCategory] = useState<
    StaffCategory.EKey | string | undefined
  >(categories[0]?.key);

  const setSelectedStaffCategory = useCallback(
    (category: StaffCategory.EKey | string, setUnit = true) => {
      // Do not change category if something is in progress
      if (!selected || !actionIsInProgress) {
        __setSelectedStaffCategory(category);
        setIsExpandedByDefault(true);
      }
      // Only change selection if no action is in progress, and a unit is
      //  already selected.
      if (!actionIsInProgress && setUnit) onSelect?.(unit);
    },
    [onSelect, selected, actionIsInProgress, unit],
  );

  useEffect(() => {
    if (selected && selectedStaffCategory && !actionIsInProgress) {
      dispatch(houseViewStore.state.selectStaffCategory(selectedStaffCategory));
    }
  }, [selectedStaffCategory, dispatch, selected, actionIsInProgress]);

  const scheduleIds = uniq(map(shifts, "scheduleId"));
  const { data: shiftTypes = [] } = useListScheduleShiftTypeQuery(
    { scheduleIds },
    { skip: !scheduleIds.length },
  );
  const sortedShiftTypes = useMemo(
    () => ShiftTypeHelpers.byScheduleIdByShiftTypeKey(shiftTypes),
    [shiftTypes],
  );

  // Filtered shifts, based on selected category
  const shiftByCategory = useMemo(() => {
    if (!selectedStaffCategory) return undefined;

    const groupedShifts = groupBy(
      shifts,
      ({ staffId }) => staffDetails[staffId]?.staffType.staffCategoryKey,
    );
    keys(groupedShifts).forEach((categoryKey) => {
      groupedShifts[categoryKey] = groupedShifts[categoryKey]!.filter(
        ({ scheduleId, shiftTypeKey }) =>
          sortedShiftTypes[scheduleId]?.[shiftTypeKey]?.isCountedForRealTimeStaffingTarget,
      );
    });

    return groupedShifts;
  }, [selectedStaffCategory, sortedShiftTypes, shifts, staffDetails]);

  useEffect(() => {
    if (actionIsInProgress && floatToCategory && selectedStaffCategory !== floatToCategory) {
      setSelectedStaffCategory(floatToCategory, false);
    }
    if (!actionIsInProgress) {
      setIsExpandedByDefault(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionIsInProgress]);

  const borderWidth = selected ? "3px" : "1px";

  const allStaffingTargets = useAppSelector(
    (state) => state.houseView.staffingLevels.allStaffingTargets,
  );
  const selectedStaffingTargetsArray = allStaffingTargets[unit.id] || [];
  const latestStaffingTargetLevel = selectedStaffingTargetsArray?.[0];
  const latestPatientCount = latestStaffingTargetLevel?.patientCount;

  const tabs = useStaffingTabs(
    unit.id,
    categories,
    shiftByCategory,
    selectedStaffCategory,
    selectedDate,
    latestStaffingTargetLevel,
  );

  const unitName = useMemo(() => {
    if (!facilityHasManyTimezones) return unit.name;

    return (
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography children={unit.name} />
        <Tooltip
          title={
            <Typography
              children={`${unit.timezone}  (${timezoneLabel(unit.timezone as Timezone, { format: "long" })})`}
              sx={{ color: white, fontSize: "13px" }}
            />
          }
        >
          <Chip
            className="timezone-chip"
            size="small"
            icon={<QueryBuilder />}
            label={timezoneLabel(unit.timezone as Timezone)}
          />
        </Tooltip>
      </Stack>
    );
  }, [facilityHasManyTimezones, unit.name, unit.timezone]);

  const selectedTab = tabs.find(({ value }) => value === selectedStaffCategory);
  const shiftsToDisplay = useSortedStaffShifts(selectedTab?.shifts || emptyShifts, staffDetails);

  // Set default category
  useEffect(() => {
    if (!categories[0]) return;
    if (selectedTab?.customPosition) return;

    if (
      !categories.find(({ key }) => key === selectedStaffCategory) ||
      selectedStaffCategory === undefined
    ) {
      setSelectedStaffCategory(categories[0].key, false);
    }
  }, [
    categories,
    selectedStaffCategory,
    setSelectedStaffCategory,
    shifts,
    staffDetails,
    selectedTab?.customPosition,
  ]);

  if (!selectedStaffCategory) return <></>;

  return (
    <Box
      className="unit-card"
      key={unit.id + categories.map(({ key }) => key).join("")}
      display="flex"
      minWidth={"300px"}
      flex={"auto"}
      flexDirection="column"
      height={"fit-content"}
      mt={3}
      mb={3}
      p={1}
      pl={0}
      position={"relative"}
      sx={{
        border: `${borderWidth} solid ${selected ? darkPurple : mediumGray}`,
        borderRadius: 2,
        borderTopLeftRadius: 0,
      }}
      mr={2}
      onClick={() => (actionIsDirty ? toastActionIsDirty() : onSelect?.(unit))}
    >
      {/* If on Mobile, show the patient count, else show tabs etc. */}
      {isOnMobile() ? (
        <Box className="unit-data-wrapper">
          <Box display="flex" flexDirection="row" alignItems="start" justifyContent="space-between">
            <Typography className="unit-name" children={unitName} />
            <CustomButton
              onClick={() => {
                dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true));
                dispatch(houseViewStore.state.setSelectedUnitId(unit.id));
              }}
              sx={{
                top: "-4px",
                // icon styling
                minWidth: "30px",
                padding: "0px 0px !important",
                ".MuiButton-startIcon": { marginRight: 0, marginLeft: 0 },
                borderRadius: 100,
              }}
              startIcon={<AddIcon />}
              color="darkPurple"
              trackingLabel="Update Patient Count"
            />
          </Box>
          <Box className="staff-counts">
            <Typography>
              <b>Patients</b>:{" "}
              <span className="patient-count-number">{latestPatientCount || "N/A"}</span>
            </Typography>
            {tabs.map((tab) => (
              <Typography sx={tab.sx}>{tab.label}</Typography>
            ))}
          </Box>
        </Box>
      ) : (
        <>
          <CustomTabs<StaffCategory.EKey | string>
            onChange={setSelectedStaffCategory}
            tabs={tabs}
            value={selectedStaffCategory}
            height="30px"
            sx={{
              "& > .MuiBox-root": { borderBottom: "none" },
              position: "absolute",
              top: "-30px",
              ...(selected && actionIsInProgress
                ? {
                    pointerEvents: "none",
                  }
                : {}),
              left: selected ? "-7px" : "-5px",
              ".custom-tab": {
                border: "none",
                borderBottom: `${borderWidth} solid ${selected ? darkPurple : mediumGray}`,
                color: lighten(black, 0.2),
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  background: "rgba(255,255,255,0.2)",
                },
                "&.selected": {
                  zIndex: 10,
                  borderLeft: `${borderWidth} solid ${
                    selectedStaffCategory && selected ? darkPurple : mediumGray
                  }`,
                  borderRight: `${borderWidth} solid ${
                    selectedStaffCategory && selected ? darkPurple : mediumGray
                  }`,
                  borderTop: `${borderWidth} solid ${
                    selectedStaffCategory && selected ? darkPurple : mediumGray
                  }`,
                  borderBottom: "none",
                  color: black,
                  "&:after": {
                    background: "rgba(255,255,255,0)",
                  },
                },
              },
              ".MuiTabs-indicator": {
                display: "none",
              },
            }}
          />
          {!hideUpdatePatientCountButton && withPatientCount && newStaffingTargetsModal && (
            <CustomButton
              onClick={() => {
                dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true));
                dispatch(houseViewStore.state.setSelectedUnitId(unit.id));
              }}
              sx={{
                position: "absolute",
                // brings it all the way to the right horizontally
                right: 0,
                // brings it above the card next to the tabs vertically
                top: "-30px",
                // icon styling
                minWidth: "30px",
                padding: "0px 0px !important",
                ".MuiButton-startIcon": { marginRight: 0, marginLeft: 0 },
                borderRadius: 100,
              }}
              startIcon={<AddIcon />}
              color="darkPurple"
              trackingLabel="Update Patient Count"
            />
          )}
          {shiftsToDisplay ? (
            <CustomCollapse
              label={unitName}
              isExpandedByDefault={isExpandedByDefault}
              {...(floatToUnitId && { isOpen: isExpandedByDefault })}
              styles={{ mt: 0 }}
              content={
                <Box sx={{ height: "380px", overflowY: "scroll" }}>
                  {shiftsToDisplay.map((shift) => (
                    <HouseView.UnitCardStaff
                      key={"unit-card-staff-member-" + shift.id}
                      unitId={unit.id}
                      highlightIfEligible
                      compactShiftTag
                      shift={shift}
                      staffDatesMetadata={staffDatesMetadata}
                      staffDetails={staffDetails}
                      notes={notes}
                      showNotes={showNotes}
                    />
                  ))}
                  {selectedTab?.customPosition
                    ? null
                    : otherShifts?.(selectedStaffCategory as StaffCategory.EKey)}
                </Box>
              }
            />
          ) : (
            "No staff planned for this day"
          )}
        </>
      )}
    </Box>
  );
};

export const _HouseView_UnitCard = UnitCard;
