import { buildApiListQueryHook, buildPaginatedApiListQueryHook } from "../_shared/helpers";
import { staleFor } from "../_shared/types";

import { Schedule } from "./types";

export const useListSchedulesQuery = buildApiListQueryHook<
  Schedule.API.List.QueryParams,
  Schedule.API.List.Response
>(Schedule.API.List.PATH, {
  staleTime: staleFor(1).minute,
});

export const usePaginatedListSchedulesQuery = buildPaginatedApiListQueryHook<
  Schedule.API.List.QueryParams,
  Schedule.DTO
>(Schedule.API.List.PATH, {
  staleTime: staleFor(1).minute,
});
