/* eslint-disable no-console */

import { StaffDetails } from "~/api";

import { NameCell } from "./NameCell";

export const _SchedulerScheduleTableStaffNameHeaderCell = ({
  staffId,
  staffDetails,
}: {
  staffId: string;
  staffDetails: StaffDetails.DTO | undefined;
}) => {
  return <NameCell staffId={staffId} staffDetails={staffDetails} />;
};
