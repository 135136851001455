import { createSlice } from "@reduxjs/toolkit";
import { keys } from "lodash";

import { useAppDispatch } from "@/common/hooks/useRedux";

import { AttributeActions, AttributeActionsInitState, TAttributeState } from "./attibuteActions";
import { ShiftTypeActions, ShiftTypeActionsInitState, TShiftTypeState } from "./shiftTypeActions";
import { StaffTypeActions, StaffTypeActionsInitState, TStaffTypeState } from "./staffTypeActions";
import {
  TUnitTargetLevelState,
  UnitTargetLevelActions,
  UnitTargetLevelActionsInitState,
} from "./unitTargetLevelActions";

export type THouseViewState = {
  shiftTypes: TShiftTypeState;
  staffTypes: TStaffTypeState;
  attributes: TAttributeState;
  unitTargetLevels: TUnitTargetLevelState;
};

const initialState = {
  shiftTypes: ShiftTypeActionsInitState,
  staffTypes: StaffTypeActionsInitState,
  attributes: AttributeActionsInitState,
  unitTargetLevels: UnitTargetLevelActionsInitState,
};

const AdminPanelStore = createSlice({
  name: "adminPanel",
  initialState,
  reducers: {
    resetState: () => initialState,
    ...ShiftTypeActions,
    ...StaffTypeActions,
    ...AttributeActions,
    ...UnitTargetLevelActions,
  },
});

type TActions = typeof AdminPanelStore.actions;
type TWrappedFunctions = {
  [key in keyof TActions]: (...args: Parameters<TActions[key]>) => void;
};
export const useDispatch = () => {
  const dispatch = useAppDispatch();

  return keys(AdminPanelStore.actions).reduce(
    <K extends keyof TActions>(acc: TWrappedFunctions, fnName: K) => {
      const fn = AdminPanelStore.actions[fnName];
      if (fn)
        acc[fnName] = (...args: unknown[]) => {
          // @ts-ignore can pass its own args
          dispatch(fn(...args));
        };

      return acc;
    },
    {} as TWrappedFunctions,
  );
};

export default AdminPanelStore.reducer;
