import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { staleFor } from "~/api/_shared/types";
import { useCurrentUnitId } from "~/common/hooks/useCurrentUnitId";
import { useAppSelector } from "~/common/hooks/useRedux";
import { EStaffTypeKey, EStaffTypeName, Uuid } from "~/common/types";
import {
  getAllStaffAttributes,
  getFacilitiesApi,
  getShiftsApi,
  getShiftsByScheduleApi,
  getShiftsByUnitApi,
  getStaffAttributesForUnitApi,
  getStaffAttributesForUnitCustomSelectApi,
  getStaffCategoriesTargetLevelApi,
  getStaffTypeCategoryNameToKey,
  getStaffTypeNamesApi,
  getStaffTypesAndCategoriesApi,
  getUnitsApiForSelect,
  listUnitsApi,
} from "~/routes/api";

import { IAttribute, IStaffCategory } from "@/api";

import { IStaffAttributeCustomSelect } from "../api/types";

export const useFacilitiesQuery = () => {
  return useQuery({ queryKey: ["facilities"], queryFn: getFacilitiesApi, staleTime: Infinity });
};

export const useListUnitsQuery = () => {
  return useQuery({ queryKey: ["units"], queryFn: listUnitsApi });
};
export const useUnitForSelectQuery = () =>
  useQuery({ queryKey: ["units"], queryFn: getUnitsApiForSelect, staleTime: 5000 });
/** @deprecated, use useListUnitsQuery or useUnitForSelectQuery instead */
export const useUnitQuery = useUnitForSelectQuery;

export const useStaffAttributesForUnitCustomSelectQuery = (
  unitId?: Uuid,
): UseQueryResult<IStaffAttributeCustomSelect[]> => {
  const unitIdFromState = useAppSelector((state) => state.common.selectedUnit?.id);

  return useQuery({
    staleTime: Infinity,
    queryKey: ["attributes", unitId || unitIdFromState || ""],
    queryFn: () => getStaffAttributesForUnitCustomSelectApi(unitId || unitIdFromState || ""),
  });
};

export const useStaffAttributesForUnitQuery = (unitId?: Uuid): UseQueryResult<IAttribute[]> => {
  const unitIdFromState = useAppSelector((state) => state.common.selectedUnit?.id);

  return useQuery({
    staleTime: Infinity,
    queryKey: ["attributes", unitId || unitIdFromState || ""],
    queryFn: () => getStaffAttributesForUnitApi(unitId || unitIdFromState || ""),
  });
};

export const useAllStaffAttributesQuery = (): UseQueryResult<IStaffAttributeCustomSelect[]> => {
  return useQuery({
    staleTime: Infinity,
    queryKey: ["all-attributes"],
    queryFn: () => getAllStaffAttributes(),
  });
};

export const useShiftsQuery = () => {
  return useQuery({ staleTime: Infinity, queryKey: ["shifts"], queryFn: getShiftsApi });
};

/**
 * Custom hook to fetch shifts by unit.
 *
 * @param unitIds - An optional array of unit IDs. If not provided, will use the common unit ID (works for scheduler app or staff app)
 * @returns The result of the query.
 */
export const useShiftsByUnitQuery = (unitIds?: Uuid[]) => {
  // this commonUnitId is coming from the 1) redux store if in scheduler app or 2) current calendar or config for staff user
  const commonUnitId = useCurrentUnitId();
  unitIds ||= [commonUnitId || ""];

  return useQuery({
    staleTime: Infinity,
    queryKey: ["shiftByUnit", unitIds],
    queryFn: () => getShiftsByUnitApi(unitIds || [""]),
  });
};

export const useShiftsByScheduleQuery = (scheduleId?: string) => {
  return useQuery({
    staleTime: Infinity,
    queryKey: ["shiftBySchedule", scheduleId],
    queryFn: () => getShiftsByScheduleApi(scheduleId || ""),
  });
};

export const useStaffTypeNamesQuery = (): UseQueryResult<
  {
    label: EStaffTypeName;
    value: EStaffTypeName;
    key: EStaffTypeKey;
    staffCategoryKey: IStaffCategory["key"];
    staffCategoryName: IStaffCategory["name"];
  }[]
> => {
  return useQuery({
    queryKey: ["staffTypeNames"],
    queryFn: getStaffTypeNamesApi,
    staleTime: Infinity,
  });
};

export const useStaffCategoriesTargetLevelQuery = () => {
  return useQuery({
    queryKey: ["staffCategoriesTargetLevel"],
    queryFn: getStaffCategoriesTargetLevelApi,
  });
};

export const useStaffTypesAndCategories = () => {
  return useQuery({
    queryKey: ["staffTypesAndCategories"],
    queryFn: getStaffTypesAndCategoriesApi,
    staleTime: Infinity,
  });
};

export const useStaffTypeCategoryNameToKey = () => {
  return useQuery({
    queryKey: ["staffTypeCategoryNameToKey"],
    queryFn: getStaffTypeCategoryNameToKey,
    staleTime: staleFor(5).hours,
  });
};
