import { Unit } from "@m7-health/shared-utils";
import { createSelector } from "reselect";

import { RealTimeStaffingTarget } from "~/api/realTimeStaffingTargets";
import { TRootState } from "~/store";

import { TPositionAsTab } from "#/features/HouseView/hooks/useStaffingTabs";
import { StaffCategory } from "@/api";
import { localDayJs } from "@/common/packages/dayjs";
import { add24Hours } from "@/common/utils/dates";

export type TUpdatedStaffingLevel = Partial<RealTimeStaffingTarget.DTO>;

export const staffingLevelSelector = (unitId: string) =>
  createSelector(
    [(state: TRootState) => state.houseView.staffingLevels.editedStaffingTargets?.[unitId]],
    (staffingLevel) => staffingLevel,
    {
      memoizeOptions: (previousData: object, currentData: object) => {
        return JSON.stringify(previousData) === JSON.stringify(currentData);
      },
    },
  );

/**
 * Retrieves the matching staffing level based on the staffing level matrix.
 *
 * @param staffingLevelMatrix - The staffing level matrix.
 * @param staffCategory - The staff category.
 * @param unitStaffingLevel - The unit staffing level.
 * @returns The matching staffing level.
 */
export const getMatchingStaffingLevel = (
  staffCategoryKeyOrPosition: StaffCategory.EKey | TPositionAsTab,
  unitStaffingLevel: Partial<RealTimeStaffingTarget.DTO> | undefined,
  staffingLevelMatrix?: Unit.TStaffingLevelMatrix | undefined,
) => {
  // get possible staffing levels for the current patient count and category
  const possibleLevels =
    staffingLevelMatrix?.[unitStaffingLevel?.patientCount || 0]?.[staffCategoryKeyOrPosition];

  // for each possible level, if the end time is before the start time, add 24 hours to the end time
  possibleLevels?.forEach((level) => {
    if (level.startTime.localeCompare(level.endTime) > 0) {
      level.endTime = add24Hours(level.endTime);
    }
  });
  const unitStaffingLevelTime = localDayJs(unitStaffingLevel?.date).format("HH:mm:ss");
  const matchingStaffingLevel = possibleLevels?.find(
    (possibleLevel) =>
      unitStaffingLevelTime.localeCompare(possibleLevel.startTime) >= 0 &&
      unitStaffingLevelTime.localeCompare(possibleLevel.endTime) < 0,
  );
  return matchingStaffingLevel;
};
