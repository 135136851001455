import { useEffect, useState } from "react";

import { keyBy } from "lodash";

import { Box, CircularProgress, Typography } from "@mui/material";

import {
  RealTimeStaffingTarget,
  useCreateRealTimeStaffingTargetMutation,
  useListRealTimeStaffingTargetsQuery,
} from "~/api/realTimeStaffingTargets";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { localDayJs } from "~/common/packages/dayjs";
import { getReadableDateWithTime } from "~/common/utils/dates";
import { useListUnitsQuery } from "~/routes/queries";

import { useAppFlags } from "@/common/hooks";

import { houseViewStore } from "../../../store";

import { Table } from "./DeprecatedTable/Table";

const DeprecatedStaffingLevelModal = () => {
  // high level
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { newStaffingTargetsModal } = useAppFlags();

  // general state
  const { selectedFacilityId, filteredUnitIds } = useAppSelector((state) => ({
    selectedFacilityId: state.houseView.pageFilters.selectedFacilityId,
    filteredUnitIds: state.houseView.pageFilters.unitIds,
  }));
  const isOpen =
    useAppSelector((state) => Boolean(state.houseView.staffingLevels.modalIsOpen)) &&
    !newStaffingTargetsModal;
  // queries
  const { data: units = [], isLoading: isLoadingUnits } = useListUnitsQuery();
  const unitsInFacilitySelected = units.filter(
    (unit) =>
      unit.facility?.id === selectedFacilityId &&
      (!filteredUnitIds.length || filteredUnitIds.includes(unit.id)),
  );
  const unitIdsToQuery = unitsInFacilitySelected.map((unit) => unit.id);
  const {
    data: selectedStaffingTargetsArray,
    isLoading: isLoadingStaffingTargets,
    refetch: refetchStaffingTargets,
  } = useListRealTimeStaffingTargetsQuery({
    unitIds: unitIdsToQuery,
    latest: true,
  });

  // setting the default staffing targets to 0 for each unit and then overwriting them with the ones from the API if they exist
  const selectedStaffingTargetsByUnit = keyBy(selectedStaffingTargetsArray, "unitId");
  const lastUpdatedAt = selectedStaffingTargetsArray?.[0]?.createdAt || undefined;
  // only set the edited staffing targets once page loads or when the selected facility changes
  // overwrite the default staffing targets with the ones from the API if they exist for that unit
  const defaultStartingStaffingTargets: {
    [unitId: string]: Partial<RealTimeStaffingTarget.DTO>;
  } = keyBy(
    unitsInFacilitySelected.map(
      (unit): Partial<RealTimeStaffingTarget.DTO> => ({
        patientCount: 0,
        unitId: unit.id,
      }),
    ),
    "unitId",
  );
  useEffect(() => {
    if (!newStaffingTargetsModal) {
      dispatch(
        houseViewStore.state.setEditedStaffingTargets({
          ...defaultStartingStaffingTargets,
          ...selectedStaffingTargetsByUnit,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoadingStaffingTargets, selectedFacilityId]);

  //helper
  const closeModalAndDiscardChanges = () => {
    dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(false));
    dispatch(houseViewStore.state.setEditedStaffingTargets(selectedStaffingTargetsByUnit));
  };

  const [_numberOfStaffingLevelsSaved, setNumberOfStaffingLevelsSaved] = useState(0);
  const { isPending: isStaffingSubmitting, mutate: staffingSubmitRequest } =
    useCreateRealTimeStaffingTargetMutation({
      onError: (error: Error) => {
        toast.showError(error.message);
      },
      onSuccess: () => {
        setNumberOfStaffingLevelsSaved((prev) => {
          // onSuccess will only run after prev + 1 has been incremented to the final value of unitsInFacilitySelected.length
          if (prev + 1 === unitsInFacilitySelected.length) {
            toast.showSuccess("Staffing levels have been updated successfully");
            setIsSubmitModalOpen(false);
            dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(false));
            void refetchStaffingTargets();
            return 0;
          }
          return prev + 1;
        });
      },
    });

  // extra modal
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const SubmitModal = () => {
    const allEditedStaffingTargets = useAppSelector(
      (state) => state.houseView.staffingLevels.editedStaffingTargets,
    );
    const submitStaffing = () => {
      Object.values(allEditedStaffingTargets).forEach((editedStaffingTarget) => {
        if (editedStaffingTarget && editedStaffingTarget.unitId && editedStaffingTarget.date) {
          staffingSubmitRequest(
            editedStaffingTarget as RealTimeStaffingTarget.API.Create.QueryParams,
          );
        }
      });
    };

    return (
      <CustomModal
        isOpen={isSubmitModalOpen}
        primaryBtnText="Confirm"
        modalContent={
          <Typography>
            Are you sure you want to update the patient count and staffing levels?
          </Typography>
        }
        modalHeaderText="Submit your patient count and staffing level updates"
        primaryDisabled={isStaffingSubmitting}
        onSecondaryBtnClick={() => setIsSubmitModalOpen(false)}
        onSubmit={submitStaffing}
      />
    );
  };

  // general modal content
  const modalContent = // wrap in Box to allow for scrolling with maxHeight and overflow
    (
      <Box
        sx={{
          overflow: "auto",
          maxHeight: "60vh",
        }}
      >
        {lastUpdatedAt && (
          <Typography variant="small">
            Last Updated At: {getReadableDateWithTime(localDayJs(lastUpdatedAt))}
          </Typography>
        )}
        <Table units={unitsInFacilitySelected} />
      </Box>
    );

  if (isLoadingUnits && isLoadingStaffingTargets) {
    return <CircularProgress />;
  }

  // when hit Save... send all staffing levels to API, not just updated ones
  return (
    <>
      <CustomModal
        classes={{ root: "house-view-modal" }}
        isOpen={isOpen}
        primaryBtnText="Save"
        modalContent={modalContent}
        modalHeaderText={`Update Patient Count and Staffing Levels`}
        onSecondaryBtnClick={closeModalAndDiscardChanges}
        onSubmit={() => {
          setIsSubmitModalOpen(true);
        }}
        primaryDisabled={isStaffingSubmitting}
        customWidth="1200px"
      />
      <SubmitModal />
    </>
  );
};

export const HouseViewDeprecatedStaffingLevelModal = DeprecatedStaffingLevelModal;
