import { ERoles } from "~/features/User/types";

import { ESchedulePageTabs } from "#/features/SchedulerGrid/types";

import { TDefaultRoleRoute } from "./types";

// Default route for each role
export const defaultRoleRoute: TDefaultRoleRoute = {
  [ERoles.Admin]: "/schedule",
  [ERoles.Scheduler]: "/schedule",
  [ERoles.Staff]: "/calendar",
  [ERoles.Kiosk]: `/schedule/${ESchedulePageTabs.planning}`,
};
