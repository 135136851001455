import React from "react";

import { Box, Grid, Typography } from "@mui/material";

import { Unit } from "~/api";
import { ExpandMore } from "~/common/components/ExpandMore/ExpandMore";
import { useAppSelector } from "~/common/hooks/useRedux";
import { lightGray, lines } from "~/common/theming/colors";
import { EHVTimeRange } from "~/features/HouseView/store/pageFiltersActions";

export const HeaderNameCell = ({
  unit,
  expanded,
  setExpandedUnits,
}: {
  unit: Unit.DTO;
  expanded: boolean;
  setExpandedUnits: (value: React.SetStateAction<Record<string, boolean>>) => void;
}) => {
  const unitName = unit.name;
  const unitId = unit.id;
  const timeRange = useAppSelector((state) => state.houseView.pageFilters.timeRange);
  const timeRangesToShow =
    timeRange === EHVTimeRange.all ? [EHVTimeRange.day7A7P, EHVTimeRange.night7P7A] : [timeRange];

  return (
    <Grid container flexDirection="column">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        sx={{
          minWidth: "300px",
          height: "52px",
        }}
        item
      >
        <Grid container item display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="small" fontSize={16} fontWeight={600}>
            {unitName}
          </Typography>
          <Box
            onClick={() =>
              setExpandedUnits((previous) => ({
                ...previous,
                [unitId]: !expanded,
              }))
            }
            display={"inline"}
          >
            <Typography variant="small" fontSize={13} fontWeight={300} flexWrap={"nowrap"}>
              See Schedule
              <ExpandMore direction="right" expand={expanded} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        item
        flexDirection="column"
        flexWrap="nowrap"
        sx={{
          borderTop: `1px solid ${lines}`,
        }}
      >
        {timeRangesToShow.map((aRange) => (
          <Grid
            sx={{
              height: `${26}px`,
              background: lightGray,
              borderTop: `${1}px solid ${lines}`,
              borderBottom: `0px solid ${lines}`,
            }}
            container
            alignItems="center"
          >
            <Typography pl={2} fontSize="0.75rem" fontWeight="400">
              {aRange === EHVTimeRange.day7A7P ? "Day" : "Night"}
              {/* {aRange === EHVTimeRange.day7A7P ? (
                <Shift fullWidth miniView={true} option={"day"} hoursVariant="short" />
              ) : (
                <Shift fullWidth miniView={true} option={"night"} hoursVariant="short" />
              )} */}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
