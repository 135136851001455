import { useEffect, useState } from "react";

import { THouseViewTimeRange } from "@m7-health/shared-utils";
import { filter, isEqual, orderBy } from "lodash";

import { Box, Divider, Typography } from "@mui/material";

import {
  useCreateRealTimeStaffingTargetMutation,
  useDeleteRealTimeStaffingTargetMutation,
  useListRealTimeStaffingTargetsQuery,
  useUpdateRealTimeStaffingTargetMutation,
} from "~/api/realTimeStaffingTargets";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { localDayJs } from "~/common/packages/dayjs";
import {
  getReadableDate,
  getTzFormattedDate,
  isSpecificHourInTimeString,
  timeAdd,
  TimeStringToStandardTime,
  trimMs,
} from "~/common/utils/dates";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import { IUnitBasic } from "~/routes/api/types";
import { useListUnitsQuery } from "~/routes/queries";

import { T_24_HOURS_IN_SECONDS } from "#/features/AdminPanel/constants";
import { emptyStaffingTargetsArray } from "#/features/HouseView/hooks/useSetStaffingTargets";
import { DEFAULT_CUSTOM_TIME_RANGE } from "#/features/HouseView/store/pageFiltersActions";
import { IStaffShift, useInvalidateQuery, useListStaffCategoriesQuery } from "@/api";
import CustomSelect from "@/common/components/TrackedComponents/Select";
import { BULK_DELETE_KEY, BULK_UPDATE_KEY } from "@/common/constants";
import { useAppFlags, useCurrentTimezone } from "@/common/hooks";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { houseViewStore } from "../../../store";

import { UnitTable } from "./UnitTable/Table";

const dateStringInRange = (selectedTimeRange: THouseViewTimeRange, dateStringToCheck: string) => {
  const time = localDayJs(dateStringToCheck).format("HH:mm:ss");
  const startTimeNoMs = trimMs(selectedTimeRange.startTime);
  const endTimeNoMs = trimMs(selectedTimeRange.endTime);
  const endTimeIfBeforeStart =
    endTimeNoMs < startTimeNoMs ? trimMs(timeAdd(endTimeNoMs, T_24_HOURS_IN_SECONDS)) : endTimeNoMs;

  return startTimeNoMs <= time && time <= endTimeIfBeforeStart;
};

const StaffingLevelModal = ({
  shiftsByUnit,
}: {
  shiftsByUnit: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
}) => {
  /** HIGH LEVEL */
  const dispatch = useAppDispatch();
  const { newStaffingTargetsModal } = useAppFlags();
  const { showSuccess, showError } = useToast();
  const invalidateQuery = useInvalidateQuery();

  /** GENERAL STATE */
  const {
    selectedCustomTimeRangeState,
    selectedUnitId,
    selectedFacilityId,
    selectedDate,
    allStaffingTargets,
  } = useAppSelector(
    (state) => ({
      selectedCustomTimeRangeState: state.houseView.pageFilters.customTimeRange,
      selectedUnitId: state.houseView.pageFilters.selectedUnitId!,
      selectedFacilityId: state.houseView.pageFilters.selectedFacilityId,
      selectedDate: state.houseView.pageFilters.selectedDate,
      allStaffingTargets: state.houseView.staffingLevels.allStaffingTargets,
    }),
    isEqual,
  );
  const selectedCustomTimeRange = selectedCustomTimeRangeState || DEFAULT_CUSTOM_TIME_RANGE;

  const selectedStaffingTargetsArray =
    allStaffingTargets[selectedUnitId] || emptyStaffingTargetsArray;
  const newCreatedStaffingTarget = selectedStaffingTargetsArray.find((target) => !("id" in target));
  const isOpen =
    useAppSelector((state) => Boolean(state.houseView.staffingLevels.modalIsOpen)) &&
    newStaffingTargetsModal;
  // extra modal
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  /** QUERIES */
  /** UNIT QUERY AND USING IT TO GET TIME RANGE STATE */
  const { data: units = [] } = useListUnitsQuery();
  const unitsInFacilitySelected = units.filter((unit) => unit.facility?.id === selectedFacilityId);
  const selectedUnitName = unitsInFacilitySelected.find((unit) => unit.id === selectedUnitId)?.name;

  // Time Range State
  // Get facility configuration
  const selectedUnit = units.find((u) => u.facilityId === selectedFacilityId);
  const facility = selectedUnit?.facility;
  const facilityTimeRanges = facility?.configuration?.settings?.houseViewTimeRanges;

  // if on mobile, then always use the start time formatted label and never the custom abbreviation
  // else (on desktop), use the custom abbreviation and default to the start time formatted label if needed
  // filter out the time ranges that are not don't include 7am or 7pm (include if 7:30am / 7:30pm etc., that is ok)
  const facilityTimeRangesCustomSelect =
    facilityTimeRanges
      ?.filter((timeRange) => {
        return (
          (isSpecificHourInTimeString(timeRange.startTime, "7") ||
            isSpecificHourInTimeString(timeRange.endTime, "19") ||
            isSpecificHourInTimeString(timeRange.endTime, "7") ||
            isSpecificHourInTimeString(timeRange.startTime, "19")) &&
          timeRange.customAbbreviation !== "All"
        );
      })
      ?.map((timeRange) => ({
        label: isOnMobile()
          ? TimeStringToStandardTime(timeRange.startTime)
          : timeRange.customAbbreviation || TimeStringToStandardTime(timeRange.startTime),
        value: timeRange.startTime + "-" + timeRange.endTime,
        item: timeRange.startTime + "-" + timeRange.endTime,
      })) || [];

  /** STAFF CATEGORY QUERY */
  const { data: allCategories = [] } = useListStaffCategoriesQuery(
    { unitIds: [selectedUnitId || ""] },
    { skip: !selectedUnitId },
  );
  // set categories of unit once loaded
  useEffect(() => {
    if (allCategories.length > 0) {
      // Filter out the house supervisor category and order the rest by name
      const categories = orderBy(
        allCategories.filter(({ key }) => key !== "houseSupervisor"),
        "name",
      );
      dispatch(houseViewStore.state.setSelectedUnitCategories(categories));
    }
  }, [allCategories, dispatch]);
  const currentTimezone = useCurrentTimezone(selectedUnitId);

  /** HELPERS / MODALS */
  //helper
  const closeModalAndDiscardChanges = () => {
    dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(false));
    // discarding changes is done as part of the useSetStaffingTargets hook that is called on page load
  };

  const { mutateAsync: createRealTimeStaffingTarget, isPending: isCreatingRealTimeStaffingTarget } =
    useCreateRealTimeStaffingTargetMutation({});
  const { mutateAsync: updateRealTimeStaffingTarget, isPending: isUpdatingRealTimeStaffingTarget } =
    useUpdateRealTimeStaffingTargetMutation({});
  const { mutateAsync: deleteRealTimeStaffingTarget, isPending: isDeletingRealTimeStaffingTarget } =
    useDeleteRealTimeStaffingTargetMutation({});

  const SubmitModal = () => {
    const submitStaffing = () => {
      setIsSubmitModalOpen(false);
      if (
        !newCreatedStaffingTarget?.date ||
        !dateStringInRange(selectedCustomTimeRange, newCreatedStaffingTarget.date)
      ) {
        showError("Cannot submit staffing level as the time is not in the selected range");
      } else if (newCreatedStaffingTarget.patientCount < 0) {
        showError("Must input a patient count");
      } else {
        void (async () => {
          try {
            await Promise.all(
              selectedStaffingTargetsArray.map((staffingTargetPossiblyChanged) => {
                // if the staffing target is new (no id in the object), then create it
                if (!("id" in staffingTargetPossiblyChanged)) {
                  // console.log("creating");
                  return createRealTimeStaffingTarget(staffingTargetPossiblyChanged);
                } else {
                  // Check if we are deleting or creating (check deleting first, since could
                  // have updated a target and then deleted it, should still delete it)
                  if (
                    BULK_DELETE_KEY in staffingTargetPossiblyChanged &&
                    !!staffingTargetPossiblyChanged?.[BULK_DELETE_KEY]
                  ) {
                    // if the staffing target is deleted, then delete it
                    return deleteRealTimeStaffingTarget({
                      id: staffingTargetPossiblyChanged.id,
                    });
                  } else if (
                    BULK_UPDATE_KEY in staffingTargetPossiblyChanged &&
                    !!staffingTargetPossiblyChanged?.[BULK_UPDATE_KEY]
                  ) {
                    // if the staffing target is updated, then update it
                    return updateRealTimeStaffingTarget(staffingTargetPossiblyChanged);
                  }
                }
                return Promise.resolve(true);
              }),
            );
            showSuccess("The staffing levels were updated successfully");
            invalidateQuery(useListRealTimeStaffingTargetsQuery);
            closeModalAndDiscardChanges();
          } catch (error) {
            // if error, show the error message
            showError("There was a problem updating the staffing levels. Please try again");
          }
        })();
      }
    };

    return (
      <CustomModal
        isOpen={isSubmitModalOpen}
        primaryBtnText="Save"
        modalContent={
          <Typography>
            Save log for {selectedUnitName || ""} at{" "}
            {localDayJs(newCreatedStaffingTarget?.date).format("hh:mm A")}.
          </Typography>
        }
        modalHeaderText="Submit your patient count and staffing notes"
        primaryDisabled={isCreatingRealTimeStaffingTarget}
        onSecondaryBtnClick={() => setIsSubmitModalOpen(false)}
        onSubmit={submitStaffing}
      />
    );
  };

  // general modal content
  const modalContent = (
    <Box
      sx={{
        overflow: "auto",
        maxHeight: "60vh",
      }}
    >
      <Box sx={{ mt: 1 }}>
        <Typography variant="h6" mr={2} mb={2}>
          Date:{" "}
          {getReadableDate(
            localDayJs(getTzFormattedDate(localDayJs(selectedDate), currentTimezone)),
          )}
          <Box ml={2} display={"inline"}>
            {/* Range picker */}
            <CustomSelect<string>
              className="time-range-select"
              label="Time range"
              width={"130px"}
              items={facilityTimeRangesCustomSelect}
              value={selectedCustomTimeRange.startTime + "-" + selectedCustomTimeRange.endTime}
              onChange={(e) => {
                const customTimeRangeBasedOnValue = facilityTimeRanges?.find(
                  (timeRange) => timeRange.startTime + "-" + timeRange.endTime === e.target.value,
                );
                if (!customTimeRangeBasedOnValue) return;
                dispatch(houseViewStore.state.selectCustomTimeRange(customTimeRangeBasedOnValue));
              }}
            />
          </Box>
        </Typography>
        <Divider />
        <Box mt={5} display="flex" alignItems="center">
          <Typography variant="small" fontWeight={600} fontSize={17} mr={2}>
            Unit: {selectedUnitName}
          </Typography>
        </Box>
        <UnitTable
          unitStaffingLevels={selectedStaffingTargetsArray || []}
          hasNewEntry
          shiftsByUnit={shiftsByUnit}
        />
      </Box>
      {filter(
        selectedStaffingTargetsArray.map((staffingTargetPossiblyChanged) => {
          // if the staffing target is new (no id in the object)
          if (!("id" in staffingTargetPossiblyChanged)) {
            return (
              <Typography key="new created staffing target">
                Creating new log entry for <b>{selectedUnitName || ""}</b> at{" "}
                <b>{localDayJs(staffingTargetPossiblyChanged?.date).format("hh:mm A")}</b>
              </Typography>
            );
          } else {
            // Check if we are deleting or creating (check deleting first, since could
            // have updated a target and then deleted it, should still delete it)
            if (
              BULK_DELETE_KEY in staffingTargetPossiblyChanged &&
              !!staffingTargetPossiblyChanged?.[BULK_DELETE_KEY]
            ) {
              // if the staffing target is deleted
              return (
                <Typography key={`deleted staffing target id ${staffingTargetPossiblyChanged.id}`}>
                  Deleting log entry for <b>{selectedUnitName || ""}</b> at{" "}
                  <b>{localDayJs(staffingTargetPossiblyChanged?.date).format("hh:mm A")}</b>
                </Typography>
              );
            } else if (
              BULK_UPDATE_KEY in staffingTargetPossiblyChanged &&
              !!staffingTargetPossiblyChanged?.[BULK_UPDATE_KEY]
            ) {
              // if the staffing target is updated
              return (
                <Typography key={`updated staffing target id ${staffingTargetPossiblyChanged.id}`}>
                  Editing log entry for <b>{selectedUnitName || ""}</b> at{" "}
                  <b>{localDayJs(staffingTargetPossiblyChanged?.date).format("hh:mm A")}</b>
                </Typography>
              );
            }
          }
          return null;
        }),
      )}
    </Box>
  );

  // when hit Save... send all staffing levels to API, not just updated ones
  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "edit-staffing-level-modal" }}>
      <CustomModal
        classes={{ root: "house-view-modal" }}
        isOpen={isOpen}
        primaryBtnText="Save logs"
        modalContent={modalContent}
        modalHeaderText={`Update Patient Count and Staffing Levels`}
        onSecondaryBtnClick={closeModalAndDiscardChanges}
        onSubmit={() => {
          setIsSubmitModalOpen(true);
        }}
        primaryDisabled={
          isCreatingRealTimeStaffingTarget ||
          isUpdatingRealTimeStaffingTarget ||
          isDeletingRealTimeStaffingTarget
        }
        customWidth="1200px"
      />
      <SubmitModal />
    </MixpanelProvider>
  );
};

export const HouseViewStaffingLevelModal = StaffingLevelModal;
