import { Timezone } from "@m7-health/shared-utils";
import { Dayjs } from "dayjs";
import { sortBy, uniqBy } from "lodash";

import { IShift } from "~/routes/api/types";

import { ISchedule, IShiftType, IStaffShift, StaffShift } from "@/api";
import { TimeString, dateString, seconds } from "@/common/types";
import { dateToTimeString, timeAdd, timeStringToDate, trimMs } from "@/common/utils/dates";

/**
 * Take an original shift, and new start/end times of the end result we want with different attributes
 *  such as shift type of status.
 *
 * Depending on how the new start/end times overlap with the original shift, it can result to 1, 2 or 3 shifts.
 * Examples:
 * - if from/to are the same, just update the shift
 * - if from is the same, and to is in the middle, create a new shift for the first half, and update
 *     existing shift with new start time
 */
export const splitOrEditShift = ({
  shiftToSplitAndFloat,
  originalShiftType,
  newShiftType,
  partialShiftAttributes,
  selectedSchedule,
  timezone,
  targetedShiftParams,
}: {
  shiftToSplitAndFloat: IStaffShift;
  originalShiftType: IShift;
  newShiftType: IShift;
  partialShiftAttributes: {
    customDuration: seconds | null;
    customStartTime: TimeString | null;
  };
  selectedSchedule: ISchedule;
  timezone: Timezone;
  targetedShiftParams?: Partial<IStaffShift>;
}): IStaffShift[] => {
  // Compute start end end time of new and desired new times
  const originalShiftStartTime =
    shiftToSplitAndFloat.customStartTime || originalShiftType.startTime;
  const originalShiftEndTime = timeAdd(
    originalShiftStartTime,
    shiftToSplitAndFloat.customDuration || originalShiftType.durationSeconds,
  );
  const newShiftStartTime = partialShiftAttributes.customStartTime || newShiftType.startTime;
  const newShiftEndTime = timeAdd(
    newShiftStartTime,
    partialShiftAttributes.customDuration || newShiftType.durationSeconds,
  );

  let originalShiftStart = timeStringToDate(originalShiftStartTime, timezone);
  let originalShiftEnd = timeStringToDate(originalShiftEndTime, timezone);
  const newShiftStart = timeStringToDate(newShiftStartTime, timezone);
  let newShiftEnd = timeStringToDate(newShiftEndTime, timezone);

  // When overlapping midnight, make sure that the end time is after the start time
  // Also add a day if start === end, it's a 24h shift
  if (originalShiftEnd < originalShiftStart || originalShiftStart.isSame(originalShiftEnd))
    originalShiftEnd = originalShiftEnd.addInTz(1, "day");
  if (newShiftEnd < newShiftStart || newShiftStart.isSame(newShiftEnd))
    newShiftEnd = newShiftEnd.addInTz(1, "day");
  if (originalShiftStart.isSame(originalShiftEnd))
    originalShiftEnd = originalShiftEnd.addInTz(1, "day");
  if (newShiftStart.isSame(newShiftEnd)) newShiftEnd = newShiftEnd.addInTz(1, "day");

  /** Strategy to know how to organize results is as following:
   * - Generate an array of time (datetime)
   * - Make them unique
   * - Sort them
   * - Depending on the length of the array, we can know how many shifts we need to create
   *   ex: [08:00, 10:00, 12:00] => 2 shifts
   *       [08:00, 10:00, 12:00, 14:00] => 3 shifts (could be 2 if they don't overlap, aka gab between 10 and 12)
   *       [08:00, 10:00] => 1 shifts
   */

  // When the shifts overlap, but new start is before or old new,
  //  or new end is after old end, extend the existing shift times
  //  to the "new" one so the uniq/sort strategy can work
  if (newShiftStart < originalShiftEnd && newShiftEnd > originalShiftStart) {
    if (newShiftStart < originalShiftStart) originalShiftStart = newShiftStart;
    if (newShiftEnd > originalShiftEnd) originalShiftEnd = newShiftEnd;
  }

  const dateUniqIdFn = (date: Dayjs) => date.toISOString();
  const times = sortBy(
    uniqBy([originalShiftStart, newShiftStart, originalShiftEnd, newShiftEnd], dateUniqIdFn),
    dateUniqIdFn,
  );

  const now = new Date().toISOString() as dateString;

  const shiftFactory = () => ({
    id: window.crypto.randomUUID(),
    staffId: shiftToSplitAndFloat.staffId,
    date: shiftToSplitAndFloat.date,
    attributes: [],
    isWorkingAway: false,
    status: null,
    scheduleType: StaffShift.EScheduleType.draft,
    scheduleId: selectedSchedule.id,
    ...getTimestamps(now),
  });

  switch (times.length) {
    // corner case: 24hour shift, no need to split
    case 1:
      return [
        {
          ...shiftToSplitAndFloat,
          scheduleId: selectedSchedule.id,
          shiftTypeKey: newShiftType.key as IShiftType["key"],
          customStartTime: null,
          customDuration: null,
          ...(targetedShiftParams || {}),
        },
      ];
    // Only one shift, return original shift with new start and end
    case 2:
      const duration = newShiftEnd.diff(newShiftStart, "seconds") as seconds;
      const shiftStartTime = dateToTimeString(newShiftStart, timezone);
      const custom =
        trimMs(shiftStartTime) !== trimMs(newShiftType.startTime) ||
        duration !== newShiftType.durationSeconds;
      return [
        {
          ...shiftToSplitAndFloat,
          scheduleId: selectedSchedule.id,
          shiftTypeKey: newShiftType.key as IShiftType["key"],
          customStartTime: custom ? shiftStartTime : null,
          customDuration: custom ? duration : null,
          ...(targetedShiftParams || {}),
        },
      ];

    // Original shift is split into two shifts.
    // Identity if the new shift is the first or the second
    case 3:
      const newShiftIsFirst = newShiftStart <= originalShiftStart;
      if (newShiftIsFirst) {
        return [
          {
            ...shiftFactory(),
            shiftTypeKey: newShiftType.key as IShiftType["key"],
            customStartTime: dateToTimeString(newShiftStart, timezone),
            customDuration: newShiftEnd.diff(newShiftStart, "seconds") as seconds,
            ...targetedShiftParams,
          },
          {
            ...shiftToSplitAndFloat,
            customStartTime: dateToTimeString(newShiftEnd, timezone),
            customDuration: originalShiftEnd.diff(newShiftEnd, "seconds") as seconds,
          },
        ];
      } else {
        return [
          {
            ...shiftToSplitAndFloat,
            customStartTime: dateToTimeString(originalShiftStart, timezone),
            customDuration: newShiftStart.diff(originalShiftStart, "seconds") as seconds,
          },
          {
            ...shiftFactory(),
            shiftTypeKey: newShiftType.key as IShiftType["key"],
            customStartTime: dateToTimeString(newShiftStart, timezone),
            customDuration: newShiftEnd.diff(newShiftStart, "seconds") as seconds,
            ...targetedShiftParams,
          },
        ];
      }

    // OR:
    // - there is overlap - and original shift is split into three shifts
    //   (and Original shift is kept first)
    // - there is NO overlap - original shift is unchanged, and new shift is floated
    case 4:
      if (newShiftEnd <= originalShiftStart || newShiftStart >= originalShiftEnd) {
        return [
          ...(newShiftEnd <= originalShiftStart ? [] : [shiftToSplitAndFloat]),
          {
            ...shiftFactory(),
            shiftTypeKey: newShiftType.key as IShiftType["key"],
            customStartTime: dateToTimeString(newShiftStart, timezone),
            customDuration: newShiftEnd.diff(newShiftStart, "seconds") as seconds,
            ...targetedShiftParams,
          },
          ...(newShiftStart >= originalShiftEnd ? [] : [shiftToSplitAndFloat]),
        ];
      } else {
        return [
          {
            ...shiftToSplitAndFloat,
            customStartTime: dateToTimeString(originalShiftStart, timezone),
            customDuration: newShiftStart.diff(originalShiftStart, "seconds") as seconds,
          },
          {
            ...shiftFactory(),
            shiftTypeKey: newShiftType.key as IShiftType["key"],
            customStartTime: dateToTimeString(newShiftStart, timezone),
            customDuration: newShiftEnd.diff(newShiftStart, "seconds") as seconds,
            ...targetedShiftParams,
          },
          {
            ...shiftToSplitAndFloat,
            // Keep original shift, but override id and timestamps
            id: window.crypto.randomUUID(),
            ...getTimestamps(now),
            // and adjust start time and duration
            customStartTime: dateToTimeString(newShiftEnd, timezone),
            customDuration: originalShiftEnd.diff(newShiftEnd, "seconds") as seconds,
          },
        ];
      }

    default:
      return [];
  }
};

const getTimestamps = (now: dateString) => ({
  createdAt: now,
  updatedAt: now,
  deletedAt: null,
});
