import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { values } from "lodash";

import { BookmarkAdd, Groups3 } from "@mui/icons-material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import GridOnIcon from "@mui/icons-material/GridOn";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import RadarIcon from "@mui/icons-material/Radar";
import SettingsIcon from "@mui/icons-material/Settings";

import { StaffDetails } from "~/api/staffDetails/types";
import CatchAllPage from "~/common/pages/CatchAllPage";
import ErrorPage from "~/common/pages/ErrorPage";
import {
  ERosterPageTabs,
  ESchedulerRosterPages,
  ESchedulerRosterTabs,
} from "~/features/Roster/types";
import { ERoles } from "~/features/User/types";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import ProtectedRoute from "~/routes/components/ProtectedRoute";
import RouterRoot from "~/routes/components/RouterRoot";

import { ESchedulePageTabs } from "#/features/SchedulerGrid/types";

import {
  ADMIN_PERMISSIONS,
  KIOSK_PERMISSIONS,
  PERMISSION_ALLOW_ALL,
  STAFF_PERMISSIONS,
  TSingularPermission,
} from "./components/ProtectedRoute/types";

const FairnessPage = lazy(() => import("../features/Fairness"));
const LoginPage = lazy(() => import("../features/User/LoginPage"));
const ProfilePage = lazy(() => import("../features/User/ProfilePage"));
const RosterPage = lazy(() => import("../features/Roster"));
const SchedulerRosterPage = lazy(() => import("../features/Roster/SchedulerRoster"));
const StaffRoster = lazy(() => import("../features/Roster/StaffRoster"));
const StatisticsPage = lazy(() => import("../features/Statistics"));
const SupportPage = lazy(() => import("../features/Support"));
const OpenShiftsPage = lazy(() => import("../features/OpenShifts"));
const DailyReportsPage = lazy(() => import("../features/DailyReports"));
const ShiftSwapRequestPage = lazy(() => import("../features/ShiftSwapRequest"));
const HouseViewPage = lazy(() => import("../features/HouseView"));
const SchedulerGridPage = lazy(() => import("#/features/SchedulerGrid"));
const AdminPanelPage = lazy(() => import("#/features/AdminPanel"));
const CxDashboardPage = lazy(() => import("#/features/CxDashboard"));
const CalendarPage = lazy(() => import("#/features/CalendarV2"));
const HolidaySchedulePage = lazy(() => import("#/features/HolidaySchedule"));

export const FALLBACK_ROUTE = "/_default";

export interface IBasicRoute {
  id: string;
  path: string;
  name: string;
  icon?: JSX.Element;
  children?: IBasicRoute[];
  roles?: ERoles[];
  legacyPaths?: string[];
}

export interface IMainRoute extends IBasicRoute {
  component: JSX.Element;
  roles: ERoles[];
  allowedEmploymentType?: StaffDetails.EEmploymentType[];
  permissions: (EUnitPermissionAreas | TSingularPermission)[];
}

export const routes = {
  account: {
    id: "1",
    path: "/",
    name: "Login",
    component: <LoginPage />,
    roles: [],
    permissions: [PERMISSION_ALLOW_ALL],
  },
  calendar: {
    id: "9",
    path: "/calendar",
    name: "Calendar",
    component: <CalendarPage />,
    roles: [ERoles.Staff],
    permissions: [STAFF_PERMISSIONS],
  },
  fairness: {
    id: "3",
    path: "/fairness",
    name: "Fairness",
    component: <FairnessPage />,
    roles: [ERoles.Admin, ERoles.Scheduler, ERoles.Staff],
    permissions: [EUnitPermissionAreas.fairness],
  },
  profile: {
    id: "4",
    path: "/profile",
    name: "Profile",
    component: <ProfilePage />,
    roles: [ERoles.Staff],
    permissions: [STAFF_PERMISSIONS],
  },
  roster: {
    id: "5",
    path: "/roster",
    name: "Roster",
    component: <RosterPage />,
    roles: [ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.staffRoster],
  },
  rosterUnit: {
    id: "6",
    path: "/roster/:unitId",
    name: "Roster",
    component: <RosterPage />,
    roles: [ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.staffRoster],
    children: [
      {
        id: "6.1",
        path: `/roster/:unitId/${ERosterPageTabs.homeUnitUsers}`,
        name: "Home Unit Users",
        legacyPaths: ["/roster/:unitId/current_users"],
      },
      {
        id: "6.2",
        path: `/roster/:unitId/${ERosterPageTabs.suspendedUsers}`,
        name: "Suspended Users",
      },
      {
        id: "6.3",
        path: `/roster/:unitId/${ERosterPageTabs.otherUnitUsers}`,
        name: "Floats from Other Units",
      },
    ],
  },
  staffRosterUnit: {
    id: "16",
    path: "/roster/staff",
    name: "Staff",
    component: <StaffRoster />,
    roles: [ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.staffRoster],
    children: [
      {
        id: "16.1",
        path: `/roster/staff/:unitId/${ERosterPageTabs.homeUnitUsers}`,
        name: "Home Unit Users",
        legacyPaths: ["/roster/:unitId/current_users"],
      },
      {
        id: "16.2",
        path: `/roster/staff/:unitId/${ERosterPageTabs.suspendedUsers}`,
        name: "Suspended Users",
      },
      {
        id: "16.3",
        path: `/roster/staff/:unitId/${ERosterPageTabs.otherUnitUsers}`,
        name: "Floats from Other Units",
      },
    ],
  },
  schedulerRoster: {
    id: "17",
    path: "/roster/scheduler",
    name: "Scheduler",
    component: <SchedulerRosterPage />,
    roles: [ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.schedulerRoster],
    children: [
      {
        id: "17.1",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerDetails}`,
        name: "Add scheduler details",
        children: [
          {
            id: "17.1.1",
            path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerDetails}/:schedulerId`,
            name: "Edit scheduler details",
          },
        ],
      },
      {
        id: "17.2",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerPermissions}`,
        name: "Add scheduler permissions",
        children: [
          {
            id: "17.2.1",
            path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerPermissions}/:schedulerId`,
            name: "Edit scheduler permissions",
          },
        ],
      },
      {
        id: "17.3",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterTabs.activeSchedulers}`,
        name: "Active schedulers",
      },
      {
        id: "17.4",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterTabs.suspendedSchedulers}`,
        name: "Suspended schedulers",
      },
    ],
  },
  schedule: {
    id: "7",
    path: "/schedule",
    name: "Schedule",
    component: <SchedulerGridPage />,
    roles: [ERoles.Admin, ERoles.Scheduler, ERoles.Kiosk],
    permissions: [EUnitPermissionAreas.scheduleGrid, KIOSK_PERMISSIONS],
    children: [
      {
        id: "7.1",
        path: `/schedule/${ESchedulePageTabs.past}`,
        name: "Past schedules",
        children: [
          {
            id: "7.1.1",
            path: `/schedule/${ESchedulePageTabs.past}/:scheduleId`,
            name: "Past schedule details",
          },
        ],
        roles: [ERoles.Admin, ERoles.Scheduler],
      },
      {
        id: "7.2",
        path: `/schedule/${ESchedulePageTabs.current}`,
        name: "Current schedule",
        roles: [ERoles.Admin, ERoles.Scheduler],
      },
      {
        id: "7.3",
        path: `/schedule/${ESchedulePageTabs.planning}`,
        name: "Planned schedules",
        children: [
          {
            id: "7.3.1",
            path: `/schedule/${ESchedulePageTabs.planning}/:scheduleId`,
            name: "Planned schedule details",
          },
        ],
        roles: [ERoles.Admin, ERoles.Scheduler, ERoles.Kiosk],
      },
    ],
  },
  holidaySchedule: {
    id: "8",
    path: "/holiday-schedule",
    name: "Holiday Schedule",
    component: <HolidaySchedulePage />,
    roles: [ERoles.Admin, ERoles.Scheduler, ERoles.Kiosk],
    permissions: [EUnitPermissionAreas.scheduleGrid, KIOSK_PERMISSIONS],
  },

  statistics: {
    id: "9",
    path: "/statistics",
    name: "Statistics",
    component: <StatisticsPage />,
    roles: [ERoles.Staff],
    allowedEmploymentType: [
      StaffDetails.EEmploymentType.fullTime,
      StaffDetails.EEmploymentType.partTime,
      StaffDetails.EEmploymentType.travelStaff,
      StaffDetails.EEmploymentType.inHouseContract,
      StaffDetails.EEmploymentType.flex,
    ],
    permissions: [STAFF_PERMISSIONS],
  },
  support: {
    id: "10",
    path: "/support",
    name: "Support",
    component: <SupportPage />,
    roles: [ERoles.Staff],
    permissions: [STAFF_PERMISSIONS],
  },
  openShifts: {
    id: "11",
    path: "/open-shifts",
    name: "Open Shifts",
    component: <OpenShiftsPage />,
    roles: [ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.openShifts],
  },
  dailyReports: {
    id: "12",
    path: "/daily-reports",
    name: "Daily Reports",
    component: <DailyReportsPage />,
    roles: [ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.dailyReport],
  },
  shiftSwapRequest: {
    id: "13",
    path: "/shift-swap-request",
    name: "Shift Swap Request",
    component: <ShiftSwapRequestPage />,
    roles: [ERoles.Staff, ERoles.Admin, ERoles.Scheduler],
    permissions: [EUnitPermissionAreas.approvals, STAFF_PERMISSIONS],
  },
  houseView: {
    id: "14",
    path: "/house-view",
    name: "House View",
    component: <HouseViewPage />,
    roles: [ERoles.Admin, ERoles.Scheduler, ERoles.Kiosk],
    permissions: [EUnitPermissionAreas.houseView, KIOSK_PERMISSIONS],
  },
  adminPanelCxDashboard: {
    id: "18",
    path: "/admin/cx-dashboard",
    name: "CX Dashboard",
    roles: [ERoles.Admin],
    component: <CxDashboardPage />,
    permissions: [ADMIN_PERMISSIONS],
  },
  adminPanelUnit: {
    id: "15",
    path: "/admin/unit",
    name: "Unit Panel",
    roles: [ERoles.Admin],
    component: <AdminPanelPage />,
    permissions: [ADMIN_PERMISSIONS],
    children: [
      {
        id: "15.1",
        path: "/admin/unit/actions",
        name: "Actions",
        icon: <MultipleStopIcon />,
      },
      {
        id: "15.2",
        path: "/admin/unit/questionnaires",
        name: "Questionnaires",
        icon: <MonitorHeartIcon />,
      },
      {
        id: "15.3",
        path: "/admin/unit/shift-types",
        name: "Shift Types",
        icon: <BookmarkAdd />,
      },
      {
        id: "15.4",
        path: "/admin/unit/attributes",
        name: "Positions",
        icon: <RadarIcon />,
      },
      {
        id: "15.5",
        path: "/admin/unit/target-levels",
        name: "Unit Target Levels",
        icon: <CrisisAlertIcon />,
      },
      {
        id: "15.6",
        path: "/admin/unit/configuration",
        name: "Unit:Config",
        icon: <SettingsIcon />,
      },
      {
        id: "15.7",
        path: "/admin/unit/staffing-matrix",
        name: "Staffing:Matrix",
        icon: <GridOnIcon />,
      },
      {
        id: "15.8",
        path: "/admin/unit/staff-types",
        name: "Staff Types",
        icon: <Groups3 />,
      },
    ],
  },
  // defaultRoleRoute has a fallthrough route for each role
  // below is last resort route for all roles
  fallthrough: {
    id: "99",
    path: "*",
    name: "Fallthrough",
    component: <CatchAllPage />,
    roles: values(ERoles),
    permissions: [PERMISSION_ALLOW_ALL],
  },
} satisfies { [routeKey: string]: IMainRoute };

const router = createBrowserRouter([
  {
    path: "",
    element: <RouterRoot />,
    errorElement: <ErrorPage />,
    children: Object.values(routes).map(
      ({
        component,
        path,
        roles,
        name,
        children,
        permissions,
        allowedEmploymentType,
      }: IMainRoute) => ({
        path,
        element: (
          <MixpanelProvider properties={{ [Mxp.Property.layout.page]: name }}>
            <ProtectedRoute
              roles={roles}
              permissions={permissions}
              allowedEmploymentType={allowedEmploymentType}
            >
              {component}
            </ProtectedRoute>
          </MixpanelProvider>
        ),
        children: children,
      }),
    ),
  },
]);

export default router;
