import { PayloadAction } from "@reduxjs/toolkit";

import {
  RealTimeStaffingTarget,
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToDelete,
  TRealTimeStaffingTargetToUpdate,
} from "~/api/realTimeStaffingTargets";

import { StaffCategory, Unit } from "@/api";

import { TUpdatedStaffingLevel } from "../components/modals/StaffingLevelModal/helpers";

import { THouseViewState } from ".";

export type StaffingLevels = {
  modalIsOpen?: boolean;
  // contains all staffing targets for all units
  // every unit can have a MAXIMUM OF ONE target being created at a time (so one target of type TRealTimeStaffingTargetToCreate)
  // and multiple targets being updated or deleted at a time
  // see updateStaffingLevel action below for more details on how this works
  allStaffingTargets: {
    [unitId: string]: (
      | TRealTimeStaffingTargetToCreate
      | TRealTimeStaffingTargetToUpdate
      | TRealTimeStaffingTargetToDelete
    )[];
  };
  selectedUnitCategories?: StaffCategory.DTO[];

  /** @deprecated use selectedUnitId from pageFilters,
   * staffingTargetsUnit selected will be same as that of page */
  selectedUnitId?: Unit.DTO["id"];
  /** @deprecated use allStaffingTargets */
  editedStaffingTargets: {
    [unitId: string]: Partial<RealTimeStaffingTarget.DTO> | undefined;
  };
};

export const StaffingLevelActions = {
  setSelectedUnitId: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffingLevels["selectedUnitId"]>,
  ) => {
    staffingLevels.selectedUnitId = action.payload;
  },
  setStaffingLevelModalIsOpen: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<boolean>,
  ) => {
    staffingLevels.modalIsOpen = action.payload;
  },
  setAllStaffingTargets: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffingLevels["allStaffingTargets"]>,
  ) => {
    staffingLevels.allStaffingTargets = action.payload;
  },
  /**
   * Updates the staffing level for a specific unit in the HouseView state.
   *
   * @param {Object} param0 - The current state object containing staffingLevels.
   * @param {Object} param0.staffingLevels - The staffing levels state object.
   * @param {Object} action - The Redux action object.
   * @param {TRealTimeStaffingTargetToCreate | TRealTimeStaffingTargetToUpdate | TRealTimeStaffingTargetToDelete} action.payload - The payload containing the staffing target data.
   *
   * @description
   * This function handles three types of staffing level updates:
   * 1. Creating a new staffing target (every unit can have one new staffing target being created at a time, so it either already is in the state or not)
   * 2. Updating an existing staffing target
   * 3. Deleting an existing staffing target
   *
   * The function first extracts the unitId from the payload and retrieves the current targets for that unit.
   * It then determines whether to update an existing target or create a new one based on the presence of an 'id' in the payload.
   *
   * For existing targets (with 'id'):
   * - It maps through the current targets and updates the matching target.
   * - This handles both updates and deletions (deletion payload should have a special flag or empty data).
   *
   * For new targets (without 'id'):
   * - It checks if there's an existing target being created (without an 'id') (since every unit only has ONE target being created).
   * - If found, it updates this target; otherwise, it adds a new target to the list.
   *
   * Finally, it updates the allStaffingTargets in the state with the new list of targets for the specific unit.
   *
   * @example
   * // Updating an existing target
   * updateStaffingLevel(state, {
   *   payload: { id: '123', unitId: 'unit1', staffCount: 5 }
   * });
   *
   * // Creating a new target
   * updateStaffingLevel(state, {
   *   payload: { unitId: 'unit1', staffCount: 3 }
   * });
   *
   * // Deleting a target (assuming deletion is handled by sending an empty object with id)
   * updateStaffingLevel(state, {
   *   payload: { id: '123', unitId: 'unit1' }
   * });
   */
  updateStaffingLevel: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<
      | TRealTimeStaffingTargetToCreate
      | TRealTimeStaffingTargetToUpdate
      | TRealTimeStaffingTargetToDelete
    >,
  ) => {
    const { unitId, ...rest } = action.payload;
    const currentUnitTargets = staffingLevels.allStaffingTargets[unitId] || [];

    let updatedUnitTargets;
    // IF DEALING WITH EXISTING TARGETS (ToUpdate or ToDelete)...
    // ELSE IF DEALING WITH NEW TARGETS (ToCreate)...
    if ("id" in action.payload) {
      // Updating or deleting existing target (if being deleted, the payload)
      updatedUnitTargets = currentUnitTargets.map((target) =>
        "id" in target && "id" in action.payload && target.id === action.payload.id
          ? { ...target, ...rest }
          : target,
      );
    } else {
      // Updating the target that is being created if it exists (the one without an id in it), else creating it
      const existingCreatedTarget = currentUnitTargets.find((target) => !("id" in target));
      if (existingCreatedTarget) {
        updatedUnitTargets = currentUnitTargets.map((target) =>
          target === existingCreatedTarget ? { ...target, ...rest } : target,
        );
      } else {
        updatedUnitTargets = [...currentUnitTargets, action.payload];
      }
    }

    staffingLevels.allStaffingTargets = {
      ...staffingLevels.allStaffingTargets,
      [unitId]: updatedUnitTargets,
    };
  },
  setSelectedUnitCategories: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffCategory.DTO[]>,
  ) => {
    staffingLevels.selectedUnitCategories = action.payload;
  },
  /** @deprecated, uses editedStaffingTargets */
  setEditedStaffingTargets: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffingLevels["editedStaffingTargets"]>,
  ) => {
    staffingLevels.editedStaffingTargets = action.payload;
  },
  /** @deprecated, uses editedStaffingTargets */
  updateStaffingLevelDeprecated: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<TUpdatedStaffingLevel>,
  ) => {
    const { unitId, ...rest } = action.payload;
    staffingLevels.editedStaffingTargets = {
      ...staffingLevels.editedStaffingTargets,
      [unitId || ""]: {
        ...staffingLevels.editedStaffingTargets[unitId || ""],
        ...rest,
        unitId: unitId || "",
      },
    };
  },
};
