import { useEffect, useMemo } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";

import { useIsStaffApp } from "~/common/hooks/useIsStaffApp";
import { useAppSelector } from "~/common/hooks/useRedux";
import { useStaffDetailsQuery } from "~/features/User/queries";
import { ERoles } from "~/features/User/types";

import { useCheckUserPermissions, useCurrentUnitId } from "@/common/hooks";

import { defaultRoleRoute } from "./constants";
import { IProtectedRoute } from "./types";

const getDefaultRouteForRole = (currentRole: ERoles | undefined): string => {
  if (currentRole) return defaultRoleRoute[currentRole];
  return "/";
};

export const ProtectedRoute = ({
  children,
  roles,
  allowedEmploymentType,
  permissions,
}: IProtectedRoute) => {
  const isStaff = useIsStaffApp();
  const currentUnitId = useCurrentUnitId();
  const queryClient = useQueryClient();
  const { data: staffData, isLoading: isLoadingStaffData } = useStaffDetailsQuery(isStaff);

  const userCan = useCheckUserPermissions();

  const { currentRole } = useAppSelector((state) => ({ currentRole: state.user.currentRole }));

  const { isAuthenticated } = useAuth0();
  const isAllowed = useMemo(() => {
    if (isStaff && isLoadingStaffData) return true;

    const allowedByEmploymentType =
      isStaff && allowedEmploymentType && allowedEmploymentType.length > 0
        ? staffData?.employmentType && allowedEmploymentType.includes(staffData.employmentType)
        : true;

    const allowedPermission = permissions.some((permission) => userCan("read", permission));
    const allowedByRole = roles.includes(currentRole as ERoles);

    return allowedByRole && allowedPermission && allowedByEmploymentType;
  }, [
    isStaff,
    isLoadingStaffData,
    allowedEmploymentType,
    staffData?.employmentType,
    permissions,
    roles,
    currentRole,
    userCan,
  ]);
  const { pathname } = useLocation();

  // reload window if searchParams has reload_window
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("reload_window")) {
      setSearchParams((current) => {
        current.delete("reload_window");
        return current;
      });

      void Promise.all([
        queryClient.invalidateQueries(),
        queryClient.resetQueries(),
        queryClient.clear(),
      ]).then(() => {
        window.location.reload();
      });
    }
  }, [searchParams, setSearchParams, queryClient]);

  // Make sure reload is not overwritten by a navigate or a redirect
  if (searchParams.get("reload_window")) {
    return <></>;
  }

  if (!isAuthenticated && pathname !== "/") {
    return <Navigate to="/" />;
  }

  // Identify an non existent or non allowed route and redirect if so.
  // But make sure we are ready before checking
  if (isAuthenticated) {
    const readyToCheck = currentRole && currentUnitId;
    if (!readyToCheck && isAuthenticated) return null;

    if (!isAllowed && pathname !== getDefaultRouteForRole(currentRole)) {
      return <Navigate to={getDefaultRouteForRole(currentRole)} />;
    }
  }

  return children;
};
