import { ISODateString, YyyyMmDd } from "@m7-health/shared-utils";

import { APIListResponse, SDTO } from "~/api/_shared/types";
import { dateString } from "~/common/types";

import { IFacility } from "../facility";
import { IScheduleInternalDetails } from "../scheduleInternalDetails/types";
import { StaffDetails } from "../staffDetails";
import { IUnit } from "../unit";

export type ISchedule = Schedule.DTO;

export namespace Schedule {
  export enum EStatus {
    collecting = "sent_out",
    balancing = "in_review",
    published = "published",
    completed = "completed",
    current = "current",
    // only used for Staff App to get ALL future schedules
    future = "future",
  }

  // incomplete
  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    status: EStatus;
    unitId: IUnit["id"];
    scheduleInternalDetails?: IScheduleInternalDetails;
    startDay: YyyyMmDd;
    startTime: dateString;
    endDay: YyyyMmDd;
    endTime: dateString;
    employmentTypesOpenTo: StaffDetails.EEmploymentType[];
    // YYYY-MM-DD
    staffsInputDeadline: YyyyMmDd;
    targetPublishDeadline: YyyyMmDd;
    // Iso
    staffsInputDeadlineTime: ISODateString;
    targetPublishDeadlineTime: ISODateString;
  }

  export namespace API {
    export const ROOT_PATH = "/schedule";
    export namespace List {
      export const PATH = ROOT_PATH;
      export type QueryParams =
        | {
            ids?: ISchedule["id"][];
            unitIds?: IUnit["id"][];
            facilityIds?: IFacility["id"][];
            statuses?: EStatus[];
            skip?: number;
            take?: number;
          }
        | undefined;
      export type Response = APIListResponse<DTO>;
    }

    export namespace Update {
      export const PATH = ({ id }: QueryParams) => `${ROOT_PATH}/${id}`;

      export type QueryParams = {
        id: ISchedule["id"];
        status: EStatus;
        employmentTypesOpenTo?: StaffDetails.EEmploymentType[];
      };

      export type Response = DTO;
    }
  }
}
