const SECTIONS = {
  "/admin/unit/actions": "actions",
  "/admin/unit/staffing-matrix": "staffing-matrix",
  "/admin/unit/configuration": "unit-configuration",
  "/admin/unit/questionnaires": "questionnaires",
  "/admin/unit/shift-types": "shiftTypes",
  "/admin/unit/attributes": "attributes",
  "/admin/unit/target-levels": "unitTargetLevels",
  "/admin/unit/staff-types": "staffTypes",
  "/admin/unit": "home",
  default: "home",
} as const;

const T_24_HOURS_IN_SECONDS = 24 * 60 * 60;

export { SECTIONS, T_24_HOURS_IN_SECONDS };
