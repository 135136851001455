import { toast } from "react-toastify";

import Alert, { AlertColor } from "@mui/material/Alert";

import CustomButton from "../components/TrackedComponents/Button";

export const useToast = () => {
  const options = {
    position: toast.POSITION.BOTTOM_CENTER,
    hideProgressBar: false,
    closeButton: false,
    draggable: false,
  };

  const toastedAlert = (severity: AlertColor) => {
    return (message: string, action?: () => void, actionLabel = "View") => {
      const actionButton = action ? (
        <CustomButton
          color="inherit"
          size="small"
          onClick={action}
          trackingLabel={actionLabel}
          variant="text"
        >
          {actionLabel}
        </CustomButton>
      ) : null;
      const currentToast = toast(
        <div>
          <Alert severity={severity} action={actionButton} onClose={() => null}>
            {message}
          </Alert>
        </div>,
        options,
      );

      return {
        id: currentToast,
        update: (newMessage: string) => {
          toast.update(currentToast, {
            render: (
              <div>
                <Alert severity={severity} action={actionButton} onClose={() => null}>
                  {newMessage}
                </Alert>
              </div>
            ),
            ...options,
          });
        },
      };
    };
  };

  return {
    showSuccess: toastedAlert("success"),
    showInfo: toastedAlert("info"),
    showWarning: toastedAlert("info"),
    showError: toastedAlert("error"),
  };
};
