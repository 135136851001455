import { APIListResponse, SDTO } from "~/api/_shared/types";

import { ISchedule } from "../schedule/types";

export type IScheduleComposition = ScheduleComposition.DTO;

export namespace ScheduleComposition {
  export enum EType {
    draft = "draft",
    m7 = "m7",
  }

  // incomplete
  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    scheduleId: ISchedule["id"];
    type: EType;
  }

  export namespace API {
    const PATH_ROOT = "/schedule-composition";

    export namespace List {
      export const PATH = PATH_ROOT;
      export interface QueryParams {
        scheduleIds?: ISchedule["id"][];
        scheduleTypes?: EType[];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
