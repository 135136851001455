import { ISODateString } from "@m7-health/shared-utils";

// eslint-disable-next-line deprecate/import
import { TDateFilter, TimeString, Uuid, YyyyMmDd, dateString, seconds } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { EShiftStatus, EShiftStatusOrNull } from "#/features/DailyReports/types";

import { IStaff } from "../../features/Roster/types";
import { APIListResponse } from "../_shared/types";
import { IAttribute } from "../attribute";
import { ISchedule } from "../schedule";
import { IShiftType } from "../shiftType";
import { Unit } from "../unit";
import { IUser } from "../user";

export type IStaffShift = StaffShift.DTO;
export type IWorkingHours = StaffShift.API.WorkingHours.DTO;

export namespace StaffShift {
  export enum EScheduleType {
    m7 = "m7",
    draft = "draft",
  }

  export enum EStatus {
    calledIn = "Called In",
    onCall = "On Call",
    cancelled = "Cancelled",
    floated = "Floated",
    flexed = "Flexed",
    requestOnCall = "On Call (ROC)",
    onCall4h = "On Call (< 4H)",
  }

  export interface DTO {
    id: Uuid;
    createdAt: dateString;
    updatedAt: dateString;
    deletedAt: dateString | null;
    shiftTypeKey: IShiftType["key"];
    staffId: IUser["id"];
    scheduleId: ISchedule["id"];
    date: dateString;
    attributes: IAttribute["key"][];
    customStartTime: TimeString | null;
    customDuration: seconds | null;
    isWorkingAway: boolean;
    scheduleType: EScheduleType;
    status: StaffShift.EStatus | null;
  }

  export namespace API {
    export const ROOT_PATH = "/staff-shift";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        staffIds?: IStaff["id"][];
        scheduleIds?: ISchedule["id"][];
        shiftTypeKeys?: IShiftType["key"][];
        isWorkingAway?: boolean[];
        date?: TDateFilter[];
        excludeNoteUpdates?: EShiftStatus[];
        scheduleTypes?: EScheduleType[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace WorkingHours {
      export const PATH = ROOT_PATH + "/working-hours";

      export interface DTO {
        staffId: IUser["id"];
        from: YyyyMmDd;
        to: YyyyMmDd;
        cumulatedSeconds: seconds;
      }

      export interface QueryParams {
        staffIds?: IUser["id"][];
        dateRanges: {
          from: YyyyMmDd;
          to: YyyyMmDd;
        }[];
        shiftType?: {
          isWorkingShift?: boolean;
          isPaidShift?: boolean;
        };
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace DownloadUpdateReport {
      export const PATH = ROOT_PATH + "/download-update-report";
      export interface QueryParams {
        date?: TDateFilter[];
        unitIds?: Unit.DTO["id"][];
        updateTypes?: EShiftStatusOrNull[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace BulkSave {
      export const PATH = ROOT_PATH + "/bulk-save";

      export interface QueryParams {
        staffShifts: TBulkSaveStaffShifts;
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}

type TStaffShiftToDelete = {
  id: IStaffShift["id"];
  updatedAt: ISODateString | dateString;
  shiftTypeKey: null;
};
type TStaffShiftToUpdate = {
  id: IStaffShift["id"];
  updatedAt: ISODateString | dateString;
} & Partial<
  Pick<IStaffShift, "attributes" | "shiftTypeKey" | "customStartTime" | "customDuration"> & {
    date: ISODateString | dateString;
  }
>;

type TStaffShiftToCreate = Pick<
  IStaffShift,
  "shiftTypeKey" | "scheduleId" | "staffId" | "scheduleType"
> & {
  date: ISODateString | dateString;
} & Partial<Pick<IStaffShift, "customStartTime" | "customDuration">>;

type TBulkSaveStaffShifts = (TStaffShiftToCreate | TStaffShiftToUpdate | TStaffShiftToDelete)[];
