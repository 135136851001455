import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { tzAddPlugin } from "./addInTz";

dayjs.extend(calendar);
dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(tzAddPlugin);
dayjs.extend(advancedFormat);

export const localDayJs = dayjs;

export const timezones = [
  { value: "America/Adak", label: "America/Adak" },
  { value: "America/Anchorage", label: "America/Anchorage" },
  { value: "America/Boise", label: "America/Boise" },
  { value: "America/Chicago", label: "America/Chicago" },
  { value: "America/Denver", label: "America/Denver" },
  { value: "America/Detroit", label: "America/Detroit" },
  { value: "America/Indiana/Indianapolis", label: "America/Indiana/Indianapolis" },
  { value: "America/Indiana/Knox", label: "America/Indiana/Knox" },
  { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell City" },
  { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay" },
  { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac" },
  { value: "America/Juneau", label: "America/Juneau" },
  { value: "America/Kentucky/Louisville", label: "America/Kentucky/Louisville" },
  { value: "America/Kentucky/Monticello", label: "America/Kentucky/Monticello" },
  { value: "America/Los_Angeles", label: "America/Los Angeles" },
  { value: "America/Menominee", label: "America/Menominee" },
  { value: "America/Metlakatla", label: "America/Metlakatla" },
  { value: "America/New_York", label: "America/New York" },
  { value: "America/Nome", label: "America/Nome" },
  { value: "America/North_Dakota/Beulah", label: "America/North Dakota/Beulah" },
  { value: "America/North_Dakota/Center", label: "America/North Dakota/Center" },
  { value: "America/North_Dakota/New_Salem", label: "America/North Dakota/New Salem" },
  { value: "America/Phoenix", label: "America/Phoenix" },
  { value: "America/Sitka", label: "America/Sitka" },
  { value: "America/Yakutat", label: "America/Yakutat" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
];
