import { useMemo } from "react";

import { List, filter } from "lodash";

/** This hook is a memoized wrapper around lodash's filter function that allows you to key an array of objects by a key.
 *
 * @example results = useFilterBy(users, ({status}) => status === "active");
 */
export const useFilterBy = <T>(data: List<T> | null | undefined, filterFn: (item: T) => boolean) =>
  useMemo(() => filter(data || [], filterFn), [data, filterFn]);
