import { useMemo } from "react";

import { THouseViewTimeRange, TimeString, YyyyMmDd } from "@m7-health/shared-utils";
import { capitalize, entries, filter, keyBy, round, values } from "lodash";

import { darken } from "@mui/material";

import {
  IScheduleShiftType,
  IShiftType,
  IStaffShift,
  ShiftTypeHelpers,
  StaffCategory,
  StaffShift,
  useListScheduleShiftTypeQuery,
} from "~/api";
import { RealTimeStaffingTarget } from "~/api/realTimeStaffingTargets";
import { useAppSelector } from "~/common/hooks/useRedux";
import { localDayJs } from "~/common/packages/dayjs";
import { black, blockShift, green, lightBlue, lightGray } from "~/common/theming/colors";
import { TSx, Uuid } from "~/common/types";
import { timeOverlap } from "~/common/utils/dates";

import { useAppConfigQuery } from "#/features/User/queries";
import { useAppFlags } from "@/common/hooks";
import { isOnMobile } from "@/common/utils/isOnMobile";
import { voidingShiftStatus } from "@/common/utils/shifts";

import {
  getMatchingStaffingLevel,
  staffingLevelSelector,
} from "../components/modals/StaffingLevelModal/helpers";
import { DEFAULT_CUSTOM_TIME_RANGE, EHVTimeRange } from "../store/pageFiltersActions";

export const returnStaffedColorForTarget = (numerator: number, denominator: number) => {
  const upperRange = denominator + 0.5;
  const lowerRange = denominator - 0.5;

  if (numerator > upperRange) return lightBlue;
  if (numerator < lowerRange) return blockShift;
  return green;
};

export const returnStaffedColorForTargetDeprecated = (
  numerator: number,
  denominator: number,
  defaultColor: string,
) => {
  if (denominator === 0) return defaultColor;
  const upperRange = denominator + 0.5;
  const lowerRange = denominator - 0.5;

  if (numerator > upperRange) return lightBlue;
  if (numerator < lowerRange) return blockShift;
  return green;
};

// If we want specific attributes to have their own separate tab, we can add them here
export type TPositionAsTab = keyof typeof HVSpecificPositionsTabs;
export const HVSpecificPositionsTabs = {
  sitter: {
    countInTarget: false,
    separateTab: true,
  },
} satisfies Record<string, { countInTarget: boolean; separateTab: boolean }>;

const emptyShiftTypes: IScheduleShiftType[] = [];

const useStaffingTabs = (
  unitId: Uuid,
  categories: StaffCategory.DTO[],
  shiftByCategory: Record<StaffCategory.EKey, StaffShift.DTO[]> | undefined,
  selectedStaffCategory: StaffCategory.EKey | string | undefined,
  selectedDate: YyyyMmDd,
  latestStaffingLevel: Partial<RealTimeStaffingTarget.DTO> | undefined,
): (
  | {
      label: string | JSX.Element;
      value: StaffCategory.EKey;
      customPosition: false;
      shifts: StaffShift.DTO[];
      className?: string;
      sx?: TSx | undefined;
    }
  | {
      label: string | JSX.Element;
      value: string;
      customPosition: true;
      shifts: StaffShift.DTO[];
      className?: string;
      sx?: TSx | undefined;
    }
)[] => {
  const { newStaffingTargetsModal, hvPositionAsTab } = useAppFlags();

  const range = useAppSelector((state) => state.houseView.pageFilters.timeRange);
  const customRange =
    useAppSelector((state) => state.houseView.pageFilters.customTimeRange) ||
    DEFAULT_CUSTOM_TIME_RANGE;
  const isAllTimeRange = newStaffingTargetsModal
    ? customRange.customAbbreviation === "All"
    : range === EHVTimeRange.all;
  const unitStaffingLevel = useAppSelector(staffingLevelSelector(unitId));
  const selectedShowTargetLevels = useAppSelector(
    (state) => state.houseView.pageFilters.showTargetLevels,
  );
  const dateIsPast = localDayJs(selectedDate).isBefore(localDayJs(), "day");
  const { data: schedulesShiftTypes = emptyShiftTypes } = useListScheduleShiftTypeQuery({});
  const indexedShiftTypes = useMemo(
    () => ShiftTypeHelpers.byScheduleIdByShiftTypeKey(schedulesShiftTypes),
    [schedulesShiftTypes],
  );
  const unitAttributes = useAppConfigQuery().data?.units.find(
    (unit) => unit.id === unitId,
  )?.attributes;

  const { data: config } = useAppConfigQuery();
  const selectedUnitFromConfig = config?.units.find((unit) => unit.id === unitId);
  const staffingLevelMatrix = selectedUnitFromConfig?.staffingLevelMatrix;

  const tabs = useMemo(
    () => {
      const attributesByKey = keyBy(unitAttributes, "key");
      const positionTabs = {} as Record<string, { shifts: StaffShift.DTO[]; sum: number }>;

      const categoriesTabs = categories.map((category) => {
        const { key, name: label } = category;

        // get the target from the matrix if using the new staffing targets modal
        const matchingStaffingLevel = getMatchingStaffingLevel(
          category.key,
          latestStaffingLevel,
          staffingLevelMatrix,
        );
        const targetFromMatrix = matchingStaffingLevel?.staffingLevel;

        const targetDefaultColor =
          // On mobile, there is not such thing as selected tab, so we never want white
          !isOnMobile() && key === selectedStaffCategory ? "white" : darken(lightGray, 0.05);

        const currentCategoryShifts: IStaffShift[] = [];

        (shiftByCategory?.[key] || []).forEach((staffShift) => {
          const { attributes, status } = staffShift;
          if (voidingShiftStatus(status)) return;

          let positionDiscardShift = false;

          attributes.forEach((attribute) => {
            const attributeData = attributesByKey[attribute]?.name?.toLowerCase() as TPositionAsTab;
            if (!attributeData) return;

            const specificPosition = hvPositionAsTab
              ? HVSpecificPositionsTabs[attributeData]
              : undefined;
            if (specificPosition) {
              if (specificPosition.separateTab)
                (positionTabs[attributeData] ||= { shifts: [], sum: 0 }).shifts.push(staffShift);
              if (!specificPosition.countInTarget) positionDiscardShift = true;
            }
          });

          if (!positionDiscardShift) currentCategoryShifts.push(staffShift);
        });

        const currentCountOfShiftsInCategory = newStaffingTargetsModal
          ? currentCategoryShifts.reduce(
              (totalCount, staffShift) =>
                totalCount +
                calculatePortionCustomTimeRange(
                  staffShift,
                  indexedShiftTypes[staffShift.scheduleId]?.[staffShift.shiftTypeKey],
                  customRange,
                ),
              0,
            )
          : currentCategoryShifts.reduce(
              (totalCount, staffShift) =>
                totalCount +
                calculatePortionFixed7A7P(
                  staffShift,
                  indexedShiftTypes[staffShift.scheduleId]?.[staffShift.shiftTypeKey],
                  range,
                ),
              0,
            );

        const unitCategoryTargetDeprecated =
          key === StaffCategory.EKey.nurse || key === StaffCategory.EKey.tech
            ? unitStaffingLevel?.staffingTarget?.[key]
            : undefined;

        // If the target is not set OR we are on all time range, we want to show the default color
        const target = selectedShowTargetLevels
          ? newStaffingTargetsModal
            ? targetFromMatrix
            : unitCategoryTargetDeprecated
          : null;
        const targetString = target?.toString() ?? "";
        const targetRepresentation = targetString ? `/${targetString}` : "";
        const backgroundColor =
          !selectedShowTargetLevels || isAllTimeRange || !targetString
            ? targetDefaultColor
            : returnStaffedColorForTarget(
                currentCountOfShiftsInCategory,
                (newStaffingTargetsModal ? targetFromMatrix : unitCategoryTargetDeprecated) || 0,
              );

        return {
          value: key,
          customPosition: false as const,
          shifts: currentCategoryShifts,
          label: (
            <>
              {label}
              {!isAllTimeRange ? (
                <>
                  :{" "}
                  <span className={`count`}>
                    {" "}
                    {round(currentCountOfShiftsInCategory, 1)}
                    {targetRepresentation}
                  </span>
                </>
              ) : null}
            </>
          ),
          sx: {
            ".count": { backgroundColor },
            backgroundColor,
            color: black,
            border: `1px solid ${lightGray}`,
            borderBottom: "none",
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
            ml: 0.5,
            padding: 0,
          },
        };
      });

      values(positionTabs).forEach((positionTab) => {
        positionTab.sum = newStaffingTargetsModal
          ? positionTab.shifts.reduce(
              (totalCount, staffShift) =>
                totalCount +
                calculatePortionCustomTimeRange(
                  staffShift,
                  indexedShiftTypes[staffShift.scheduleId]?.[staffShift.shiftTypeKey],
                  customRange,
                ),
              0,
            )
          : positionTab.shifts.reduce(
              (totalCount, staffShift) =>
                totalCount +
                calculatePortionFixed7A7P(
                  staffShift,
                  indexedShiftTypes[staffShift.scheduleId]?.[staffShift.shiftTypeKey],
                  range,
                ),
              0,
            );
      });

      const computedPositionTabs = filter(
        entries(positionTabs).map(([key, value]) => {
          if (value.sum === 0) return null;

          const backgroundColor =
            !isOnMobile() && key === selectedStaffCategory ? "white" : darken(lightGray, 0.05);

          return {
            sx: {
              ".count": { backgroundColor },
              backgroundColor,
              color: black,
              border: `1px solid ${lightGray}`,
              borderBottom: "none",
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              ml: 0.5,
              padding: 0,
            },
            value: key,
            customPosition: true as const,
            shifts: value.shifts,
            className: "position-tab",
            label: (
              <>
                {capitalize(key)}
                {!isAllTimeRange ? (
                  <>
                    :{" "}
                    <span className={`count ${dateIsPast ? "past-date" : ""}`}>
                      {" "}
                      {round(value.sum, 1)}
                    </span>
                  </>
                ) : null}
              </>
            ),
          };
        }),
      );

      return [...categoriesTabs, ...computedPositionTabs];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      unitAttributes,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(categories),
      latestStaffingLevel,
      staffingLevelMatrix,
      selectedStaffCategory,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(shiftByCategory),
      newStaffingTargetsModal,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(unitStaffingLevel?.staffingTarget || {}),
      isAllTimeRange,
      selectedShowTargetLevels,
      dateIsPast,
      hvPositionAsTab,
      indexedShiftTypes,
      customRange,
      range,
    ],
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  return tabs;
};

export const calculatePortionCustomTimeRange = (
  staffShift: IStaffShift,
  shiftType: IShiftType | undefined,
  selectedRange: THouseViewTimeRange,
): number => {
  if (!shiftType?.isCountedForRealTimeStaffingTarget) return 0;

  if (!shiftType) return 0;

  if (voidingShiftStatus(staffShift.status)) return 0;

  // 1 - Retrieve and calculate start-end staff shift times
  const staffShiftStart = localDayJs(staffShift.customStartTime || shiftType.startTime, "HH:mm:ss");
  const staffShiftEnd = staffShiftStart.add(
    staffShift.customDuration || shiftType.durationSeconds,
    "seconds",
  );
  // 2 - Calculate the intersection between the staff shift and the selected range
  const overlapSeconds = timeOverlap(
    staffShiftStart.format("HH:mm:ss") as TimeString,
    staffShiftEnd.format("HH:mm:ss") as TimeString,
    selectedRange.startTime,
    selectedRange.endTime,
  );

  // 3 - Calculate the shift-duration vs range ratio
  const bottomRange = localDayJs(selectedRange.startTime, "HH:mm:ss");
  const topRange =
    selectedRange.endTime < selectedRange.startTime
      ? localDayJs(selectedRange.endTime, "HH:mm:ss").add(1, "day")
      : localDayJs(selectedRange.endTime, "HH:mm:ss");
  const rangeDuration = topRange.diff(bottomRange, "seconds");
  const ratio = overlapSeconds / rangeDuration;

  return ratio;
};

export const calculatePortionFixed7A7P = (
  staffShift: IStaffShift,
  shiftType: IShiftType | undefined,
  selectedRange: EHVTimeRange,
): number => {
  if (!shiftType?.isCountedForRealTimeStaffingTarget) return 0;

  if (!shiftType) return 0;

  if (voidingShiftStatus(staffShift.status)) return 0;

  let range;
  switch (selectedRange) {
    case EHVTimeRange.day7A7P:
      range = { start: "07:00:00", end: "19:00:00" };
      break;

    case EHVTimeRange.night7P7A:
      range = { start: "19:00:00", end: "31:00:00" };
      break;

    case EHVTimeRange.all:
      range = { start: "07:00:00", end: "31:00:00" };
  }

  // 1 - Retrieve and calculate start-end "real" staff shift times
  const shiftTypeStart = localDayJs(shiftType.startTime, "HH:mm:ss");
  let staffShiftStart = localDayJs(staffShift.customStartTime || shiftType.startTime, "HH:mm:ss");
  // Regular shift type is 19:00-07:00, but the custom starts at 01:00 for example
  // We need to add a day to the real shift start.
  if (staffShiftStart.isBefore(shiftTypeStart)) staffShiftStart = staffShiftStart.add(1, "day");

  const staffShiftEnd = staffShiftStart.add(
    staffShift.customDuration || shiftType.durationSeconds,
    "seconds",
  );

  // 1.1 - If the shift starts after the range, or ends before the range, return 0
  const bottomRange = localDayJs(range.start, "HH:mm:ss");
  const topRange = localDayJs(range.end, "HH:mm:ss");
  if (staffShiftStart.isAfter(topRange) || staffShiftEnd.isBefore(bottomRange)) return 0;

  // 2 - Calculate the intersection between the staff shift and the selected range
  const intersectionStart = Math.max(bottomRange.diff(staffShiftStart, "seconds"), 0);
  const intersectionEnd = Math.max(staffShiftEnd.diff(topRange, "seconds"), 0);
  // Make sure we keep the duration that falls within the range
  const trimmedDuration =
    (staffShift.customDuration || shiftType.durationSeconds) - intersectionEnd - intersectionStart;

  // 3 - Calculate the shift-duration vs range ratio
  const rangeDuration = topRange.diff(bottomRange, "seconds");
  const ratio = trimmedDuration / rangeDuration;

  return ratio;
};

export const HouseView_hooks_useStaffingTabs = useStaffingTabs;
