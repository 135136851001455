import { Grid, Typography } from "@mui/material";

import { StaffDetails } from "~/api";
import { Uuid } from "~/common/types";

interface NameCellProps {
  staffId: Uuid;
  staffDetails: StaffDetails.DTO | undefined;
}

export const NameCell = ({ staffId: _staffId, staffDetails }: NameCellProps) => {
  return (
    <Grid
      container
      flexWrap="nowrap"
      alignItems="center"
      sx={{
        paddingLeft: "14px",
        height: "100%",
        paddingRight: "8px",
        background: "transparent",
        borderLeft: "none",
      }}
      className={`header-cell-name`}
    >
      <Typography ml={1} mr={1} variant="small" className="header-cell-name-attribute">
        {staffDetails?.user?.lastName.trim()} {staffDetails?.user?.firstName.trim()},{" "}
        {staffDetails?.staffType.name}{" "}
      </Typography>
    </Grid>
  );
};
