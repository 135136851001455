import {
  Seconds,
  TimeString as TimeStringSharedUtils,
  Timezone,
  YyyyMmDd,
} from "@m7-health/shared-utils";
import dayjs, { Dayjs } from "dayjs";
import { get } from "lodash";

import { localDayJs } from "../packages/dayjs";
import { AnyDate, MmDdYy, TimeString, YyyyMmDd as deprecatedYyyyMmDd } from "../types";

export const getDatesInRange = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
  try {
    return Array(endDate.diff(startDate, "day", false) + 1)
      .fill(startDate)
      .map((date: Dayjs, index) => date.addInTz(index, "days").startOf("day"));
  } catch (_error) {
    const error = _error as Error;
    // want this console warn here so we disable eslint for this line
    // eslint-disable-next-line no-console
    console.warn("dates don't have timezone info, falling back to local timezone", error.stack);
    return Array(endDate.diff(startDate, "day", false) + 1)
      .fill(startDate)
      .map((date: Dayjs, index) => date.add(index, "days").startOf("day"));
  }
};

export const getDateInIsoFormat = (date: Dayjs | string | Date): deprecatedYyyyMmDd => {
  date = localDayJs(date);

  return date.format("YYYY-MM-DD") as deprecatedYyyyMmDd;
};

export const getDateInUSFormat = (date: deprecatedYyyyMmDd): string => {
  const [year, month, day] = date.split("-") as [string, string, string];
  return `${month}/${day}/${year}`;
};

export const addDaysToYyyyMmDd = (date: YyyyMmDd, days: number, timezone: Timezone): YyyyMmDd => {
  return localDayJs.tz(date, timezone).add(days, "days").format("YYYY-MM-DD") as YyyyMmDd;
};

export const getTzFormattedDate = (date: AnyDate, timezone: Timezone): YyyyMmDd => {
  const dateJs = getTzDayjs(date, timezone);

  return dateJs.format("YYYY-MM-DD") as YyyyMmDd;
};

export const getTzUSFormattedDate = (date: AnyDate, timezone: Timezone): string => {
  const dateJs = getTzDayjs(date, timezone);

  return dateJs.format("MM/DD/YYYY");
};

export const getTzDayjs = (date: AnyDate, timezone: Timezone): Dayjs => {
  let dateJs: Dayjs;

  if (typeof date === "string" && date.includes("Z")) {
    dateJs = localDayJs(date).tz(timezone);
  } else {
    dateJs = localDayJs.tz(date, timezone);
  }
  return dateJs;
};

export const getDateInUtcIsoFormat = (date: AnyDate): deprecatedYyyyMmDd =>
  dayjs(date).utc().format("YYYY-MM-DD") as deprecatedYyyyMmDd;

export const getReadableDateFromIsoFormat = (date: string) => {
  return localDayJs(date).format("MMM DD, YYYY");
};

export const getReadableDate = (date: Dayjs | Date, withDayOfWeek = false) => {
  const dayjsDate = dayjs(date);
  return dayjsDate.format(`${withDayOfWeek ? "ddd " : ""}MMM DD, YYYY`);
};

export const getReadableDateWithTime = (date: Dayjs | Date, withDayOfWeek = false) => {
  const dayjsDate = dayjs(date);
  return dayjsDate.format(`${withDayOfWeek ? "ddd " : ""}MMM DD, YYYY [at] h:mm A`);
};

export const isAfterDeadline = (deadline: string | undefined, unitTimezone: string | undefined) => {
  const deadlineInUnitTimezone = localDayJs.tz(deadline, unitTimezone).endOf("day");
  const nowInLocalTimezone = localDayJs();

  return nowInLocalTimezone.isAfter(deadlineInUnitTimezone);
};

export const datesDiffInDays = (start: Dayjs, end: Dayjs, float = false) =>
  end.diff(start, "days", float);

export const getClosesIn = (startDateTime: Dayjs, endDateTime: Dayjs) => {
  return endDateTime.from(startDateTime);
};

export const isPastDate = (date: string | Dayjs): boolean => {
  return localDayJs(date).startOf("day").tz().isBefore(localDayJs().startOf("day").tz());
};

export const dateToDateKey = (date: Dayjs | null): deprecatedYyyyMmDd => {
  if (!date) {
    return "";
  }
  return date.format("YYYY-MM-DD");
};

/**
 * Normalizes a time string by removing milliseconds if present.
 * If the time string does not contain milliseconds, it returns the original.
 *
 * @param {TimeString | TimeStringSharedUtils} timeString - The time string to normalize.
 * @returns {TimeString | TimeStringSharedUtils} The normalized time string.
 */
export const stripMilliseconds = (
  timeString: TimeString | TimeStringSharedUtils,
): TimeString | TimeStringSharedUtils => {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, secondsWithMilliseconds] = timeString.split(":");
  // will never happen... should always have seconds. Else, return the same string
  if (!secondsWithMilliseconds) return timeString;

  // Extract seconds and milliseconds
  const [seconds, _milliseconds] = secondsWithMilliseconds.split(".");

  // Reconstruct the time string without milliseconds
  const formattedTime: TimeString | TimeStringSharedUtils =
    `${hours || ""}:${minutes || ""}:${seconds || ""}` as TimeString | TimeStringSharedUtils;

  return formattedTime;
};

// Function to get the Sunday before a given date
export const getPreviousSunday = (date: Dayjs) => {
  return date.subtract(date.day(), "day");
};

// Function to get the Saturday after a given date
export const getNextSaturday = (date: Dayjs) => {
  return date.add(6 - date.day(), "day");
};

export const getDatesBetween = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
  const dates: Dayjs[] = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, "day"); // Increment by one day
  }

  return dates;
};

// Time manipulation
export const timeStringToDate = (
  time: TimeString | TimeStringSharedUtils,
  timezone?: Timezone,
): Dayjs => {
  const [hours, minutes, secondsAndMs] = time.split(":");
  const [seconds, ms] = secondsAndMs?.split(".") || [0, 0];
  let result = localDayJs();
  if (timezone) result = result.tz(timezone);

  return result
    .set("hour", Number(hours))
    .set("minute", Number(minutes))
    .set("seconds", Number(seconds))
    .set("milliseconds", Number(ms || "000"));
};

export const trimMs = <T extends TimeString | TimeStringSharedUtils>(time: T) =>
  time.split(".")[0]! as T;

export const dateToTimeString = (date: string | Dayjs, timezone?: Timezone): TimeString => {
  const dateJs = timezone ? dayjs.tz(date, timezone) : dayjs(date);

  return dateJs.format("HH:mm:ss.SSS") as TimeString;
};

export const compactDate = (date: deprecatedYyyyMmDd): MmDdYy => {
  const [year, month, day] = date.split("-") as [string, string, string];
  return `${month}/${day}/${year.slice(-2)}` as MmDdYy;
};

export const timeAdd = <T extends TimeString | TimeStringSharedUtils>(
  time: T,
  secondsToAdd: number,
) => {
  const date = timeStringToDate(time).add(secondsToAdd, "seconds");

  return date.format("HH:mm:ss.SSS") as T;
};

export const add24Hours = <T extends TimeString | TimeStringSharedUtils>(time: T): T => {
  const currentHours = Number(time.split(":")[0]!);
  return ((currentHours + 24).toString() + time.slice(2)) as T;
};

/**
 * Checks if the given time string contains the specified hour.
 *
 * @param {TimeString | TimeStringSharedUtils} time - The time string to check. Expected format is "HH:mm:ss" or "HH:mm:ss.SSS".
 * @param {string} hour - The hour to check for. Can be in 12-hour or 24-hour format.
 * @returns {boolean} True if the time contains the specified hour, false otherwise.
 *
 * @example
 * // Returns true
 * isSpecificHourInTimeString("09:30:00", "9");
 *
 * @example
 * // Returns true
 * isSpecificHourInTimeString("14:45:00", "14");
 *
 * @example
 * // Returns false
 * isSpecificHourInTimeString("10:00:00", "11");
 */
export const isSpecificHourInTimeString = (
  time: TimeString | TimeStringSharedUtils,
  hour: string,
) => {
  // the replace(/^0+/, "") is to remove leading zeros
  // example: "09" -> "9"
  return time.split(":")[0]?.replace(/^0+/, "") === hour.replace(/^0+/, "");
};

export const add24HoursSharedUtils = (time: TimeStringSharedUtils): TimeStringSharedUtils => {
  const currentHours = Number(time.split(":")[0]!);
  return ((currentHours + 24).toString() + time.slice(2)) as TimeStringSharedUtils;
};

export const TimeStringToStandardTime = (
  time: TimeString | TimeStringSharedUtils,
  format: "short" | "medium" | "long" = "medium",
) => {
  const date = timeStringToDate(time);
  if (format === "medium") {
    // Check if minutes are "empty"
    if (time.split(":")[1] === "00") format = "short";
    else format = "long";
  }

  switch (format) {
    case "short":
      return date.format("hA").slice(0, -1);
    case "long":
      return date.format("h:mmA").slice(0, -1);
  }
};

/**
 * Calculates the overlap duration in seconds between two time intervals.
 * The time strings must be in the format "HH:mm:ss".
 * If the second interval is before the first interval, the overlap duration is 0.
 * If endTime1 is before startTime1, then endTime1 is assumed to be on the next day.
 * TODO: universalize how this works across app with overlaps
 *
 * @param startTime1 - The start time of the first interval.
 * @param endTime1 - The end time of the first interval.
 * @param startTime2 - The start time of the second interval.
 * @param endTime2 - The end time of the second interval.
 * @returns The overlap duration in seconds.
 *
 * @example
 * // Returns 3600 (1 hour)
 * const overlapDuration = timeOverlap('09:00:00', '10:00:00', '09:30:00', '11:00:00');
 */
export const timeOverlap = (
  startTime1: TimeString | TimeStringSharedUtils,
  endTime1: TimeString | TimeStringSharedUtils,
  startTime2: TimeString | TimeStringSharedUtils,
  endTime2: TimeString | TimeStringSharedUtils,
): number => {
  // Convert time strings to Date objects for easy comparison

  // 2 cases to go into next day, 02 instead of 01
  // 1. if the start time is after the end time, then the end time is on the next day
  // 2. if the start time is before 5AM, then both the start time and end time are on the next day

  // TODO: not use 5AM
  const timeString5AM: TimeString = `05:00:00`;
  // for case 2., adjust start date if needed to 02
  const start1 = new Date(`1970-01-0${startTime1 < timeString5AM ? 2 : 1}T${startTime1}`);
  const start2 = new Date(`1970-01-0${startTime2 < timeString5AM ? 2 : 1}T${startTime2}`);

  // for case 1 and 2., adjust end date if needed to 02
  const end1 = new Date(
    `1970-01-0${startTime1 > endTime1 || startTime1 < timeString5AM ? 2 : 1}T${endTime1}`,
  );
  const end2 = new Date(
    `1970-01-0${startTime2 > endTime2 || startTime2 < timeString5AM ? 2 : 1}T${endTime2}`,
  );

  // Check for overlap
  const overlapStart = Math.max(start1.getTime(), start2.getTime());
  const overlapEnd = Math.min(end1.getTime(), end2.getTime());

  // Calculate duration of the overlap in seconds
  const overlapDurationSeconds = Math.max(0, (overlapEnd - overlapStart) / 1000);

  return Math.abs(overlapDurationSeconds);
};

/**
 * Calculates the time difference in seconds between two time strings.
 * The time strings must be in the format "HH:mm:ss".
 * The time difference is always positive.
 * If the second time is before the first time, the time difference is calculated as if the second time is on the next day.
 *
 * @param {string} time1 - The first time string in the format "HH:mm:ss".
 * @param {string} time2 - The second time string in the format "HH:mm:ss".
 * @returns {number} The time difference in seconds.
 *
 * @example
 * const time1 = "10:30:00";
 * const time2 = "11:45:30";
 * const difference = calculateTimeDifference(time1, time2);
 * console.log(difference); // Output: 4500 (seconds)
 */
export const timeDifference = <T = Seconds>(
  time1: TimeString | TimeStringSharedUtils,
  time2: TimeString | TimeStringSharedUtils,
) => {
  const time2IsNextDay = time2 < time1;
  return Math.abs(
    timeStringToDate(time1).diff(
      timeStringToDate(time2).add(time2IsNextDay ? 1 : 0, "day"),
      "seconds",
    ),
  ) as T;
};

export const dateToMilitary = (date: Dayjs): TimeString => {
  return date.format("HH:mm:ss.SSS") as TimeString;
};

export const msToMilitary = (ms: number): TimeString => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = (minutes % 60).toString().padStart(2, "0");
  const formattedSeconds = (seconds % 60).toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.000` as TimeString;
};

export const timezoneLabel = (
  timezone: Timezone,
  options: { withOffset?: boolean; format?: "long" | "short" } = {},
) => {
  const tzDate = localDayJs().tz(timezone);
  const format = options.format || "short";
  const tzLabel = localDayJs()
    .tz(timezone)
    .format(format === "short" ? "z" : "zzz");
  const tzOffset = (get(tzDate, "$offset") as unknown as number) / 60;

  let result = tzLabel;
  if (options.withOffset) result += ` (UTC${tzOffset >= 0 ? "+" : ""}${tzOffset})`;

  return result;
};
