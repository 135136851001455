import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { LDContext, useLDClient } from "launchdarkly-react-client-sdk";

import { IUser } from "~/features/User/types";
import { IUnitBasic } from "~/routes/api/types";

import { useCurrentUnit, useCurrentUser } from "../hooks";

export const useLaunchDarkly = () => {
  const currentUser = useCurrentUser();
  const currentUnit = useCurrentUnit();

  const ldClient = useLDClient();

  // Configure LaunchDarkly client
  useEffect(() => {
    if (!ldClient) return;

    ldClient.on("error", (e: unknown) => {
      Sentry.addBreadcrumb({
        category: "launchdarkly",
        message: "Error",
        data: { error: e },
      });
    });
  }, [ldClient]);

  // Set LaunchDarkly user context
  useEffect(() => {
    if (!ldClient || !currentUser || !currentUser.id) return; // we must have at least user data to build the context

    const buildUserContext = (user: IUser): LDContext | undefined => {
      if (!user) return undefined;
      const { id, email, firstName, lastName, roles } = user;
      return {
        key: id,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
        roles,
      };
    };

    const buildUnitContext = (
      unit: IUnitBasic | { id: string; name: string | undefined },
    ): LDContext | undefined => {
      const { id, name } = unit;
      return {
        key: id,
        name,
      };
    };

    const userContext = buildUserContext(currentUser);

    const unitContext = currentUnit // if we have a selected unit, use that
      ? buildUnitContext(currentUnit)
      : undefined;

    const context = {
      kind: "multi",
      user: userContext,
      unit: unitContext,
    };

    void ldClient.identify(context);
  }, [currentUser, currentUnit, ldClient]);
};
