import { useMemo } from "react";

import { useAppSelector } from "~/common/hooks/useRedux";
import { ERoles } from "~/features/User/types";

export const useIsKiosk = (): boolean => {
  const currentRole = useAppSelector((state) => state.user.currentRole);

  return useMemo(() => {
    return currentRole === ERoles.Kiosk;
  }, [currentRole]);
};
