import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { Dictionary, entries, keyBy, sortBy, uniq } from "lodash";

import { Box, Divider, Typography } from "@mui/material";

import { IAttribute, IStaffCategory, IStaffShift, useShiftTypesForSchedules } from "~/api";
import CustomCollapse from "~/common/components/Collapse";
import { HouseViewHooks } from "~/features/HouseView/hooks";
import { IShift } from "~/routes/api/types";

import { emptyStaffingTargetsArray } from "#/features/HouseView/hooks/useSetStaffingTargets";
import {
  HVSpecificPositionsTabs,
  TPositionAsTab,
} from "#/features/HouseView/hooks/useStaffingTabs";
import { houseViewStore } from "#/features/HouseView/store";
import { CustomButton } from "@/common/components";
import { useAppFlags, useCheckUserPermission, useIsKiosk, useKeyBy } from "@/common/hooks";
import { useAppSelector } from "@/common/hooks/useRedux";
import { darkPurple } from "@/common/theming/colors";
import { isOnMobile } from "@/common/utils/isOnMobile";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { STAFF_ITEMS_LAYOUTS } from "..";
import { StaffItem } from "../..";
import { SelectedUnitNote } from "../helpers/SelectedUnitNote";
import { THouseViewSideBar } from "../types";

const { useSortedStaffShifts } = HouseViewHooks;

const SelectedUnit = ({ selectedUnit, shifts, staffDetails, notes }: THouseViewSideBar) => {
  const { hvPositionAsTab } = useAppFlags();
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const dispatch = useDispatch();
  const { newStaffingTargetsModal } = useAppFlags();

  // Display shifts
  const [hoveredStaffId, setHoveredStaffId] = useState<IStaffShift["staffId"] | undefined>();
  const selectedUnitShifts = selectedUnit && shifts?.[selectedUnit.id];
  const shiftTypesBySchedule = useShiftTypesForSchedules(
    uniq(selectedUnitShifts?.map((shift) => shift.scheduleId) || []),
  );
  const shiftTypeByScheduleByKey = Object.entries(shiftTypesBySchedule || {}).reduce(
    (acc, [shiftTypeScheduleId, shiftTypes]) => {
      acc[shiftTypeScheduleId] = keyBy(shiftTypes, "key");

      return acc;
    },
    {} as Record<string, Dictionary<IShift>>,
  );

  const orderedUnitShifts = useSortedStaffShifts(selectedUnitShifts || [], staffDetails || {});
  const unitAttributes = useKeyBy(selectedUnit.attributes, "key");

  const liveShifts: Partial<{ [key in IStaffCategory["name"] | string]: IStaffShift[] }> = {};
  const shiftsWithUpdates: IStaffShift[] = [];
  const otherShifts: IStaffShift[] = [];
  orderedUnitShifts?.forEach((shift) => {
    const shiftStaffDetails = staffDetails?.[shift.staffId];
    const shiftType = shiftTypeByScheduleByKey[shift.scheduleId]?.[shift.shiftTypeKey];
    const category = shiftStaffDetails?.staffType?.staffCategory?.name;
    const [specificPosition, positionTab] = hvPositionAsTab
      ? findSpecificPosition(shift.attributes, unitAttributes)
      : [undefined, undefined];

    if (!category || !shiftType) return;
    if (!shiftType.isWorkingShift) return;
    const voidedShift = voidingShiftStatus(notes?.[shift.staffId]?.update || shift.status);

    if (voidedShift) {
      shiftsWithUpdates.push(shift);
    } else if (specificPosition && positionTab?.separateTab) {
      if (!positionTab.countInTarget) {
        (liveShifts[specificPosition.name] ||= []).push(shift);
      } else {
        (liveShifts[category] ||= []).push(shift);
      }
    } else if (shiftType.isCountedForRealTimeStaffingTarget) {
      (liveShifts[category] ||= []).push(shift);
    } else {
      otherShifts.push(shift);
    }
  });
  const allStaffingTargets = useAppSelector(
    (state) => state.houseView.staffingLevels.allStaffingTargets,
  );
  const selectedStaffingTargetsArray =
    allStaffingTargets[selectedUnit.id] || emptyStaffingTargetsArray;
  const latestStaffingTargetLevel = selectedStaffingTargetsArray?.[0];
  const latestPatientCount = latestStaffingTargetLevel?.patientCount;

  const editStaffShifts = useCallback(
    (staffId: string) => {
      dispatch(
        houseViewStore.state.startEditShifts({
          staffId,
        }),
      );
    },
    [dispatch],
  );

  const renderShift = useCallback(
    (shift: IStaffShift) => {
      if (!selectedUnit || !staffDetails || !notes) return <></>;

      return (
        <StaffItem
          onClick={editStaffShifts}
          shift={shift}
          note={notes[shift.staffId]}
          layout={STAFF_ITEMS_LAYOUTS.selectedUnit}
          unitId={selectedUnit.id}
          hoverUpdated={(staffId) => setHoveredStaffId(staffId)}
          hovered={hoveredStaffId === shift.staffId}
        />
      );
    },
    [selectedUnit, staffDetails, notes, editStaffShifts, hoveredStaffId],
  );

  if (!selectedUnit || !staffDetails || !notes) return <></>;

  return (
    <>
      {newStaffingTargetsModal && isOnMobile() && (
        <Box mb={1}>
          <Box
            className="patient-count"
            sx={{
              pt: 1,
              pl: 1,
              pb: 1,
            }}
          >
            <Box
              className="patient-count"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={18} fontWeight={600}>
                Patients:{" "}
                <span className="patient-count-number">{latestPatientCount || "N/A"}</span>
              </Typography>
              {!isKioskUser && canManage && (
                <CustomButton
                  onClick={() => {
                    dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true));
                    dispatch(houseViewStore.state.setSelectedUnitId(selectedUnit.id));
                  }}
                  sx={{ borderColor: darkPurple, py: 0.5, px: 1 }}
                  label={"Update"}
                  variant={"outlined"}
                  color={"primary"}
                  trackingLabel="Update Patient Count"
                />
              )}
            </Box>
          </Box>
          <Divider />
        </Box>
      )}
      <Typography variant="subtitle1" fontWeight="500" children="Staff List" width="100%" />
      <Box sx={{ overflowY: "auto" }}>
        {sortBy(
          entries(liveShifts),
          ([categoryName, _]) => ({ Nurse: 1, Tech: 2 })[categoryName] || 3,
        ).map(([categoryName, orderedCategoryShifts = []]) => (
          <CustomCollapse
            key={"side-bar-staff-list-" + categoryName}
            label={`${categoryName} (${orderedCategoryShifts.length})`}
            isExpandedByDefault
            content={<Box>{orderedCategoryShifts.map((shift) => renderShift(shift))}</Box>}
          />
        ))}
        {shiftsWithUpdates[0] && (
          <CustomCollapse
            isExpandedByDefault
            label={`Staff with updates (${shiftsWithUpdates.length})`}
            content={<Box>{shiftsWithUpdates.map((shift) => renderShift(shift))}</Box>}
          />
        )}
        {otherShifts[0] && (
          <CustomCollapse
            isExpandedByDefault
            label={`Staff with other shift types (${otherShifts.length})`}
            content={<Box>{otherShifts.map((shift) => renderShift(shift))}</Box>}
          />
        )}
      </Box>
      <Box flexGrow={1} />
      {<SelectedUnitNote selectedUnit={selectedUnit} />}
    </>
  );
};

/** Return position and tab characteristic of the first attribute that matches a specific position */
type TPositionTab = (typeof HVSpecificPositionsTabs)[TPositionAsTab];
const findSpecificPosition = (
  shiftAttributes: IAttribute["key"][],
  unitAttributes: Record<string, IAttribute>,
): [IAttribute | undefined, TPositionTab | undefined] => {
  let specificPosition: IAttribute | undefined = undefined;
  let positionTab: TPositionTab | undefined;

  for (const attributeKey of shiftAttributes) {
    const attribute = unitAttributes[attributeKey];
    const attributeName = attribute?.name.toLowerCase() as TPositionAsTab;
    const tab = attributeName ? HVSpecificPositionsTabs[attributeName] : undefined;
    if (tab) {
      specificPosition = attribute!;
      positionTab = tab;
      break;
    }
  }

  return [specificPosition, positionTab];
};

export const _HouseView_SideBar_SelectedUnit = SelectedUnit;
