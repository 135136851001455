import { useMemo, useState } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { Dayjs } from "dayjs";
import { groupBy, keyBy, sortBy } from "lodash";

import { Box, CircularProgress } from "@mui/material";

import {
  IStaffShift,
  useListSchedulesQuery,
  useListStaffDetailsQuery,
  useListStaffShiftsQuery,
  useListUnitsQuery,
} from "~/api";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useCurrentFacilityId } from "~/common/hooks/useCurrentFacilityId";
import { useAppSelector } from "~/common/hooks/useRedux";
import { localDayJs } from "~/common/packages/dayjs";
import { Uuid, dateString } from "~/common/types";

import { useAppFlags } from "@/common/hooks";
import { compareStaffOrder } from "@/common/utils/compareStaffOrder";

import { HouseViewHooks } from "../../hooks";
import { calculatePortionFixed7A7P } from "../../hooks/useStaffingTabs";
import { EHVTimeRange } from "../../store/pageFiltersActions";
import { HouseView_StaffCalendar_Hooks } from "../staffCalendar/hooks";

import { TableStyleContainer } from "./style";

import { TableParts } from ".";

const { useSchedulesShiftTypes, useUnitShiftTypes } = HouseView_StaffCalendar_Hooks;
const { useSelectedDay } = HouseViewHooks;

// to be used only on multi-week view
export interface ITablesData {
  [scheduleId: string]: {
    [dayKey: string]: {
      [EHVTimeRange.day7A7P]: number;
      [EHVTimeRange.night7P7A]: number;
      shiftsByStaff: {
        [staffId: string]: IStaffShift[];
      };
    };
  };
}

export const MultiWeekHouseViewTable = () => {
  const selectedFacilityId = useCurrentFacilityId();
  const { showSixWeekTotalShiftCount } = useAppFlags();
  const { selectedStaffCategory, unitIdsFilter } = useAppSelector((state) => ({
    selectedStaffCategory: state.houseView.pageFilters.selectedStaffCategory,
    unitIdsFilter: state.houseView.pageFilters.unitIds,
  }));

  const { value: selectedDay } = useSelectedDay();
  const range = useMemo(
    () => ({ from: localDayJs(selectedDay), to: localDayJs(selectedDay).add(6, "week") }),
    [selectedDay],
  );

  /** QUERIES */
  // Get all shifts for the selected facility and date range
  const { data: staffShifts = [] } = useListStaffShiftsQuery(
    {
      date: [
        { value: range.from.format("YYYY-MM-DD"), operator: "gte" },
        { value: range.to.format("YYYY-MM-DD"), operator: "lte" },
      ],
    },
    { skip: !selectedFacilityId },
  );
  // Get the rest of the data needed to display the shifts
  const { data: allSchedules = [] } = useListSchedulesQuery({});
  const { data: unfilteredUnits = [] } = useListUnitsQuery(
    { facilityId: selectedFacilityId || NOT_EXISTING_UUID },
    { skip: !selectedFacilityId },
  );
  const { data: allStaffDetails = [], isLoading: isLoadingStaffDetails } = useListStaffDetailsQuery(
    {},
    { skip: staffShifts.length === 0 },
  );

  const units = useMemo(
    () =>
      unitIdsFilter.length
        ? unfilteredUnits.filter((unit) => unitIdsFilter.includes(unit.id))
        : unfilteredUnits,
    [unitIdsFilter, unfilteredUnits],
  );

  // Build static array of all days in the range
  const [allDays, allDayJs] = useMemo(() => {
    const days: YyyyMmDd[] = [];
    const daysJs: Dayjs[] = [];
    let day = range.from;
    while (day.isSameOrBefore(range.to, "day")) {
      days.push(day.format("YYYY-MM-DD") as YyyyMmDd);
      daysJs.push(day);
      day = day.add(1, "day");
    }
    return [days, daysJs];
  }, [range.from, range.to]);

  /** INDEXING/CACHING data */
  // staff shifts
  const shiftsByScheduleId = useMemo(() => groupBy(staffShifts, "scheduleId"), [staffShifts]);
  // schedules
  const scheduleIds = useMemo(() => Object.keys(shiftsByScheduleId), [shiftsByScheduleId]);
  // include any schedule that could potentially have a shift in between the from / to range
  // needs to be looking at startDay and endDay, and if either is in the range, include the schedule
  const schedules = useMemo(() => {
    return allSchedules.filter(
      (schedule) =>
        scheduleIds.includes(schedule.id) &&
        (schedule.startDay >= range.from.format("YYYY-MM-DD") ||
          schedule.startDay <= range.to.format("YYYY-MM-DD") ||
          schedule.endDay >= range.from.format("YYYY-MM-DD") ||
          schedule.endDay <= range.to.format("YYYY-MM-DD")),
    );
  }, [allSchedules, scheduleIds, range.from, range.to]);
  // shift types
  const unitsById = useMemo(() => keyBy(units, "id"), [units]);
  const shiftTypesByScheduleIdByKey = useSchedulesShiftTypes(scheduleIds);
  const shiftTypesByUnitIdByKey = useUnitShiftTypes(Object.keys(unitsById));

  // units
  const unitsByScheduleIds = useMemo(
    () =>
      schedules.reduce(
        (acc, schedule) => {
          acc[schedule.id] = unitsById[schedule.unitId]!;
          return acc;
        },
        {} as Record<string, (typeof units)[number]>,
      ),
    [schedules, unitsById],
  );
  // Staff details
  const staffDetailsByStaffId = useMemo(() => {
    return keyBy(allStaffDetails, "userId");
  }, [allStaffDetails]);
  const staffByUnitId = useMemo(() => {
    return staffShifts.reduce(
      (acc, { scheduleId, staffId }) => {
        (acc[unitsByScheduleIds?.[scheduleId]?.id || ""] ||= new Set<string>()).add(staffId);
        return acc;
      },
      {} as Record<string, Set<string>>,
    );
  }, [staffShifts, unitsByScheduleIds]);

  /** Computing data for the tables */
  // Data indexed by
  // -> scheduleId
  //  -> day
  //    -> range: count
  //    -> staffId: shift
  const tablesData = useMemo(() => {
    const formattedDates: Record<dateString, string> = {};
    const getFormattedDate = (date: dateString) => {
      if (!formattedDates[date]) formattedDates[date] = localDayJs(date).format("YYYY-MM-DD"); // maybe need to TZ this

      return formattedDates[date]!;
    };

    const result: ITablesData = {};
    for (const schedule of schedules) {
      // Retrieve all shift for current schedule
      const shifts = shiftsByScheduleId[schedule.id] || [];
      for (const shift of shifts) {
        const staff = staffDetailsByStaffId[shift.staffId];
        // only look at shifts for the selected category
        if (staff?.staffType.staffCategoryKey === selectedStaffCategory) {
          const shiftType = shiftTypesByScheduleIdByKey[shift.scheduleId]?.[shift.shiftTypeKey];
          if (!shiftType) continue;

          const dayKey = getFormattedDate(shift.date);

          // Initialize data if not already present
          const shiftsBySchedule = (result[schedule.id] ||= {});
          const shiftsByDay = (shiftsBySchedule[dayKey] ||= {
            [EHVTimeRange.day7A7P]: 0,
            [EHVTimeRange.night7P7A]: 0,
            shiftsByStaff: {},
          });

          ([EHVTimeRange.day7A7P, EHVTimeRange.night7P7A] as const).forEach((aRange) => {
            shiftsByDay[aRange] += calculatePortionFixed7A7P(shift, shiftType, aRange);
          });
          const staffShiftsForDay = (shiftsByDay.shiftsByStaff[shift.staffId] ||= []);
          staffShiftsForDay.push(shift);
        }
      }
    }
    return result;
  }, [
    shiftsByScheduleId,
    shiftTypesByScheduleIdByKey,
    schedules,
    selectedStaffCategory,
    staffDetailsByStaffId,
  ]);

  const [expandedUnits, setExpandedUnits] = useState<Record<string, boolean>>({});

  if (isLoadingStaffDetails) {
    return <CircularProgress></CircularProgress>;
  }

  // Return one table per UNIT now
  // Each table contains multiple header rows per available range
  // first header column is the range, and the staff names
  // then it's one column per day
  // each header row range cell contains the sum of the shifts for this range and this day
  // each staff row contains the shifts for this staff for this day
  return (
    <Box className="6week-schedules-wrapper" overflow="auto">
      {showSixWeekTotalShiftCount && (
        <TableParts.TotalCountHeader
          datesRows={allDayJs}
          formattedDateRows={allDays}
          schedules={schedules}
          tablesData={tablesData}
        />
      )}

      {sortBy(units, "name").map((unit) => {
        const sortStaffByPrimaryShiftTypeFlag =
          unit?.configuration?.settings?.schedulerApp?.sortStaffByPrimaryShiftTypeInSchedulerGrid;
        const availableShiftTypes = shiftTypesByUnitIdByKey[unit.id] || {};
        const staffSorting = (staffIdA: Uuid, staffIdB: Uuid) => {
          const staffA = staffDetailsByStaffId[staffIdA];
          const staffB = staffDetailsByStaffId[staffIdB];
          if (!staffA || !staffB) return 0;
          return compareStaffOrder(
            sortStaffByPrimaryShiftTypeFlag,
            staffA,
            staffB,
            unit.id,
            availableShiftTypes,
          );
        };
        const staffsOfFilteredCategory = [...(staffByUnitId[unit.id] || [])]
          .filter((staffId) => {
            const staff = staffDetailsByStaffId[staffId];
            return staff?.staffType.staffCategoryKey === selectedStaffCategory;
          })
          .sort(staffSorting);

        return (
          <Box
            mt={3}
            width="97vw"
            maxHeight="80vh"
            marginBottom="60px"
            boxSizing="border-box"
            display="flex"
            overflow={"auto"}
            flexDirection="column"
          >
            <TableStyleContainer>
              <table
                className={`scheduler-table mini-view v2`}
                style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
              >
                <tbody>
                  <TableParts.HeaderRow
                    datesRows={allDayJs}
                    formattedDateRows={allDays}
                    unit={unit}
                    schedules={schedules}
                    tablesData={tablesData}
                    expanded={!!expandedUnits[unit.id]}
                    setExpandedUnits={setExpandedUnits}
                  />

                  {expandedUnits[unit.id] && (
                    <TableParts.StaffRows
                      staffIds={staffsOfFilteredCategory}
                      staffDetailsByStaffId={staffDetailsByStaffId}
                      datesRowsFormatted={allDays}
                      schedules={schedules}
                      tablesData={tablesData}
                      unitId={unit.id}
                    />
                  )}
                </tbody>
              </table>
            </TableStyleContainer>
          </Box>
        );
      })}
    </Box>
  );
};

export const _HouseView_MultiWeekTable = MultiWeekHouseViewTable;
