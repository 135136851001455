/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-argument */
// Extracted from https://github.com/iamkun/dayjs/issues/1271#issuecomment-1686532292
// eslint-disable-next-line deprecate/import
import { Timezone } from "@m7-health/shared-utils";
import dayjs, { PluginFunc } from "dayjs";

declare module "dayjs" {
  interface Dayjs {
    addInTz: dayjs.Dayjs["add"];
    subtractInTz: dayjs.Dayjs["subtract"];
  }
}

export const tzAddPlugin: PluginFunc = (_, dayjsClass, d) => {
  dayjsClass.prototype.addInTz = function (...args) {
    // @ts-ignore - we know that $x exists
    const timezone = this.$x.$timezone as Timezone;
    if (!timezone) {
      throw new Error("No timezone set");
    }
    return d.tz(
      d(this.toDate())
        .tz(timezone)
        // @ts-ignore - we forward the arguments to the original add method
        .add(...args)
        .format("YYYY-MM-DD HH:mm:ss"),
      timezone,
    );
  };

  dayjsClass.prototype.subtractInTz = function (...args) {
    // @ts-ignore - we know that $x exists
    const timezone = this.$x.$timezone;
    if (!timezone) {
      throw new Error("No timezone set");
    }
    return d.tz(
      d(this.toDate())
        .tz(timezone)
        // @ts-ignore - we forward the arguments to the original add method
        .subtract(...args)
        .format("YYYY-MM-DD HH:mm:ss"),
      timezone,
    );
  };
};
