import { ISODateString } from "@m7-health/shared-utils";

import { APIListResponse, SDTO } from "~/api/_shared/types";

import { BULK_DELETE_KEY } from "@/common/constants";

import { IAttribute } from "../attribute";
import { ISchedule } from "../schedule";
import { IShiftType } from "../shiftType";
import { IStaffCategory } from "../staffCategory";
import { IStaffType } from "../staffType";
import { IUnit } from "../unit";

export type ITargetLevel = TargetLevel.DTO;
export type IUnitTargetLevel = TargetLevel.Unit.DTO;

// incomplete
interface BaseDTO extends SDTO.base, SDTO.withTimestamps {
  min: number;
  shiftTypeKey: IShiftType["key"];
  staffCategoryKey: IStaffCategory["key"];
  attributeKey?: IAttribute["key"];

  /** @deprecated do not use, only use staffCategoryKey */
  staffTypeKey?: IStaffType["key"];
}
export namespace TargetLevel {
  export interface DTO extends BaseDTO {
    scheduleId: ISchedule["id"];
    date: ISODateString;
  }

  export namespace API {
    export const ROOT_PATH = "/target-level";
    export namespace List {
      export const PATH = ROOT_PATH;
      export type QueryParams = {
        scheduleIds?: ISchedule["id"][];
      };
      export type Response = APIListResponse<DTO>;
    }
  }

  export namespace Unit {
    export interface DTO extends BaseDTO {
      unitId: IUnit["id"];
      daysOfWeek: number[];
    }

    export namespace API {
      export const ROOT_PATH = "/unit-target-level";

      export namespace List {
        export const PATH = ROOT_PATH;
        export type QueryParams = {
          unitIds?: IUnit["id"][];
        };
        export type Response = APIListResponse<DTO>;
      }

      export namespace BulkSave {
        export const PATH = `${ROOT_PATH}/bulk-save`;
        export type QueryParams = {
          unitTargetLevels: (
            | TUnitTargetLevelToUpdate
            | TUnitTargetLevelToCreate
            | TUnitTargetLevelToDelete
          )[];
        };
        export type Response = APIListResponse<DTO>;
      }
    }
  }
}
export type UpdatableAttributes = Pick<
  IUnitTargetLevel,
  "shiftTypeKey" | "staffCategoryKey" | "attributeKey" | "min"
>;

type TUnitTargetLevelToDelete = {
  id: IUnitTargetLevel["id"];
  [BULK_DELETE_KEY]: true;
};

type TUnitTargetLevelToUpdate = {
  id: IUnitTargetLevel["id"];
} & Partial<UpdatableAttributes>;

type TUnitTargetLevelToCreate = UpdatableAttributes;
