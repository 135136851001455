import { useLocation } from "react-router-dom";

import { Timezone } from "@m7-health/shared-utils";
import { uniq } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { IUnit } from "@/api";

import { localDayJs } from "../packages/dayjs";

import { useCurrentFacility } from "./useCurrentFacilityId";
import { useCurrentFacilityUnits } from "./useCurrentFacilityUnits";
import { useCurrentUnit } from "./useCurrentUnitId";
import { useDeepMemo } from "./useDeepMemo";

export const useCurrentTimezone = (unitId?: IUnit["id"] | null) => {
  const location = useLocation();

  const timezoneFromUnitParam = useAppConfigQuery().data?.accessibleUnits.find(
    (unit) => unit.id === unitId,
  )?.timezone;
  const facilityTimezone = useCurrentFacility()?.timezone;
  const unitTimezone = useCurrentUnit()?.timezone;

  // Make sure we prioritize unit timezone over facility timezone everywhere (except in house view)
  const currentViewIsHouseView = location.pathname.endsWith("house-view");
  const prioritizedTimezone = currentViewIsHouseView ? facilityTimezone : unitTimezone;

  return (timezoneFromUnitParam ||
    prioritizedTimezone ||
    unitTimezone ||
    facilityTimezone ||
    localDayJs.tz.guess()) as Timezone;
};

export const useFacilityTimezones = () => {
  const facilityUnits = useCurrentFacilityUnits();

  return useDeepMemo(
    () => (uniq(facilityUnits?.map((unit) => unit.timezone)) || []) as Timezone[],
    [facilityUnits],
  );
};
