/* eslint-disable no-console */
import { memo } from "react";

import { HeaderCell } from "./legacyUsedParts/HeaderCell";

export const STAFFING_LEVEL_MODAL_COLUMN_TITLES = ["Unit", "Patient Count", "Nurses", "Techs"];

// eslint-disable-next-line  @typescript-eslint/naming-convention
export const _StaffingLevelsHeaderRow = memo(function Memoized_StaffingLevelsHeaderRow() {
  const HeaderCellComponent = HeaderCell;

  return (
    <tr>
      {STAFFING_LEVEL_MODAL_COLUMN_TITLES.map((title) => {
        return (
          <th key={title}>
            <div>
              <HeaderCellComponent key={title + "-header"} title={title} />
            </div>
          </th>
        );
      })}
    </tr>
  );
});
