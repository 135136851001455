/* eslint-disable no-console */

import { YyyyMmDd } from "@m7-health/shared-utils";

import { Schedule, StaffDetails } from "~/api";
import { Uuid } from "~/common/types";

import { TableParts } from "..";
import { ITablesData } from "../Table";

export const _SchedulerScheduleTableStaffRows = ({
  staffIds,
  staffDetailsByStaffId,
  datesRowsFormatted,
  schedules,
  tablesData,
  unitId,
}: {
  staffIds: Uuid[];
  staffDetailsByStaffId: {
    [x: string]: StaffDetails.DTO;
  };
  datesRowsFormatted: YyyyMmDd[];
  schedules: Schedule.DTO[];
  tablesData: ITablesData;
  unitId: Uuid;
}) => {
  return (
    <>
      {staffIds.map((staffId) => {
        const staffDetails = staffDetailsByStaffId[staffId];
        return (
          <tr key={"row-" + staffId}>
            <th className="sticky-col">
              <TableParts.StaffNameHeaderCell staffId={staffId} staffDetails={staffDetails} />
            </th>
            {datesRowsFormatted.map((formattedDate) => {
              const schedule = schedules.find(
                ({ startDay, endDay, unitId: possibleUnitId }) =>
                  startDay <= formattedDate && formattedDate <= endDay && unitId === possibleUnitId,
              );
              const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
              const shiftInfo = shiftsByDayByRange?.[formattedDate]?.shiftsByStaff[staffId];

              return (
                <TableParts.StaffShiftCell
                  key={`scheduler-grid-cell-${formattedDate}-${staffId}`}
                  staffId={staffId}
                  date={formattedDate}
                  scheduleId={schedule?.id || ""}
                  shiftInfo={shiftInfo}
                />
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
