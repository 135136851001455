import { useCallback } from "react";

import { TimeString, YyyyMmDd, isWeekendShift } from "@m7-health/shared-utils";

import { IShift, IUnitBasic } from "~/routes/api/types";

import { DeprecatedTShift } from "#/features/SchedulerGrid/types";
import { useAppConfigQuery } from "#/features/User/queries";
import { IPreferenceRequest, IStaffShift, IUnit, Note, ShiftType, StaffShift } from "@/api";

import { useCurrentUnit } from "../hooks";
import { localDayJs } from "../packages/dayjs";
import { YyyyMmDd as deprecatedYyyyMmDd } from "../types";

export const getAllShiftsFromPreferences = (
  preferences: { shiftTypeKey: DeprecatedTShift }[],
  scheduleShiftOptions: Record<
    string,
    {
      isCountedForRequirements: boolean;
    }
  >,
) =>
  preferences.filter(
    ({ shiftTypeKey }) => scheduleShiftOptions?.[shiftTypeKey]?.isCountedForRequirements,
  );

export const getWeekendShiftsFromPreferences = (
  preferences: { shiftTypeKey: DeprecatedTShift; date: deprecatedYyyyMmDd | string }[],
  weekendDays: number[],
  scheduleShiftOptions: Record<string, IShift | ShiftType.DTO>,
  weekendTimeBoundaries: { startTime: TimeString; endTime: TimeString },
) =>
  preferences.filter(({ date, shiftTypeKey }) =>
    isWeekendShift({
      date: date as YyyyMmDd,
      settings: {
        weekendDays,
        ...weekendTimeBoundaries,
      },
      shiftType: scheduleShiftOptions?.[shiftTypeKey] || undefined,
    }),
  );

export const getBlockShiftsFromPreferences = (preferences: { shiftTypeKey: DeprecatedTShift }[]) =>
  preferences.filter(({ shiftTypeKey }) => shiftTypeKey === "block");

export const filterPreferencesByDateRange = <T extends IPreferenceRequest>(
  preferences: T[],
  startDay: string | undefined,
  endDay: string | undefined,
) =>
  preferences.filter(({ date }) => {
    const preferenceDate = localDayJs(date);
    return (
      preferenceDate.isSame(localDayJs(startDay)) ||
      preferenceDate.isSame(localDayJs(endDay)) ||
      (preferenceDate.isAfter(localDayJs(startDay)) && preferenceDate.isBefore(localDayJs(endDay)))
    );
  });

/**
 * Return a list of preferences that does not contain currently selected dates
 */
export const filterPreferencesBySelectedDates = <T extends IPreferenceRequest>(
  preferences: T[],
  selectedDates: string[],
) => {
  return preferences.filter((preference) => {
    const date = preference.date as string;
    return !selectedDates.includes(date);
  });
};

/**
 * Whether a shift with a status (or deprecated note update)
 *  voids a shift or not. For example, a shift with called-in status
 *  should not be counted in targets, or hours worked.
 *
 * @param staffShiftOrStatus - StaffShift or StaffShift["status"]
 * @returns boolean - true if shift is voided
 */
const NonVoidingStatuses = [StaffShift.EStatus.floated];
export const voidingShiftStatus = (
  staffShiftOrStatus?: IStaffShift | StaffShift.EStatus | null | Note.EUpdate,
) => {
  const status =
    typeof staffShiftOrStatus === "string" ? staffShiftOrStatus : staffShiftOrStatus?.status;

  return !!status && !NonVoidingStatuses.includes(status as StaffShift.EStatus);
};

export const useStatusLabel = (unitId?: IUnit["id"]) => {
  const currentUnitConfigLabels = useCurrentUnit()?.configuration.data?.shiftStatusLabels;
  const providedUnitIdConfig = useAppConfigQuery().data?.units?.find((unit) => unit.id === unitId);

  // if providedUnitIdConfig is found, use it even if the configuration is empty, as we fallback
  //  default values, rather than using the custom ones of the one selected in the state that might be
  //  irrelevant in the context of the provided unitId.
  const unitConfigLabels = providedUnitIdConfig
    ? providedUnitIdConfig.configuration?.data?.shiftStatusLabels
    : currentUnitConfigLabels;

  return useCallback(
    (label: StaffShift.EStatus | null | undefined) => (label && unitConfigLabels?.[label]) || label,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(unitConfigLabels)],
  );
};

/**
 * Determines whether to show the status based on the provided unit configuration.
 * @param {StaffShift.EStatus | string} status - The status string.
 * @param {IUnitBasic | undefined} unit - The unit object containing configuration settings.
 * @returns {boolean} - Returns true if the status should be shown, otherwise false.
 */
export const showStatusForUnit = (status: StaffShift.EStatus, unit: IUnitBasic | undefined) => {
  switch (status) {
    case StaffShift.EStatus.onCall4h:
      return !!unit?.configuration.settings?.schedulerApp?.useOnCall4h;
    case StaffShift.EStatus.flexed:
      return !!unit?.configuration.settings?.schedulerApp?.useFlexedUpdate;
    case StaffShift.EStatus.requestOnCall:
      return !!unit?.configuration.settings?.schedulerApp?.useRequestOnCall;
  }

  return true;
};
