import { EStaffScheduleTypes, ICalendarState } from "#/features/CalendarV2/types";

export const initialState: ICalendarState = {
  scheduleType: EStaffScheduleTypes.Current,
  selectedTiles: [],
  selectedSchedule: undefined,
  displayOpenShifts: true,
  shiftDetailsOpen: null,
  areShiftNotesExpanded: true,
  isRequestOpenShiftModalOpen: false,
  selectedOpenShiftData: null,
  isUnrequestOpenShiftModalOpen: false,
  dropShiftData: {
    isConfirmModalOpen: false,
  },
  swapShiftData: {
    swapping: false,
    submittingSwap: false,
    dateToSwapWith: null,
    shiftToSwapWithData: null,
    personToSwapWith: null,
  },
  timeOffRequestModal: {
    isOpen: false,
  },
};
