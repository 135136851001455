import { INote, ISchedule, IStaffShift } from "~/api";
import { KeyBy } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { HouseViewDeprecatedStaffingLevelModal as DeprecatedUpdateStaffLevels } from "./StaffingLevelModal/DeprecatedStaffingLevelModal";
import { HouseViewStaffingLevelModal as UpdateStaffLevels } from "./StaffingLevelModal/StaffingLevelModal";

export type THouseViewModal = {
  unitsBySchedule: Record<ISchedule["id"], IUnitBasic>;
  shiftsByUnit: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
  notes: KeyBy<INote, "userId">;
};

const Modals = ({ shiftsByUnit }: THouseViewModal) => (
  <>
    <DeprecatedUpdateStaffLevels />
    <UpdateStaffLevels shiftsByUnit={shiftsByUnit} />
  </>
);

export const _HouseView_Modals = Modals;
