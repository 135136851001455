import { useCallback } from "react";

import { Grid, Typography } from "@mui/material";

import { RealTimeStaffingTarget } from "~/api/realTimeStaffingTargets";
import CustomInput from "~/common/components/Input";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { houseViewStore } from "~/features/HouseView/store";
import { IUnitBasic } from "~/routes/api/types";

import { dateString } from "@/common/types";

import { staffingLevelSelector } from "../../helpers";

// eslint-disable-next-line  @typescript-eslint/naming-convention
export const _StaffingLevelsUnitRow = ({ unit }: { unit: IUnitBasic }) => {
  const dispatch = useAppDispatch();
  const unitRow = useAppSelector(staffingLevelSelector(unit.id));
  const selectedDate = useAppSelector((state) => state.houseView.pageFilters.selectedDate);

  const updateStaffingLevelHandler = useCallback(
    (payload: Partial<RealTimeStaffingTarget.DTO>) => {
      dispatch(houseViewStore.state.updateStaffingLevelDeprecated(payload));
    },
    [dispatch],
  );

  return (
    <tr key={"row-" + unit.id}>
      <td key={`cell unitName for unitId ${unit.id}`}>
        <Grid container minWidth={"200px"} p={1}>
          <Typography display="flex" alignContent={"center"} justifyContent={"center"}>
            {unit.name}
          </Typography>
        </Grid>
      </td>

      <td key={`cell patient count for unitId ${unit.id}`}>
        <Grid container>
          <CustomInput
            label=""
            name="cell patient count"
            type="number"
            counter
            onChange={(event) => {
              updateStaffingLevelHandler({
                ...unitRow,
                patientCount: Number(event.target.value),
                date: selectedDate as string as dateString,
              });
            }}
            field={{
              value: unitRow?.patientCount || 0,
              // onchange from increment/decrement buttons
              onChange: (newValue: string) =>
                updateStaffingLevelHandler({
                  ...unitRow,
                  patientCount: Number(newValue),
                  date: selectedDate as string as dateString,
                }),
            }}
            sx={{ ml: "30%", width: "40%" }}
          />
        </Grid>
      </td>
      <td key={`cell nurse count for unitId ${unit.id}`}>
        <Grid container>
          <Typography alignContent={"center"} justifyContent={"center"}>
            <CustomInput
              label=""
              name="cell nurse count"
              type="number"
              counter
              onChange={(event) => {
                updateStaffingLevelHandler({
                  ...unitRow,
                  date: selectedDate as string as dateString,
                  staffingTarget: {
                    ...(unitRow?.staffingTarget || {}),
                    nurse: Number(event.target.value),
                  },
                });
              }}
              field={{
                value: unitRow?.staffingTarget?.nurse || 0,
                // onchange from increment/decrement buttons
                onChange: (newValue: string) =>
                  updateStaffingLevelHandler({
                    ...unitRow,
                    date: selectedDate as string as dateString,
                    staffingTarget: { ...(unitRow?.staffingTarget || {}), nurse: Number(newValue) },
                  }),
              }}
              sx={{ ml: "30%", width: "40%" }}
            />
          </Typography>
        </Grid>
      </td>
      <td key={`cell tech count for unitId ${unit.id}`}>
        <Grid container>
          <Typography alignContent={"center"} justifyContent={"center"}>
            <CustomInput
              label=""
              name="cell tech count"
              type="number"
              counter
              onChange={(event) => {
                updateStaffingLevelHandler({
                  ...unitRow,
                  date: selectedDate as string as dateString,
                  staffingTarget: {
                    ...(unitRow?.staffingTarget || {}),
                    tech: Number(event.target.value),
                  },
                });
              }}
              field={{
                value: unitRow?.staffingTarget?.tech || 0,
                // onchange from increment/decrement buttons
                onChange: (newValue: string) =>
                  updateStaffingLevelHandler({
                    ...unitRow,
                    date: selectedDate as string as dateString,
                    staffingTarget: { ...(unitRow?.staffingTarget || {}), tech: Number(newValue) },
                  }),
              }}
              sx={{ ml: "30%", width: "40%" }}
            />
          </Typography>
        </Grid>
      </td>
    </tr>
  );
};
