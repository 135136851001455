import { useCallback, useEffect, useMemo, useState } from "react";

import { IUnit, useListUnitsQuery } from "~/api";
import { useAppConfigQuery, useStaffDetailsQuery } from "~/features/User/queries";

import { httpClient } from "../packages/httpClient";

import { useIsAdmin } from "./useIsAdmin";
import { useIsKiosk } from "./useIsKiosk";
import { useIsScheduler } from "./useIsScheduler";
import { useIsStaffApp } from "./useIsStaffApp";
import { useAppSelector } from "./useRedux";

/**
 * Custom hook that returns the current unit ID based on the HEADER.
 * @returns The current unit ID or undefined if not found.
 */
export const useCurrentUnitId = () => {
  const [unitId, setUnitId] = useState<string | null>(httpClient.getUnitId());
  const handleUnitIdChange = useCallback(() => {
    setUnitId(httpClient.getUnitId());
  }, []);

  useEffect(() => {
    httpClient.eventEmitter.on("unitIdChanged", handleUnitIdChange);

    return () => {
      httpClient.eventEmitter.removeListener("unitIdChanged", handleUnitIdChange);
    };
  }, [handleUnitIdChange]);

  return (unitId as IUnit["id"]) || undefined;
};

/**
 * Custom hook that returns the current unit ID based on the user's roles, selected unit, and other data.
 * if staff: will get the home unit.
 * if scheduler: will get the selected unit you are on.
 * @returns The current unit or undefined if not found.
 */
export const useCurrentSelectedUnitId = () => {
  return useCurrentUnit()?.id;
};

/**
 * Custom hook that returns the current unit based on the user's roles, selected unit, and other data.
 * if staff: will get the home unit.
 * if scheduler: will get the selected unit you are on.
 * @returns The current unit or undefined if not found.
 */
export const useCurrentUnit = () => {
  const selectedUnit = useAppSelector((state) => state.common.selectedUnit);
  const userIsStaff = useIsStaffApp();
  const userIsAdmin = useIsAdmin();
  const userIsKiosk = useIsKiosk();
  const userIsScheduler = useIsScheduler();
  const { data: config } = useAppConfigQuery();
  const { data: unitsFromQuery } = useListUnitsQuery({}, { skip: userIsStaff });
  const units = useMemo(
    () => [...(unitsFromQuery || []), ...(config?.units || [])],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config?.units, JSON.stringify(unitsFromQuery)],
  );
  const staffDetails = useStaffDetailsQuery(userIsStaff)?.data;

  // If admin or scheduler, just return the selected unit from state
  if (userIsAdmin || userIsScheduler || userIsKiosk) {
    // make sure to refresh unit from api to get latest or find unit from config which will have the latest
    // else could get stale data or empty config for example
    const unitFromConfig = units.find((unit) => unit.id === selectedUnit?.id);
    return unitFromConfig || undefined;
  }

  return (
    (staffDetails?.homeUnitId && units?.find(({ id }) => id === staffDetails.homeUnitId)) ||
    undefined
  );
};
