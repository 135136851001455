/* eslint-disable no-console */

import { memo } from "react";

import {
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToDelete,
  TRealTimeStaffingTargetToUpdate,
} from "~/api/realTimeStaffingTargets";
import { IUnitBasic } from "~/routes/api/types";

import { IStaffShift } from "@/api";

import { TableParts } from "..";

export const StaffingLevelsRows = memo(function MemoizedTableRows({
  unitStaffingLevels,
  hasNewEntry,
  shiftsByUnit,
}: {
  unitStaffingLevels: (
    | TRealTimeStaffingTargetToCreate
    | TRealTimeStaffingTargetToUpdate
    | TRealTimeStaffingTargetToDelete
  )[];
  hasNewEntry: boolean;
  shiftsByUnit?: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
}) {
  const unitStaffingLevelsToBeCreated: TRealTimeStaffingTargetToCreate[] =
    unitStaffingLevels.filter(
      (staffingLevel) => "unitId" in staffingLevel && !("id" in staffingLevel),
    );
  const unitStaffingLevelsToBeUpdatedOrDeleted: (
    | TRealTimeStaffingTargetToUpdate
    | TRealTimeStaffingTargetToDelete
  )[] = unitStaffingLevels.filter((staffingLevel) => "id" in staffingLevel) as (
    | TRealTimeStaffingTargetToUpdate
    | TRealTimeStaffingTargetToDelete
  )[];
  return (
    <>
      {hasNewEntry &&
        shiftsByUnit &&
        unitStaffingLevelsToBeCreated.map((unitStaffingLevel) => (
          <TableParts.NewEntryRow
            shiftsByUnit={shiftsByUnit}
            unitStaffingLevel={unitStaffingLevel}
          />
        ))}
      {unitStaffingLevelsToBeUpdatedOrDeleted.map((unitStaffingLevel) => (
        <TableParts.Row unitStaffingLevel={unitStaffingLevel} />
      ))}
    </>
  );
});
