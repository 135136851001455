import { useMemo } from "react";

import { useListSchedulesQuery } from "~/api";
import { useAppSelector } from "~/common/hooks/useRedux";

export const useSelectedSchedule = () => {
  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
  const selectedDate = useAppSelector((state) => state.houseView.pageFilters.selectedDate);
  const { data: schedules } = useListSchedulesQuery({});

  return useMemo(() => {
    if (!selectedUnitId || !selectedDate) return undefined;

    const unitSchedules = schedules?.filter((schedule) => schedule.unitId === selectedUnitId);
    return unitSchedules?.find(
      (schedule) => schedule.startDay <= selectedDate && selectedDate <= schedule.endDay,
    );
  }, [schedules, selectedUnitId, selectedDate]);
};
