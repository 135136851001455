import {
  EStaffTypeKey,
  EStaffTypeName,
  IDictionaryValue,
  IShiftTypeDictionaryValue,
  Timezone,
} from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { IPermissions, IStaffCategory, IStaffType, StaffCategory } from "@/api";

export interface ILoginData {
  login: string;
  password: string;
}

export enum ERoles {
  Admin = "admin",
  Scheduler = "scheduler",
  Staff = "staff",
  Kiosk = "kiosk",
}

export enum ERoleIds {
  admin = 1,
  scheduler = 2,
  staff = 3,
  kiosk = 4,
}

export interface IFacility {
  timezone: Timezone;
  id: string;
  name: string;
}

export interface IUnitEntity {
  id: string;
  name: string;
}

export interface IUser {
  email: string;
  emailVerified: boolean;
  firstName: string;
  id: string;
  roles: ERoles[];
  facilities: IFacility[];
  unitEntities: IUnitEntity[];
  lastName: string;
  phoneNumber: string;
  homeUnitId: string;
  homeUnitName: string;
  isSoftDeleted: boolean;
}

export interface IStaffDetail {
  chargeEligibility: boolean;
  contractEndDate: string;
  email: string;
  emailVerified: boolean;
  employmentStartDate: string;
  employmentType: string;
  experience: string;
  externalStaffIdentifier: string | null;
  firstName: string;
  id: string;
  isSoftDeleted: boolean;
  lastName: string;
  maxConsecutiveShifts: number;
  maxRequestsOffPerSchedule: number;
  maxShiftsPerWeek: number;
  minShiftsPerWeek: number;
  moreThanOneDayBetweenShifts: boolean;
  note: string;
  onOrientation: boolean;
  orientationEndDate: string | null;
  phoneNumber: string;
  preceptor: boolean;
  shiftType: string;
  staffTypeKey: EStaffTypeKey;
  staffTypeName: EStaffTypeName;
  status: string;
  totalShiftsRequiredPerSchedule: number;
  totalWeekendShiftsRequiredPerSchedule: number;
  userType: string;
}

export interface IUserState {
  userData: IUser;
  changeEmailModal: {
    isOpen: boolean;
  };
  currentRole: ERoles | undefined;
}

export interface IStaffPreferences {
  maxConsecutiveShifts: number;
  moreThanOneDayBetweenShifts: boolean;
}
export interface IUserEmailUpdate {
  email: string;
}

export interface IUnitConfig extends IUnitBasic {
  staffCategoryKeys: StaffCategory.EKey[];
}

export interface IAppConfig {
  staffCategories: IStaffCategory[];
  staffTypes: IStaffType[];
  employmentTypes: IDictionaryValue[];
  roles: IDictionaryValue[];
  shiftTypes: IShiftTypeDictionaryValue[];
  statuses: IDictionaryValue[];
  /** Unit you have explicit access to through rosters you are on */
  units: Array<IUnitConfig>;
  facilities: Array<IFacility>;
  /** All units of your facility OR across platform if admin */
  accessibleUnits: Array<IUnitConfig>;
  schedulerPermissions: IPermissions[];
  /** List of all admins only present if user is an admin */
  admins?: IUser[];
}
