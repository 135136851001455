/* eslint-disable no-console */

import { memo } from "react";

import { IUnitBasic } from "~/routes/api/types";

import { TableParts } from "..";

// eslint-disable-next-line  @typescript-eslint/naming-convention
export const _StaffingLevelsRows = memo(function MemoizedTableRows({
  units,
}: {
  units: IUnitBasic[];
}) {
  return (
    <>
      {units.map((unit) => (
        <TableParts.Row unit={unit} />
      ))}
    </>
  );
});
