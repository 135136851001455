import { useEffect, useMemo } from "react";

import { THouseViewTimeRange } from "@m7-health/shared-utils";
import { groupBy, isEqual } from "lodash";

import {
  RealTimeStaffingTarget,
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToUpdate,
  useListRealTimeStaffingTargetsQuery,
} from "~/api/realTimeStaffingTargets";

import { T_24_HOURS_IN_SECONDS } from "#/features/AdminPanel/constants";
import { useAppConfigQuery } from "#/features/User/queries";
import { useAppDispatch, useAppFlags, useAppSelector } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { dateString } from "@/common/types";
import { timeAdd, trimMs } from "@/common/utils/dates";

import { houseViewStore } from "../store";
import { DEFAULT_CUSTOM_TIME_RANGE } from "../store/pageFiltersActions";

export const emptyStaffingTargetsArray: RealTimeStaffingTarget.DTO[] = [];

const dateStringInRange = (selectedTimeRange: THouseViewTimeRange, dateStringToCheck: string) => {
  const time = localDayJs(dateStringToCheck).format("HH:mm:ss");
  const startTimeNoMs = trimMs(selectedTimeRange.startTime);
  const endTimeNoMs = trimMs(selectedTimeRange.endTime);
  const endTimeIfBeforeStart =
    endTimeNoMs < startTimeNoMs ? trimMs(timeAdd(endTimeNoMs, T_24_HOURS_IN_SECONDS)) : endTimeNoMs;

  return startTimeNoMs <= time && time <= endTimeIfBeforeStart;
};

/** Set staffing targets data for the whole house view page
 * Fetches and set data in the store:
 * - staffingTargets
 */
export const useSetStaffingTargets = () => {
  // High -level
  const dispatch = useAppDispatch();
  const { newStaffingTargetsModal } = useAppFlags();

  // States
  const {
    selectedFacilityId,
    selectedDate,
    selectedCustomTimeRangeState,
    isStaffingTargetLevelModalOpen,
  } = useAppSelector(
    ({ houseView: { pageFilters, staffingLevels } }) => ({
      selectedFacilityId: pageFilters.selectedFacilityId!,
      selectedDate: pageFilters.selectedDate!,
      selectedCustomTimeRangeState: pageFilters.customTimeRange,
      isStaffingTargetLevelModalOpen: staffingLevels.modalIsOpen!,
    }),
    isEqual,
  );
  const selectedCustomTimeRange = selectedCustomTimeRangeState || DEFAULT_CUSTOM_TIME_RANGE;

  const unitIds = useAppConfigQuery()
    .data?.accessibleUnits.filter((unit) => unit.facilityId === selectedFacilityId)
    .map((unit) => unit.id);

  // get the time range
  const startTime =
    selectedDate &&
    localDayJs(selectedDate)
      .add(parseInt(selectedCustomTimeRange.startTime.split(":")[0] || ""), "hours")
      .toISOString();

  const endTime =
    selectedDate &&
    selectedCustomTimeRange &&
    localDayJs(selectedDate)
      .add(parseInt(selectedCustomTimeRange.endTime.split(":")[0] || ""), "hours")
      .toISOString();

  /** All queries */
  const { data: staffingTargets, isLoading: staffingTargetsAreLoading } =
    useListRealTimeStaffingTargetsQuery(
      {
        unitIds: unitIds,
        latest: false,
        date: [
          {
            value: startTime,
            operator: "gte",
          },
          {
            value: endTime,
            operator: "lt",
          },
        ],
      },
      { skip: !newStaffingTargetsModal || !startTime || !endTime },
    );

  // Dispatch staffing targets to the store
  // Do it whenever staffing targets change OR whenever the modal is open or closed OR whenever the time range changes
  useEffect(() => {
    // Aggregate staffing targets by unit
    if (!staffingTargetsAreLoading) {
      const aggregatedTargets: Record<
        string,
        (TRealTimeStaffingTargetToCreate | TRealTimeStaffingTargetToUpdate)[]
      > = groupBy(staffingTargets, "unitId");

      // if the modal is open, also create a new default staffing target for each unit at the current time
      if (isStaffingTargetLevelModalOpen) {
        const currentTime = localDayJs().format("HH:mm:ss");
        // if the current time is within the selected time range, use it, otherwise use the start time
        const timeToUse = dateStringInRange(selectedCustomTimeRange, currentTime)
          ? currentTime
          : selectedCustomTimeRange.startTime;
        unitIds?.forEach((unitId) => {
          if (!aggregatedTargets[unitId]) {
            aggregatedTargets[unitId] = [];
          }
          aggregatedTargets[unitId]?.push({
            unitId: unitId,
            // Default value (the 0th element is the latest)
            // -1 will not be shown
            patientCount: aggregatedTargets[unitId]?.[0]?.patientCount || -1,
            date: `${selectedDate}T${timeToUse}` as dateString,
          } as TRealTimeStaffingTargetToCreate);
        });
      }
      dispatch(houseViewStore.state.setAllStaffingTargets(aggregatedTargets));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    dispatch,
    isStaffingTargetLevelModalOpen,
    JSON.stringify(unitIds),
    selectedCustomTimeRangeState,
    staffingTargetsAreLoading,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // change when staffing targets are loaded
  // also needs to be reloaded when close the new staffing target modal
  return useMemo(() => {
    return {
      staffingTargets,
      loading: staffingTargetsAreLoading,
    };
  }, [staffingTargets, staffingTargetsAreLoading]);
};
