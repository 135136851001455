import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { NextUIProvider } from "@nextui-org/system";
import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";
import { entries } from "lodash";
import { PersistGate } from "redux-persist/integration/react";

import { ThemeProvider } from "@mui/material";

import { httpClient } from "~/common/packages/httpClient";
// eslint-disable-next-line deprecate/import -- It's ok to use it here
import { useAnalytics } from "~/common/utils/analytics";

import "normalize.css";
import "./common/theming/colors.scss";

import "react-toastify/dist/ReactToastify.css";
import "~/common/theming/global.css";
import ReloadPromptModal from "./common/components/ReloadPromptModal/ReloadPromptModal";
import { useM7SimpleContext, useUser } from "./common/hooks";
import { useAppSelector } from "./common/hooks/useRedux";
import { theme } from "./common/theming";
import { useLaunchDarkly } from "./common/utils/useLaunchDarkly";
import router from "./routes";
import { AppWideModalContainer } from "./routes/components/AppWideModalContainer/AppWideModalContainer";
import { useSetCurrentRole } from "./routes/hooks";
import { useResetQueriesOnM7HeadersChange } from "./routes/hooks/useResetQueriesOnM7HeadersChange";
import { persistor } from "./store";

const App = () => {
  useLaunchDarkly();
  const analytics = useAnalytics();
  const { getAccessTokenSilently, error: auth0Error, loginWithRedirect } = useAuth0();

  const isRefreshTokenError = (error: Error) => {
    return (
      error?.message?.toLowerCase().includes("missing refresh token") ||
      error?.message?.toLowerCase().includes("invalid refresh token")
    );
  };

  useResetQueriesOnM7HeadersChange();

  useEffect(() => {
    if (!auth0Error) return;
    // If we receive a refresh token error, just try to login again
    if (isRefreshTokenError(auth0Error)) {
      Sentry.addBreadcrumb({
        category: "auth0",
        message: auth0Error.message,
      });
      loginWithRedirect().catch((e) => {
        // Only capture exception if it's not related to refresh token
        if (!isRefreshTokenError(auth0Error)) {
          captureException(e);
        }
      });
    } else captureException(auth0Error);
  }, [auth0Error, loginWithRedirect]);

  useUser();
  useSetCurrentRole();

  const currentRole = useAppSelector((state) => state.user.currentRole);

  useEffect(() => {
    httpClient.setTokenRetriever(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  // Retrieve user data from redux store
  const {
    id,
    email,
    firstName,
    lastName,
    roles,
    facilities,
    unitEntities: units,
    homeUnitId,
    homeUnitName,
  } = useAppSelector((state) => state.user.userData);

  // Set Sentry and analytics user context
  useEffect(() => {
    if (!!id && currentRole) {
      analytics?.identify(id, {
        $email: email,
        roles: roles,
        facilities: facilities?.map((f) => f.name),
        units: units?.map((u) => u.name),
        homeUnitId: homeUnitId,
        homeUnitName: homeUnitName,
      });
      Sentry.setUser({
        id,
        email,
        roles,
        facilities: facilities?.map((f) => f.name),
        units: units?.map((u) => u.name),
        analyticsDistinctId: analytics?.getDistinctId() as string,
        homeUnitId: homeUnitId,
        homeUnitName: homeUnitName,
      });
      datadogRum.setUser({
        id,
        email,
        name: `${firstName} ${lastName}`,
        roles,
        facilities: facilities?.map((f) => f.name),
        units: units?.map((u) => u.name),
        homeUnitId: homeUnitId,
        homeUnitName: homeUnitName,
        currentRole,
      });
    }
  }, [
    id,
    email,
    roles,
    facilities,
    units,
    analytics,
    firstName,
    lastName,
    homeUnitId,
    homeUnitName,
    currentRole,
  ]);

  const m7Context = useM7SimpleContext();
  // when m7 context changes, re-set sentry tags
  useEffect(() => {
    entries(m7Context).forEach(([key, value]) => {
      Sentry.setTag(key, value);
      datadogRum.setGlobalContextProperty(key, value);
    });
  }, [m7Context]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <NextUIProvider>
          <RouterProvider router={router} />
          <ToastContainer closeOnClick />
          <AppWideModalContainer />
          <ReloadPromptModal />
        </NextUIProvider>
      </ThemeProvider>
    </PersistGate>
  );
};

export default App;
