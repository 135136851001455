import { useEffect, useRef, useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { Sms } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";

import { StaffDetails } from "~/api/staffDetails/types";
import CustomModal from "~/common/components/Modal";
import CustomSelect from "~/common/components/TrackedComponents/Select";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { emptySx, EStaffCategoryName, EStaffTypeName, TSx } from "~/common/types";
import { sendMassSmsApi } from "~/features/Roster/api";
import { useRosterQueryAll } from "~/features/Roster/queries";
import { setMassSendSmsModalOpen } from "~/features/Roster/store";
import { EStaffStatus, TRosterItem } from "~/features/Roster/types";
import { useStaffCategoriesTargetLevelQuery, useStaffTypesAndCategories } from "~/routes/queries";

import { ERosterUserOptions } from "#/features/Roster/RosterPageDeprecated/RosterTable/RosterTable";
import { useIsAdmin, useIsScheduler } from "@/common/hooks";

import CustomButton from "../TrackedComponents/Button";

const Button = ({ sx = emptySx }: { sx?: TSx }) => {
  const dispatch = useAppDispatch();

  const isAdmin = useIsAdmin();
  const isScheduler = useIsScheduler();

  const hasMassSendSmsPermission = isAdmin || isScheduler;
  const handleMassSendSmsModal = () => {
    dispatch(setMassSendSmsModalOpen(true));
  };

  if (!hasMassSendSmsPermission) return <></>;

  return (
    <CustomButton
      sx={{ width: "150px", ...sx }}
      label="Send Unit SMS"
      onClick={handleMassSendSmsModal}
      startIcon={<Sms />}
    />
  );
};

const Modal = () => {
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess, showWarning } = useToast();

  const selectedUnit = useAppSelector((state) => state.common.selectedUnit);
  const isOpen = useAppSelector((state) => state.roster.massSendSmsModal.isOpen);
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: sendMassSmsApi,
    onSuccess: (data) => {
      dispatch(setMassSendSmsModalOpen(false));
      // Check errors array
      const errors = data.errors;
      if (errors.length) {
        showWarning(
          `SMS sent. The message failed to be delivered to ${errors.length} phone number${errors.length > 1 ? "s" : ""}.`,
        );
      } else {
        showSuccess("SMS sent successfully");
      }
      message.current = "";
    },
    onError: handleErrors,
  });

  const [selectedCategories, setSelectedCategories] = useState<EStaffCategoryName[]>([]);
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<
    StaffDetails.EEmploymentType[]
  >([]);
  const [filteredUsers, setFilteredUsers] = useState<TRosterItem[]>([]);
  const [activeUsers, setActiveUsers] = useState<TRosterItem[]>([]);
  const [contentIsEmpty, setContentIsEmpty] = useState(true);
  const message = useRef("");
  const suspended = [false];
  const { data: { data: users = [] } = {} } = useRosterQueryAll(suspended, ERosterUserOptions.home);

  // Dropdown to select users by type
  const { data: allDisplayableCategories = [] } = useStaffCategoriesTargetLevelQuery();
  const { data: staffTypesCategoriesHashMap = {} } = useStaffTypesAndCategories();
  const nonZeroCategories = activeUsers.reduce(
    (acc, user) => {
      if ("staffTypeName" in user && user.staffTypeName) {
        const mappedCategory = staffTypesCategoriesHashMap[user.staffTypeName];
        if (mappedCategory) {
          acc[mappedCategory] = true;
        }
      }
      return acc;
    },
    {} as Record<EStaffTypeName | EStaffCategoryName, boolean>,
  );
  const categoryItems = allDisplayableCategories
    .filter((category) => nonZeroCategories[category.name])
    .map((category) => ({
      value: category.name,
      label: category.name,
      item: category.name,
    }));

  const employmentTypeItems = Object.values(StaffDetails.EEmploymentType).map((employmentType) => ({
    value: employmentType,
    label: employmentType,
    item: employmentType,
  }));
  const activeUserItems = activeUsers
    .map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}, ${"staffTypeName" in user ? user.staffTypeName : ""}`,
      item: `${user.firstName} ${user.lastName}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    setActiveUsers(users.filter((user) => "status" in user && user.status === EStaffStatus.Active));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(users.map((user) => "status" in user && user.status === EStaffStatus.Active)),
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setFilteredUsers(
      activeUsers.filter((user) => {
        if ("staffTypeName" in user && user.staffTypeName) {
          const mappedCategory = staffTypesCategoriesHashMap[user.staffTypeName];
          const employmentType = user.employmentType;
          return (
            mappedCategory &&
            selectedCategories.includes(mappedCategory as EStaffCategoryName) &&
            selectedEmploymentTypes.includes(employmentType)
          );
        }
        return false;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategories,
    users,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(staffTypesCategoriesHashMap),
    selectedEmploymentTypes,
  ]);

  const confirmSending = () => {
    if (
      window.confirm(
        `You are about to send an SMS to ${
          filteredUsers.length
        } staff members.\n${selectedCategories.join(", ")}`,
      )
    ) {
      const userIds = filteredUsers.map((user) => user.id);
      mutate({
        userIds: userIds,
        messageContent: message.current,
      });
    }
  };

  const handleSecondaryBtnClick = () => {
    dispatch(setMassSendSmsModalOpen(false));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      withBlurBackground={false}
      sx={{ overflowY: "auto" }}
      primaryBtnText="Send SMS"
      modalHeaderText={"Send SMS to Staff"}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmSending}
      primaryDisabled={isLoading || filteredUsers.length === 0 || contentIsEmpty}
      variant="warning"
      variantText={
        <>
          You are about to send an SMS to
          <u>
            <b> {filteredUsers.length} </b>
          </u>{" "}
          staff members.
          <br />
          <i style={{ fontWeight: "400" }}>
            Text will be sent from {selectedUnit?.facility?.twilioPhoneNumber}
          </i>
        </>
      }
      modalContent={
        <Box mt={3}>
          <CustomSelect<EStaffCategoryName>
            multiple
            checked
            items={categoryItems}
            label="Select category"
            value={selectedCategories}
            onChange={(e) => {
              const values = (
                Array.isArray(e.target.value) ? e.target.value : [e.target.value]
              ) as EStaffCategoryName[];
              setSelectedCategories(values);
            }}
            sx={{ mb: 3 }}
          />
          <CustomSelect<StaffDetails.EEmploymentType>
            multiple
            checked
            items={employmentTypeItems}
            label="Select employment type"
            value={selectedEmploymentTypes}
            onChange={(e) => {
              const values = (
                Array.isArray(e.target.value) ? e.target.value : [e.target.value]
              ) as StaffDetails.EEmploymentType[];
              setSelectedEmploymentTypes(values);
            }}
            sx={{ mb: 3 }}
          />
          <Typography sx={{ mb: 2 }}>Select or deselect receiving staff to include:</Typography>
          <Box maxWidth={"500px"}>
            <CustomSelect<string>
              multiple
              checked
              customDisplayOption={`${filteredUsers.length} Users Selected`}
              items={activeUserItems}
              label="Receiving Staff"
              value={filteredUsers.map((user) => user.id)}
              onChange={(e) => {
                const values = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
                const newFilteredUsers = activeUsers.filter((user) => values.includes(user.id));
                setFilteredUsers(newFilteredUsers);
              }}
              sx={{ mb: 3 }}
            />
          </Box>

          <TextField
            fullWidth
            multiline
            onChange={(e) => {
              message.current = e.target.value;
              setContentIsEmpty(e.target.value === "");
            }}
            placeholder="Enter your message here"
            minRows={8}
          />
        </Box>
      }
    />
  );
};

export const SendMassSms = {
  Button,
  Modal,
};
