import { useMemo } from "react";

import { ERoles } from "~/features/User/types";

import { useAppSelector } from "./useRedux";

const schedulerAppRoles = [ERoles.Scheduler, ERoles.Admin, ERoles.Kiosk];

export const useIsSchedulerApp = (): boolean => {
  const curentRole = useAppSelector((state) => state.user.currentRole);

  return useMemo(() => {
    return !!curentRole && schedulerAppRoles.includes(curentRole);
  }, [curentRole]);
};
