import { Stack, Typography } from "@mui/material";

import { StaffShiftCreated, StaffShiftDeleted, StaffShiftUpdated } from "~/api/eventLog/events";

import { useAppConfigQuery } from "#/features/User/queries";
import { IScheduleShiftType, IStaffShift, IUnit, IUser } from "@/api";
import { AttributeTag } from "@/common/components/AttributeTag/AttributeTag";
import { NoteUpdateTag } from "@/common/components/NoteUpdateTag/NoteUpdateTag";
import { ShiftV2 } from "@/common/components/Shift/ShiftV2";
import { useCurrentTimezone, useKeyBy } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { darkGray } from "@/common/theming/colors";
import { KeyBy } from "@/common/types";
import { TimeStringToStandardTime, timeAdd } from "@/common/utils/dates";

const shiftOriginCopy: {
  [key in
    | StaffShiftCreated.StaffShiftOrigin["type"]
    | StaffShiftUpdated.StaffShiftOrigin["type"]
    | StaffShiftDeleted.StaffShiftOrigin["type"]]: string;
} = {
  "approved-open-shift-request": "Open Shift approved",
  "bulk-save-action": "Manual update",
  "approved-drop-shift-request": "Drop Shift approved",
  "approved-time-off-request": "Time off approved",
  "approved-swap-shift-request": "Swap Shift approved",
  "deactivated-staff": "Deactivated Staff",
};
const linkWord = (word: string, first = false) => (
  <Typography sx={{ pr: 1, pl: first ? 0 : 1, fontSize: "inherit" }} children={word} />
);

export const ShiftDetails = ({
  staffShift,
  origin,
  unitId,
}: {
  staffShift?: Partial<
    Pick<IStaffShift, "customDuration" | "customStartTime" | "status" | "attributes" | "scheduleId">
  >;
  origin: StaffShiftCreated.StaffShiftOrigin;
  unitId?: IUnit["id"];
}) => {
  const attributes = useAppConfigQuery().data?.units.find((unit) => unit.id === unitId)?.attributes;
  const attributesByKey = useKeyBy(attributes, "key");
  const originDetails = shiftOriginCopy[origin.type];

  // Display from where the shift originated (approved OPS, manual update, etc.)
  let originRendered: string | undefined = undefined;
  if (originDetails) originRendered = originDetails;

  // Display if the shift is a partial shift
  let customRange: string | undefined = undefined;
  if (staffShift?.customDuration && staffShift?.customStartTime)
    customRange = `${TimeStringToStandardTime(staffShift.customStartTime, "medium")} - ${TimeStringToStandardTime(timeAdd(staffShift.customStartTime, staffShift.customDuration))}`;

  // Display the status of the shift (on call, canceled, etc.)
  let shiftStatus: JSX.Element | undefined = undefined;
  if (staffShift?.status)
    shiftStatus = (
      <NoteUpdateTag sx={{ ml: 0.5, px: 0.5 }} update={staffShift.status} unitId={unitId} />
    );

  // Display the attributes of the shift (eg: "charge")
  let shiftAttributes: JSX.Element | undefined = undefined;
  if (staffShift?.attributes?.length)
    shiftAttributes = (
      <>
        {staffShift.attributes.map((key) => {
          const attribute = attributesByKey[key];
          if (!attribute) return "";

          return (
            <AttributeTag
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              attribute={attribute}
            />
          );
        })}
      </>
    );

  // Return everything in a stack, with `{title}: {content}` format
  return (
    <Stack direction={"column"} gap={0.4} mt={0.6}>
      {contentLine("Partial Shift", customRange)}
      {contentLine("Status", shiftStatus)}
      {contentLine("Attributes", shiftAttributes)}
      {contentLine("Origin", originRendered)}
    </Stack>
  );
};

const contentLine = (title: string, content?: JSX.Element | string) =>
  content && (
    <Stack direction={"row"} gap={0.3}>
      <Typography color={darkGray} fontSize="12px" display="inherit" alignItems={"center"}>
        <i>{title}</i> :{" "}
      </Typography>
      <Typography fontSize="12px" display="inherit" alignItems={"center"}>
        {content}
      </Typography>
    </Stack>
  );

export const ShiftDetailsDifferences = ({
  differences,
  origin,
  unitId,
  indexedShiftTypes,
  unitIdByScheduleId,
  userById,
  staffShift,
}: {
  differences: StaffShiftUpdated.Differences;
  origin: StaffShiftUpdated.StaffShiftOrigin;
  unitId?: IUnit["id"];
  indexedShiftTypes?: Record<IScheduleShiftType["id"], KeyBy<IScheduleShiftType, "key">>;
  unitIdByScheduleId?: Record<string, string>;
  userById?: KeyBy<IUser, "id">;
  staffShift: Partial<
    Pick<
      IStaffShift,
      "customDuration" | "customStartTime" | "status" | "attributes" | "scheduleId" | "shiftTypeKey"
    >
  >;
}) => {
  const currentTz = useCurrentTimezone();
  const units = useAppConfigQuery().data?.units;
  const unitById = useKeyBy(units, "id");
  const attributes = (unitId && unitById[unitId]?.attributes) || [];
  const attributesByKey = useKeyBy(attributes, "key");
  const originDetails = shiftOriginCopy[origin.type];

  // Display from where the shift originated (approved OPS, manual update, etc.)
  let originRendered: string | undefined = undefined;
  if (originDetails) originRendered = originDetails;

  // Display if custom range changed
  let customRange: string | undefined = undefined;
  if (differences.customDuration || differences.customStartTime) {
    // When there is no from, it means it was a regular shift
    // When there is at least one, maybe only duration changed
    const fromNotCustom = !differences.customDuration?.from && !differences.customStartTime?.from;
    const toNotCustom = !differences.customDuration?.to && !differences.customStartTime?.to;

    const scheduleId = staffShift.scheduleId;
    const scheduleShiftTypesByKey = (scheduleId && indexedShiftTypes?.[scheduleId]) || {};
    const fromShiftTypeKey = differences.shiftTypeKey?.from || staffShift.shiftTypeKey;
    const fromShiftType = fromShiftTypeKey ? scheduleShiftTypesByKey?.[fromShiftTypeKey] : null;
    const toShiftTypeKey = differences.shiftTypeKey?.to || staffShift.shiftTypeKey;
    const toShiftType = toShiftTypeKey ? scheduleShiftTypesByKey?.[toShiftTypeKey] : null;

    const fromStart =
      (fromNotCustom && fromShiftType?.startTime) ||
      differences.customStartTime?.from ||
      staffShift.customStartTime;
    const fromDuration =
      (fromNotCustom && fromShiftType?.durationSeconds) ||
      differences.customDuration?.from ||
      staffShift.customDuration;
    const fromEnd = fromDuration && fromStart && timeAdd(fromStart, fromDuration);

    const toStart =
      (toNotCustom && toShiftType?.startTime) ||
      differences.customStartTime?.to ||
      staffShift.customStartTime;
    const toDuration =
      (toNotCustom && toShiftType?.durationSeconds) ||
      differences.customDuration?.to ||
      staffShift.customDuration;
    const toEnd = toDuration && toStart && timeAdd(toStart, toDuration);

    const from =
      fromStart && fromEnd
        ? `${TimeStringToStandardTime(fromStart, "medium")} - ${TimeStringToStandardTime(fromEnd)}`
        : null;
    const to =
      toStart && toEnd
        ? `${TimeStringToStandardTime(toStart, "medium")} - ${TimeStringToStandardTime(toEnd)}`
        : null;

    if (!from && to) customRange = `from regular shift to partial shift: [${to}]`;
    else if (!to && from) customRange = `from partial shift [${from}] to regular shift`;
    else if (from && to) customRange = `from [${from}] to [${to}]`;
  }

  // Display the status changed
  let shiftStatus: JSX.Element | undefined = undefined;
  if (differences.status) {
    const from = differences.status.from ? (
      <NoteUpdateTag sx={{ ml: 0.5, px: 0.5 }} update={differences.status.from} unitId={unitId} />
    ) : (
      <i>No status</i>
    );
    const to = differences.status.to ? (
      <NoteUpdateTag sx={{ ml: 0.5, px: 0.5 }} update={differences.status.to} unitId={unitId} />
    ) : (
      <i>No status</i>
    );

    shiftStatus = (
      <>
        {linkWord("from", true)} {from} {linkWord("to")} {to}
      </>
    );
  }

  // Display the attributes changed
  let shiftAttributes: JSX.Element | undefined = undefined;
  if (differences.attributes) {
    const from = (
      <>
        {differences.attributes.from[0] ? (
          differences.attributes.from.map((key) => {
            const attribute = attributesByKey[key];
            if (!attribute) return "";

            return (
              <AttributeTag
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                attribute={attribute}
              />
            );
          })
        ) : (
          <i>No attributes</i>
        )}
      </>
    );

    const to = (
      <>
        {differences.attributes.to[0] ? (
          differences.attributes.to.map((key) => {
            const attribute = attributesByKey[key];
            if (!attribute) return "";

            return (
              <AttributeTag
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                attribute={attribute}
              />
            );
          })
        ) : (
          <i>No attributes</i>
        )}
      </>
    );

    shiftAttributes = (
      <>
        {linkWord("from", true)} {from} {linkWord("to")} {to}
      </>
    );
  }

  // display if shift type changed
  let shiftType: JSX.Element | undefined = undefined;
  if (differences.shiftTypeKey) {
    const scheduleId = staffShift.scheduleId;
    const scheduleShiftTypesByKey = (scheduleId && indexedShiftTypes?.[scheduleId]) || {};

    const fromShiftType = scheduleShiftTypesByKey?.[differences.shiftTypeKey.from];
    const toShiftType = scheduleShiftTypesByKey?.[differences.shiftTypeKey.to];

    const from = fromShiftType ? <ShiftV2 variant="small" shiftType={fromShiftType} /> : <></>;
    const to = toShiftType ? <ShiftV2 variant="small" shiftType={toShiftType} /> : <></>;

    shiftType = (
      <>
        {linkWord("from", true)} {from} {linkWord("shift to")} {to} {linkWord("shift")}
      </>
    );
  }

  // display if unit changed
  let unit: string | undefined = undefined;
  if (differences.scheduleId) {
    const fromUnitId = unitIdByScheduleId?.[differences.scheduleId.from];
    const toUnitId = unitIdByScheduleId?.[differences.scheduleId.to];

    const fromUnit = (fromUnitId && unitById[fromUnitId]?.name) || "";
    const toUnit = (toUnitId && unitById[toUnitId]?.name) || "";

    unit = `from unit ${fromUnit} to unit ${toUnit}`;
  }

  // display if date changed
  let date: string | undefined = undefined;
  if (differences.date) {
    const from = differences.date.from;
    const to = differences.date.to;

    const fromFormatted = localDayJs.tz(from, currentTz).format("MM-DD-YYYY");
    const toFormatted = localDayJs.tz(to, currentTz).format("MM-DD-YYYY");

    date = `from ${fromFormatted} to ${toFormatted}`;
  }

  // display if staff changed
  let staff: string | undefined = undefined;
  if (differences.staffId) {
    const fromStaff = differences.staffId.from ? userById?.[differences.staffId.from] : null;
    const toStaff = differences.staffId.to ? userById?.[differences.staffId.to] : null;

    const from = fromStaff ? `${fromStaff.firstName} ${fromStaff.lastName}` : "No staff";
    const to = toStaff ? `${toStaff.firstName} ${toStaff.lastName}` : "No staff";

    staff = `transferred from ${from} to ${to}`;
  }

  // Return everything in a stack, with `{title}: {content}` format
  return (
    <Stack direction={"column"} gap={0.4} mt={0.6}>
      {updatedContentLine(date)}
      {updatedContentLine(unit)}
      {updatedContentLine(shiftAttributes)}
      {updatedContentLine(customRange)}
      {updatedContentLine(shiftType)}
      {updatedContentLine(staff)}
      {updatedContentLine(shiftStatus)}
      {contentLine("Origin", originRendered)}
    </Stack>
  );
};

const updatedContentLine = (content?: JSX.Element | string) =>
  content && (
    <Stack direction={"row"} gap={0.3}>
      <Typography fontSize="12px" display="inherit" alignItems={"center"}>
        {content}
      </Typography>
    </Stack>
  );
