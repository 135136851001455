import { useCallback } from "react";

import { EPermissionVerbs, EUnitPermissionAreas } from "@m7-health/shared-utils";

import {
  ADMIN_PERMISSIONS,
  KIOSK_PERMISSIONS,
  PERMISSION_ALLOW_ALL,
  STAFF_PERMISSIONS,
  TSingularPermission,
} from "~/routes/components/ProtectedRoute/types";

import { NOT_EXISTING_UUID } from "../constants";
import { TMaybeArray } from "../types";

import { useAppFlags } from "./useAppFlags";
import { useCurrentUnitId } from "./useCurrentUnitId";
import { useIsAdmin } from "./useIsAdmin";
import { useIsKiosk } from "./useIsKiosk";
import { useIsStaffApp } from "./useIsStaffApp";
import { useCurrentUserPermissions } from "./useUser";

const groupedVerbs = {
  read: [EPermissionVerbs.readOnly, EPermissionVerbs.manage],
  manage: [EPermissionVerbs.manage],
};

export type TCheckUserPermission = [
  action: keyof typeof groupedVerbs,
  areas: TMaybeArray<EUnitPermissionAreas | TSingularPermission> | undefined,
  options?: {
    unitId?: string;
  },
];

/**
 * Custom hook to check one user permissions based on roles and specific areas.
 * It's the preferred way to check permissions if you only need to check one permission.
 * @returns boolean indicating whether the user has the required permission.
 */
export const useCheckUserPermission = (...params: TCheckUserPermission) => {
  const checkUserPermissions = useCheckUserPermissions();
  return checkUserPermissions(...(params as TCheckUserPermission));
};

/**
 * Custom hook to check user permissions based on roles and specific areas.
 * @returns A callback function to check user permissions.
 */
export const useCheckUserPermissions = (parentOptions?: TCheckUserPermission[2]) => {
  const { showUserPermissions } = useAppFlags();
  const currentUnitId = useCurrentUnitId();
  const userIsAdmin = useIsAdmin();
  const userIsKiosk = useIsKiosk();
  const userIsStaff = useIsStaffApp();
  const { permissionsDataByUnit, schedulerRosterPermission, houseViewPermission } =
    useCurrentUserPermissions();

  return useCallback(
    /**
     * Check if the user has permission for a specific action and area(s).
     * @param action - The type of action to check (e.g., 'read' or 'manage').
     * @param areas - The area(s) to check permissions for.
     * @param options - Optional parameters, including a specific unit ID.
     * @returns A boolean indicating whether the user has the required permission.
     */
    (...params: TCheckUserPermission) => {
      const [action, areas, options] = params;

      // If user permissions are not enabled, allow all actions
      if (!showUserPermissions) return true;
      // If no areas are specified, deny permission
      if (!areas) return false;
      // Admins have all permissions
      if (userIsAdmin) return true;

      const verbs = groupedVerbs[action];

      // Check permissions for each area
      // If any area has the required permission, return true
      return [areas].flat().some((area) => {
        switch (area) {
          case PERMISSION_ALLOW_ALL:
            return true;
          case STAFF_PERMISSIONS:
            return userIsStaff;
          case ADMIN_PERMISSIONS:
            return userIsAdmin;
          case KIOSK_PERMISSIONS:
            return userIsKiosk;
          case EUnitPermissionAreas.houseView:
            if (!houseViewPermission) return false;
            // return true if the user has the required permission
            return verbs.includes(houseViewPermission);
          case EUnitPermissionAreas.schedulerRoster:
            if (!schedulerRosterPermission) return false;
            return verbs.includes(schedulerRosterPermission);
        }

        // Default: regular permission, as non-admin/non-staff user
        const unitId =
          options?.unitId || parentOptions?.unitId || currentUnitId || NOT_EXISTING_UUID;
        const selectedUnitPermissions = permissionsDataByUnit[unitId] || {};
        const selectedAreaPermission = selectedUnitPermissions[area];
        if (!selectedAreaPermission) return false;

        return verbs.includes(selectedAreaPermission);
      });
    },
    [
      currentUnitId,
      houseViewPermission,
      parentOptions?.unitId,
      permissionsDataByUnit,
      schedulerRosterPermission,
      showUserPermissions,
      userIsAdmin,
      userIsKiosk,
      userIsStaff,
    ],
  );
};
