/* eslint-disable no-console */

import { memo } from "react";

import { Box } from "@mui/material";

import { IUnitBasic } from "~/routes/api/types";

import { TableStyleContainer } from "./style";

import { TableParts } from ".";

export const Table = memo(({ units }: { units: IUnitBasic[] }) => {
  return (
    <Box
      mt={3}
      maxHeight="80vh"
      marginBottom="60px"
      boxSizing="border-box"
      display="flex"
      overflow={"auto"}
      flexDirection="column"
    >
      <TableStyleContainer>
        <table
          className={`scheduler-table regular-view v2`}
          style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
        >
          <tbody>
            <TableParts.HeaderRow />
            <TableParts.Rows units={units} />
          </tbody>
        </table>
      </TableStyleContainer>
    </Box>
  );
});
