import { PayloadAction } from "@reduxjs/toolkit";

import { IStaffShift, StaffCategory } from "~/api";

import { EHVTimeRange } from "./pageFiltersActions";

import { THouseViewState, initialState } from ".";

// Make sure anything else than 'Other' matches StaffDetails.EmploymentType
export enum EHVEmploymentTypeFilter {
  perDiem = "Per diem",
  travelStaff = "Travel staff",
  other = "Other",
}
export type FindStaffToWork = {
  staffShift?: Partial<
    Pick<IStaffShift, "shiftTypeKey" | "staffId" | "customStartTime" | "customDuration">
  > | null;
  staffShiftsToText?: {
    [staffId: string]: Partial<
      Pick<IStaffShift, "shiftTypeKey" | "staffId" | "customStartTime" | "customDuration">
    >;
  } | null;
  confirmModalIsOpen?: boolean;
  isLookingToText?: boolean;
  filters?: {
    preferredWorkingHours?: EHVTimeRange[];
    shiftTypeKeys?: (string | null)[];
    employmentTypes?: EHVEmploymentTypeFilter[];
    unit?: ("HOME" | "OTHER")[];
    hideOvertime?: boolean;
    modalIsOpen?: boolean;
    staffCategories?: StaffCategory.EKey[];
  };
};

export const FindStaffToWorkActions = {
  startFindStaffToWork: ({ sidebarCurrentAction }: THouseViewState) => {
    sidebarCurrentAction.inProgress = "findStaffToWork";
  },
  /** Takes OR a new value, or a callback called with the current value as
   *   argument and returning the new value
   */
  setFindStaffToWorkFilters: (
    { findStaffToWork }: THouseViewState,
    action: PayloadAction<
      | FindStaffToWork["filters"]
      | ((filters: FindStaffToWork["filters"]) => FindStaffToWork["filters"])
    >,
  ) => {
    if (action.payload instanceof Function)
      findStaffToWork.filters = action.payload(findStaffToWork.filters);
    else findStaffToWork.filters = action.payload;
  },
  setFoundStaffShift: (
    { findStaffToWork, sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<Partial<IStaffShift> | null>,
  ) => {
    if (action.payload) {
      sidebarCurrentAction.isDirty = true;
      findStaffToWork.staffShift = {
        ...(findStaffToWork.staffShift || {}),
        ...action.payload,
      };
    } else {
      findStaffToWork.staffShift = null;
      sidebarCurrentAction.isDirty = false;
    }
  },
  setFoundStaffShiftToText: (
    { findStaffToWork, sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<Partial<IStaffShift> | null>,
  ) => {
    const actionShift = action.payload;
    if (actionShift) {
      sidebarCurrentAction.isDirty = true;
      findStaffToWork.staffShiftsToText ||= {};
      const possibleExistingShift = findStaffToWork.staffShiftsToText?.[actionShift?.staffId || ""];
      if (possibleExistingShift) {
        delete findStaffToWork.staffShiftsToText[actionShift.staffId || ""];
      } else {
        findStaffToWork.staffShiftsToText[actionShift.staffId || ""] = {
          ...actionShift,
        };
      }
    } else {
      findStaffToWork.staffShiftsToText = null;
      sidebarCurrentAction.isDirty = false;
    }
  },
  endFindStaffToWork: (state: THouseViewState) => {
    state.findStaffToWork = initialState.findStaffToWork;
    state.sidebarCurrentAction = initialState.sidebarCurrentAction;
  },
  selectIsLookingToText: ({ findStaffToWork }: THouseViewState, action: PayloadAction<boolean>) => {
    findStaffToWork.isLookingToText = action.payload;
  },
};
