import { YyyyMmDd } from "@m7-health/shared-utils";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";

import { StaffDetails } from "~/api/staffDetails/types";
import { lightGray, white } from "~/common/theming/colors";
import { Uuid } from "~/common/types";
import { IShift } from "~/routes/api/types";

import { ISchedule, IScheduleShiftType, IStaffDetails, IStaffShift, Note } from "@/api";

import { DeprecatedTShift } from "../SchedulerGrid/types";

export enum EDailyReportViewType {
  List = "List",
  Position = "Position",
}

export type TDailyReportShiftTypeFilter = IShift["key"][];

export interface IDailyReportState {
  date: YyyyMmDd;
  filters: {
    viewType: EDailyReportViewType;
    shiftType: TDailyReportShiftTypeFilter;
  };
  isAddLabelModelOpen: boolean;
  unsavedChangesModal: {
    isOpen: boolean;
    handleDiscardChanges?: Function;
  };
  staffNotes: TStaffNotesByStaffId | undefined;
  isEdited: boolean;
  scheduleId?: ISchedule["id"];
  updatesToSave: Record<IStaffShift["id"], Pick<IStaffShift, "id" | "attributes" | "updatedAt">>;
}

export type TStaffNotesByStaffId = { [key: string]: Partial<INote> | undefined };

export interface IDailyReportMainTableProps {
  initialStaffNotes: TStaffNotesByStaffId | undefined;
  scheduleIdOfDate: string | null;
  isDateInCurrentSchedule: boolean;
  currentDate: YyyyMmDd;
  refetchNotesByDate: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined,
  ) => Promise<QueryObserverResult<TStaffNotesByStaffId, unknown>>;
}

export interface IStaffRowProps {
  staffDetails?: IStaffDetails;
  staffShift: IStaffShift;
  staffId: string;
  name: string;
  staffType: string;
  staffCategory?: string;
  lastOfCriteria?: boolean;
  employmentType?: StaffDetails.EEmploymentType | undefined;
  idxOfStaffType?: number;
  numberInCriteria?: number;
  workDayShift?: DeprecatedTShift;
  shiftOptions?: Record<string, IShift | IScheduleShiftType> | null;
  unitIdOfShift?: Uuid;
  unitNameOfShift?: string;
  homeUnitId?: Uuid;
  homeUnitName?: string;
  isWorkingAwayToOtherUnitRow?: boolean;
  scheduledHours?: number;
  isSameStaffAsPrevious?: boolean;
}

export interface IHeaderRowProps {
  isWorkingAwayToOtherUnitTable?: boolean;
}

export interface ICreateLabel {
  unitId: string;
  name: string;
}

export interface IUnitStaffLabel {
  unitId: string;
  key: string;
  name: string;
}

export interface IUnitStaffLabelForCustomSelect {
  unitId: string;
  key: string;
  name: string;
  label: string;
  value: string;
}

export interface IGetUnitStaffLabels {
  data: IUnitStaffLabel[];
}

export interface INote {
  id: Uuid;
  content: string;
  date: YyyyMmDd;
  publishedScheduleId: string;
  shiftType?: string;
  userId?: string;
  metadata?: unknown;
  unitStaffLabel?: IUnitStaffLabel;
  update?: EShiftStatus | Note.EUpdate | null;
  updatedAt?: string;
}

/** @deprecated use StaffShift.EStatus instead */
export enum EShiftStatus {
  calledIn = "Called In",
  onCall = "On Call",
  cancelled = "Cancelled",
  floated = "Floated",
  flexed = "Flexed",
}

export type EShiftStatusOrNull = EShiftStatus | null;

export interface IGetNotes {
  data: INote[];
}

interface ICreateNoteBase {
  content?: string;
  date: string;
  shiftType?: string | null;
  metadata?: unknown;
  unitStaffLabelKey?: string | null;
  unitStaffLabelName?: string | null;
  update?: EShiftStatus | Note.EUpdate | null;
  updatedAt?: string | null;
}
export type ICreateNote = ICreateNoteBase &
  ({ userId: string; scheduleId?: never | null } | { userId?: never | null; scheduleId: string });

export const cellSx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  pr: 2,
  borderLeft: "solid lightGray",
};
export const headerCellShiftNoteSx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  pr: 2,
  borderBottom: "solid lightgray",
  borderLeft: "solid lightgray",
  borderTop: "solid lightgray",
  backgroundColor: lightGray,
};

export const headerCellSx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  pb: 2,
  pr: 2,
  borderBottom: "solid lightGray",
  borderLeft: "solid lightGray",
  borderTop: "solid lightGray",
  height: "80%",
  backgroundColor: lightGray,
};

export const headerCellTypographySx = {
  mt: -1.5,
};

export const customSelectSx = { my: "8px", height: "59px", mr: "20px", backgroundColor: white };

export const stylesTextField = {
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 500,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: "10px",
  },
};
