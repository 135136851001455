import { SyntheticEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { includes } from "lodash";

import BarChartIcon from "@mui/icons-material/BarChart";
import ChatIcon from "@mui/icons-material/Chat";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DraftsIcon from "@mui/icons-material/Drafts";
import PersonIcon from "@mui/icons-material/Person";
import { Badge } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

import { lines, white } from "~/common/theming/colors";
import { zIndexes } from "~/common/theming/constants";
import { useStaffDetailsQuery } from "~/features/User/queries";
import { routes } from "~/routes";

import { useGetNotificationsCountQuery } from "@/api";
import { useCurrentUnitId } from "@/common/hooks";

const Footer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const unitId = useCurrentUnitId();

  const handleChange = (_event: SyntheticEvent, newPath: string) => {
    navigate(newPath);
  };

  const { data: { employmentType } = {} } = useStaffDetailsQuery();
  const allowedToSeeStatistics =
    employmentType && includes(routes.statistics.allowedEmploymentType, employmentType);

  const { data: notificationsCounts } = useGetNotificationsCountQuery(
    { version: "2" },
    { skip: !unitId },
  );

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: white,
        borderTopColor: lines,
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        bottom: 0,
        left: 0,
        padding: "8px 0 25px",
        position: "fixed",
        right: 0,
        width: "100%",
        zIndex: zIndexes.bottomNavigationInStaffApp,
      }}
    >
      <BottomNavigation onChange={handleChange} showLabels value={pathname}>
        <BottomNavigationAction label="Schedule" value="/calendar" icon={<DateRangeIcon />} />
        <BottomNavigationAction
          label="Requests"
          value="/shift-swap-request"
          icon={
            notificationsCounts?.approvalRequestCount ? (
              <>
                <Badge badgeContent={notificationsCounts?.approvalRequestCount} color="error">
                  <DraftsIcon />
                </Badge>
              </>
            ) : (
              <DraftsIcon />
            )
          }
        />
        {allowedToSeeStatistics && (
          <BottomNavigationAction label="Statistics" value="/statistics" icon={<BarChartIcon />} />
        )}
        <BottomNavigationAction label="Support" value="/support" icon={<ChatIcon />} />
        <BottomNavigationAction label="Profile" value="/profile" icon={<PersonIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default Footer;
