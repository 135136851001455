import styled from "styled-components";

import { TableContainer, darken } from "@mui/material";

import { black, lines, seaShell, white } from "~/common/theming/colors";
import { css } from "~/common/utils/cl";
import { pxToRem } from "~/common/utils/pxToRem";

const style = css`
  /** TABLE ----*/
  table th {
    font-weight: 100;
  }

  /** BORDERS ----*/
  table th {
    /* For headers apply both top and bottom borders to the <th> */
    border-top: 0.5px solid ${lines};
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table .sticky-corner {
    border-bottom: 1px solid ${lines};
  }

  table th:first-child,
  table td:first-child {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 0.5px solid;
  }

  // Selected cell
  table td.selected > div {
    border-radius: 2px;
    -webkit-box-shadow: inset 0px 0px 0px 2px ${black};
    -moz-box-shadow: inset 0px 0px 0px 2px ${black};
    box-shadow: inset 0px 0px 0px 2px ${black};
  }

  // Week delimiters
  table th.sunday,
  table td.sunday {
    border-right: 4px solid ${darken(lines, 0.05)};
  }

  // Border effect on sticky column
  table th.sticky-col::before {
    content: ""; /* Necessary for the pseudo-element to be displayed */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px; /* Width of your "border" */
    background-color: ${lines}; /* Color of your "border" */
    z-index: 2; /* Higher z-index to make it appear on top */
  }

  // Border effect on sticky row
  table th.sticky-row::after {
    content: ""; /* Necessary for the pseudo-element to be displayed */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px; /* Width of your "border" */
    background-color: ${lines}; /* Color of your "border" */
    // background-color: blue; /* Color of your "border" */
    z-index: 2; /* Higher z-index to make it appear on top */
  }
  /** ----BORDERS */

  /** Staff name arrow hint ------*/
  .header-cell-name.selected {
    ::after {
      content: "‣";
      position: absolute;
      right: 5px;
      transform: translateY(-50%);
      top: 50%;
      font-size: 20px;
    }
  }
  /** -----Staff name arrow hint */

  /** STICKY CELLS ----*/
  table th.sticky-col,
  table th.sticky-row {
    padding: 0;
    border-top: 0;
    position: sticky;
    background-color: white;
    z-index: 4;
  }

  table th.sticky-col {
    left: 0;
  }

  table th.sticky-row {
    top: 0;
  }

  table th.sticky-corner {
    z-index: 5;
    top: 0;
    left: 0;
    border-left: 0;
  }
  /** ----STICKY CELLS */

  /** CELL ----*/
  .shift-cell {
    &.past-date {
      opacity: 0.5;
    }

    &.small {
      border-size: 2px;
    }
  }

  .shift-tile-cell-wrapper {
    position: relative;
  }

  .shift-custom-label {
    &.plus-sign {
      button > p {
        padding-bottom: 1px;
        font-size: ${pxToRem(18)} !important;
      }
    }
  }
  /** ----CELL */

  table .selected-row,
  table .selected-column {
    background-color: ${seaShell};
    &.selected {
      background-color: ${white};
    }
  }

  /** REGULAR VIEW ----*/
  table.scheduler-table.regular-view {
    .inside-cell {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    td.shift-cell .shift-tile.big {
      justify-content: flex-start;
    }

    .shift-tile-cell-wrapper {
      height: 48px;
      max-width: 94px;
      min-width: 60px;
    }

    /** SHIFT SELECTOR DROPDOWN ----*/
    .shift-cell {
      .MuiFormControl-root {
        position: relative;
        top: -15px;

        .MuiInput-root::before {
          display: none;
        }
        button {
          width: 100%;
          height: 100%;
        }
        .MuiInputBase-input {
          padding: 0;
        }
        .MuiSelect-nativeInput {
          display: none;
        }
      }
    }
    /** ----SHIFT SELECTOR DROPDOWN */

    th.sticky-col {
      height: 48px;
    }

    .header-cell-name-attribute {
      text-wrap: nowrap;
    }

    .inside-cell .working-shift {
      width: 85px !important;
      height: 100%;

      & {
        /* Above 10 */
      }

      // Styles for range 0-5
      > button[data-label-length="0"],
      > button[data-label-length="1"],
      > button[data-label-length="2"],
      > button[data-label-length="3"],
      > button[data-label-length="4"],
      > button[data-label-length="5"] {
        /* Regular css */
      }

      /* Styles for range 6-8 */
      > button[data-label-length="6"],
      > button[data-label-length="7"],
      > button[data-label-length="8"] {
        padding: 10px;
        align-items: center;
        .MuiButton-startIcon {
          margin-right: 2px;
        }
        p {
          font-size: 13px;
        }
        .MuiButton-startIcon {
          position: relative;
          top: -1px;
        }
      }

      /* Styles for range 9-10 */
      > button[data-label-length="9"],
      > button[data-label-length="10"],
      > button[data-label-length="11"] {
        padding: 13px 5px 15px 6px;
        align-items: self-start;
        .MuiButton-startIcon {
          margin-right: 2px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }

  /** ----REGULAR VIEW */

  /** MINI VIEW ----*/
  table.scheduler-table.mini-view {
    /** SHIFT SELECTOR DROPDOWN ----*/
    .shift-cell {
      .MuiFormControl-root {
        top: -10px;
        left: 0px;
      }

      [data-testid="ArrowDropDownIcon"] {
        display: none;
      }

      .shift-tile-cell-wrapper {
        width: 34px;
      }
    }

    .shift-note {
      &.MuiBox-root {
        width: 10px;
        height: 10px;
        overflow: hidden;
        border: 1px solid white;
        border-radius: 4px;
      }
    }

    .shift-cell[data-shift-key="__no-shift__"] {
      .MuiFormControl-root {
        top: -10px;
        left: 12px;
      }

      [data-testid="ArrowDropDownIcon"] {
        display: initial;
      }
    }
    .shift-cell {
      .MuiFormControl-root {
        position: relative;
        .MuiInputBase-input {
          padding: 0;
          min-width: 0;
        }
        .shift-tile {
          padding: 5px 3px;
          .MuiSvgIcon-root,
          .MuiButton-startIcon {
            margin: 0;
          }

          p {
            display: none;
          }
        }

        .MuiSelect-nativeInput {
          display: none;
        }
      }
    }
    /** ----SHIFT SELECTOR DROPDOWN */

    th.sticky-row > div > div {
      max-width: 35px;
      .date-wrapper {
        width: 35px;
      }
    }

    th .date-wrapper {
      display: table-cell;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      width: 30px;
      padding-top: 3px;
    }

    th.sticky-col {
      height: 36px;
    }

    .shift-cell {
      position: relative;
    }

    .shift-tile-cell-wrapper {
      width: 100%;
      height: 34px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .working-shift .MuiSvgIcon-root {
      font-size: 14px !important;
    }

    .header-cell-name-attribute {
      text-wrap: nowrap;
    }

    .inside-cell {
      padding: 1px 2px;
    }

    .shift-tile {
      padding: 0px 13px;
      height: 26px;
      .MuiSvgIcon-root,
      .MuiButton-startIcon {
        margin: 0;
      }
    }
  }
`;

export const TableStyleContainer = styled(TableContainer)`
  ${style}
`;
