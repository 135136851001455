import styled from "styled-components";

import { TableContainer } from "@mui/material";

import { lines } from "~/common/theming/colors";
import { css } from "~/common/utils/cl";

const style = css`
  /** TABLE ----*/
  table th {
    font-weight: 100;
  }

  /** BORDERS ----*/
  table th {
    /* For headers apply both top and bottom borders to the <th> */
    border-top: 0.5px solid ${lines};
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table th:first-child,
  table td:first-child {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 0.5px solid ${lines};
  }
`;

export const TableStyleContainer = styled(TableContainer)`
  ${style}
`;
