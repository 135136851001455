import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { YyyyMmDd } from "@m7-health/shared-utils";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { localDayJs } from "~/common/packages/dayjs";
import { getDateInIsoFormat } from "~/common/utils/dates";

import { houseViewStore } from "../store";

/**
 * This hook is used to get the selected day from the query params or the redux store.
 * It also updates the query params and the redux store when the selected day changes.
 * When none is provided, it defaults to today.
 * The date from the query params takes precedence over the one from the redux store.
 *
 * @returns {YyyyMmDd} selectedDay
 * @returns {Function} setSelectedDay
 *
 */
const useSelectedDay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const today = getDateInIsoFormat(localDayJs()) as YyyyMmDd;
  const selectedDayFromQuery =
    (searchParams.get("selectedDay") as YyyyMmDd | undefined) || undefined;
  const selectedDayInState = useAppSelector((state) => state.houseView.pageFilters.selectedDate);

  const setSelectedDay = useCallback(
    (day: YyyyMmDd) => {
      if (selectedDayFromQuery !== day)
        setSearchParams((otherParams) => {
          otherParams.set("selectedDay", day);
          return otherParams;
        });
      if (selectedDayInState !== day) dispatch(houseViewStore.state.selectDate(day));
    },
    [selectedDayFromQuery, setSearchParams, selectedDayInState, dispatch],
  );

  // if one is missing, use the other, or today
  const retrieveSelectedDay = (setIfNull = false) => {
    let result: YyyyMmDd;

    if (!selectedDayInState || !selectedDayFromQuery) {
      if (setIfNull) {
        if (selectedDayInState) setSelectedDay(selectedDayInState);
        else if (selectedDayFromQuery) setSelectedDay(selectedDayFromQuery);
        else setSelectedDay(today);
      }

      result = selectedDayInState || selectedDayFromQuery || today;
    } else if (selectedDayInState !== selectedDayFromQuery) {
      // if they are different, use the one from the query
      setIfNull && setSelectedDay(selectedDayFromQuery);
      result = selectedDayFromQuery;
    } else {
      // All good!
      result = selectedDayFromQuery;
    }

    return result;
  };

  const selectedDateValue = retrieveSelectedDay();

  // Set the selected day on mount, no dependencies needed
  useEffect(() => {
    retrieveSelectedDay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({ value: selectedDateValue, setSelectedDay }),
    [selectedDateValue, setSelectedDay],
  );
};

export const HouseView_hooks_useSelectedDay = useSelectedDay;
