import { useMemo } from "react";

import { IShift } from "~/routes/api/types";
import { useShiftsByScheduleQuery, useShiftsByUnitQuery, useShiftsQuery } from "~/routes/queries";

/** @deprecated, not efficient at all */
export const useShiftType = (
  shiftTypeKey: IShift["key"] | undefined,
  target?: {
    unitId?: string;
    scheduleId?: string;
  },
) => {
  // @zchentou do you think we should leverage this, or delete?
  const { data: shiftsFromUnit } = useShiftsByUnitQuery([target?.unitId || ""]);
  const { data: shiftsFromSchedule } = useShiftsByScheduleQuery(target?.scheduleId);
  const { data: allShiftOptionsGlobal } = useShiftsQuery();

  return useMemo(() => {
    if (!shiftTypeKey) return null;

    let shiftType: IShift | undefined;

    if (target?.scheduleId) {
      shiftType = shiftsFromSchedule?.[shiftTypeKey];
      // If no schedule shift type, means it's an old schedule.
      //  so corresponding shift types are in the global list
      shiftType ||= allShiftOptionsGlobal?.[shiftTypeKey];
    }
    shiftType ||= shiftsFromUnit?.[shiftTypeKey];
    shiftType ||= allShiftOptionsGlobal?.[shiftTypeKey];

    return shiftType;
  }, [shiftTypeKey, target?.scheduleId, shiftsFromSchedule, shiftsFromUnit, allShiftOptionsGlobal]);
};
