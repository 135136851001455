import { memo } from "react";

import { SxProps, Theme, Typography } from "@mui/material";

import { black, white } from "~/common/theming/colors";

import { StaffDetails } from "@/api";

export const PerDiemTag = (sx?: SxProps<Theme> | undefined) => (
  <>
    {" "}
    <Typography
      borderRadius={1.5}
      variant="small"
      sx={{
        backgroundColor: black,
        color: white,
        px: 0.3,
        py: 0.3,
        ...sx,
      }}
    >
      PRN
    </Typography>{" "}
  </>
);

const getEmploymentStatusText = (
  employmentType?: StaffDetails.EEmploymentType,
): string | undefined => {
  const employmentTypeToAbbreviation: { [key in StaffDetails.EEmploymentType]?: string } = {
    [StaffDetails.EEmploymentType.partTime]: "PT",
    [StaffDetails.EEmploymentType.perDiem]: "PRN",
    [StaffDetails.EEmploymentType.travelStaff]: "TVL",
    [StaffDetails.EEmploymentType.inHouseContract]: "IHC",
    [StaffDetails.EEmploymentType.flex]: "FLX",
  };
  if (!employmentType) return undefined;
  return employmentTypeToAbbreviation[employmentType] || "";
};

export const EmploymentTypeTag = memo(
  ({
    employmentType,
    sx,
    variant = "medium",
  }: {
    employmentType?: StaffDetails.EEmploymentType;
    variant?: "small" | "medium" | "big";
    sx?: SxProps<Theme>;
  }) => {
    const textToDisplay = getEmploymentStatusText(employmentType);

    if (!textToDisplay) return <></>;

    return (
      <Typography
        className="m7-employment-type-tag"
        borderRadius={1.5}
        variant="small"
        sx={{
          backgroundColor: black,
          color: white,
          ...sxPerSize[variant],
          ...sx,
        }}
        children={textToDisplay}
      />
    );
  },
);

const sxPerSize = {
  small: { fontSize: 10, px: 0.4, py: 0.4 },
  medium: { fontSize: 13, px: 0.3, py: 0.3 },
  big: { fontSize: 16, px: 0.4, py: 0.4 },
} as const;
