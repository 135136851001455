/* eslint-disable no-console */

import { memo } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { Dayjs } from "dayjs";

import { Schedule, Unit } from "~/api";

import { ITablesData } from "../Table";

import { HeaderCellMiniView } from "./HeaderCellMiniView";
import { HeaderNameCell } from "./HeaderNameCell";

export const _SchedulerScheduleTableHeaderRow = memo(
  ({
    datesRows,
    formattedDateRows,
    unit,
    schedules,
    tablesData,
    expanded,
    setExpandedUnits,
  }: {
    datesRows: Dayjs[];
    formattedDateRows: YyyyMmDd[];
    unit: Unit.DTO;
    schedules: Schedule.DTO[];
    tablesData: ITablesData;
    expanded: boolean;
    setExpandedUnits: (value: React.SetStateAction<Record<string, boolean>>) => void;
  }) => {
    const HeaderCellComponent = HeaderCellMiniView;

    return (
      <tr>
        {/* Corner cell */}
        <th className="sticky-col sticky-corner">
          <HeaderNameCell unit={unit} expanded={expanded} setExpandedUnits={setExpandedUnits} />
        </th>

        {/* Add a column for each date */}
        {datesRows.map((date, index) => {
          const formattedDate = formattedDateRows[index] || date.format("YYYY-MM-DD");

          const classNames = ["sticky-row"];
          if (date.day() === 6) classNames.push("sunday");
          classNames.push(formattedDate);

          return (
            <th key={formattedDate} className={classNames.join(" ")}>
              <div>
                <HeaderCellComponent
                  key={formattedDate + "-header" + unit.id}
                  cellDate={date}
                  // pass date as columnKey
                  columnKey={formattedDate}
                  schedules={schedules}
                  tablesData={tablesData}
                  unitId={unit.id}
                />
              </div>
            </th>
          );
        })}
      </tr>
    );
  },
);
