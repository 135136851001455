import { PayloadAction } from "@reduxjs/toolkit";

import { IStaffShift } from "~/api";

import { THouseViewState, initialState } from ".";

// Make sure anything else than 'Other' matches StaffDetails.EmploymentType
export enum EHVEmploymentTypeFilter {
  perDiem = "Per diem",
  travelStaff = "Travel staff",
  other = "Other",
}
export type EditShifts = {
  staffShift?: Partial<
    Pick<IStaffShift, "shiftTypeKey" | "staffId" | "customStartTime" | "customDuration" | "id">
  > | null;
  staffShifts?: IStaffShift[];
  autoSave?: boolean;
  staffId?: IStaffShift["staffId"];
};

export const EditShiftsActions = {
  startEditShift: ({ sidebarCurrentAction }: THouseViewState) => {
    sidebarCurrentAction.inProgress = "editShifts";
  },
  startEditShifts: (
    { sidebarCurrentAction, editShifts }: THouseViewState,
    action: PayloadAction<{
      autoSave?: EditShifts["autoSave"];
      shifts?: EditShifts["staffShifts"];
      staffId: IStaffShift["staffId"];
    }>,
  ) => {
    sidebarCurrentAction.inProgress = "editShifts";
    editShifts.autoSave = action.payload.autoSave;
    editShifts.staffShifts = action.payload.shifts;
    editShifts.staffId = action.payload.staffId;
  },
  setShiftToEdit: (
    { editShifts, sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<Partial<IStaffShift> | null>,
  ) => {
    if (action.payload) {
      sidebarCurrentAction.isDirty = true;
      editShifts.staffShift = {
        ...(editShifts.staffShift || {}),
        ...action.payload,
      };
    } else {
      editShifts.staffShift = null;
      sidebarCurrentAction.isDirty = false;
    }
  },
  endEditShifts: (state: THouseViewState) => {
    state.editShifts = initialState.editShifts;
    state.sidebarCurrentAction = initialState.sidebarCurrentAction;
  },
};
