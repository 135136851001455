import { useMemo } from "react";

import { EUnitPermissionAreas, Timezone } from "@m7-health/shared-utils";

import { useSelectedSchedule } from "#/features/HouseView/hooks/useSelectedSchedule";
import { houseViewStore } from "#/features/HouseView/store";
import {
  IStaffShift,
  useBulkSaveStaffShifts,
  useInvalidateQuery,
  useListStaffShiftsQuery,
} from "@/api";
import { ShiftsEditor } from "@/common/components";
import { AppLoader } from "@/common/components/AppLoader/AppLoader";
import { NOT_EXISTING_UUID } from "@/common/constants";
import {
  useAppDispatch,
  useAppSelector,
  useCheckUserPermission,
  useCurrentTimezone,
  useIsKiosk,
  useToast,
} from "@/common/hooks";
import { useErrors } from "@/common/hooks/useErrors";
import { localDayJs } from "@/common/packages/dayjs";
import { dateString } from "@/common/types";
import { getTzDayjs } from "@/common/utils/dates";

import { THouseViewSideBar } from "../types";

export const EditShiftsV2 = ({ unitsByScheduleId, staffDetails }: THouseViewSideBar) => {
  // use timezone of related "selected" unit if any
  const scheduleId = useSelectedSchedule()?.id;
  const currentTimezone = useCurrentTimezone();
  const unitTimezone =
    scheduleId && (unitsByScheduleId[scheduleId]?.timezone as Timezone | undefined);
  const timezone = unitTimezone || currentTimezone;

  const dispatch = useAppDispatch();
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const invalidateQuery = useInvalidateQuery();
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();

  const { mutateAsync: saveStaffShifts, isPending: isSavingShifts } = useBulkSaveStaffShifts({});

  const { staffId, currentDate, currentStaffShiftsInStore, autoSave } = useAppSelector((state) => ({
    staffId: state.houseView.editShifts.staffId,
    currentDate: state.houseView.pageFilters.selectedDate,
    currentStaffShiftsInStore: state.houseView.editShifts.staffShifts,
    autoSave: state.houseView.editShifts.autoSave,
  }));

  // Params for ShiftsEditor
  const currentStaffDetails = staffId ? staffDetails?.[staffId] : undefined;
  const { data: shiftsToEdit, isLoading } = useListStaffShiftsQuery(
    {
      staffIds: [staffId || NOT_EXISTING_UUID],
      date: [
        {
          value: getTzDayjs(currentDate, timezone).startOf("day").toISOString(),
          operator: "gte",
        },
        {
          value: getTzDayjs(currentDate, timezone).endOf("day").toISOString(),
          operator: "lte",
        },
      ],
    },
    { skip: !staffId },
  );
  const newStaffShiftScaffold = useMemo(() => {
    const date = localDayJs.tz(currentDate, timezone).toDate().toISOString() as dateString;
    if (!scheduleId) return null;

    return {
      date,
      scheduleId,
    };
  }, [currentDate, timezone, scheduleId]);
  const saveShifts = ({
    toCreate,
    toDelete,
    toUpdate,
  }: {
    toCreate: IStaffShift[];
    toDelete: IStaffShift[];
    toUpdate: IStaffShift[];
  }) => {
    saveStaffShifts({
      staffShifts: [
        ...toCreate.map(({ id: _id, ...newShiftAttributes }) => newShiftAttributes),
        ...toDelete.map(({ id, updatedAt }) => ({ id, updatedAt, shiftTypeKey: null })),
        ...toUpdate,
      ],
    })
      .then(() => {
        showSuccess("Staff shifts saved successfully.");
        invalidateQuery(useListStaffShiftsQuery);
        dispatch(houseViewStore.state.endAction());
      })
      .catch(handleErrors);
  };

  if (!autoSave && (!newStaffShiftScaffold || !staffId || !currentStaffDetails)) return <></>;
  if (isLoading) return <AppLoader open />;

  return (
    <ShiftsEditor
      readonly={isKioskUser || !canManage}
      autoSave={autoSave}
      staffShifts={shiftsToEdit || []}
      staffShiftsWithEdits={currentStaffShiftsInStore}
      staffDetails={currentStaffDetails}
      unitsByScheduleId={unitsByScheduleId}
      saveChanges={saveShifts}
      isLoading={isSavingShifts}
      cancelChanges={() => dispatch(houseViewStore.state.endAction())}
      newStaffShiftScaffold={newStaffShiftScaffold}
      readonlyUnit={false}
    />
  );
};
