import { useCallback, useEffect, useRef } from "react";

import { Milliseconds } from "@m7-health/shared-utils";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";

import { httpClient } from "@/common/packages/httpClient";

export const useResetQueriesOnM7HeadersChange = () => {
  const queryClient = useQueryClient();

  const debounceValue = 100 as Milliseconds;
  const debouncedOnChangeValue = useRef(Date.now());

  const onUnitChange = useCallback(() => {
    debouncedOnChangeValue.current = Date.now();

    setTimeout(() => {
      // If the last change was less than the debounce value, return
      if (Date.now() - debouncedOnChangeValue.current < debounceValue) return;

      // Reset all queries except the app config query and staffDetails query that
      //  are needed to determine the current unit
      const queriesToKeep = [["appConfig"], ["staffDetails"]];
      void queryClient.invalidateQueries();
      const allQueries = queryClient.getQueryCache().getAll();
      allQueries.forEach((query) => {
        if (isEqual(queriesToKeep, query.queryKey)) return;

        void queryClient.removeQueries({ queryKey: [query.queryKey] });
      });
    }, debounceValue);
  }, [queryClient]);

  const resetAllQueries = useCallback(() => {
    debouncedOnChangeValue.current = Date.now();

    setTimeout(() => {
      // If the last change was less than the debounce value, return
      if (Date.now() - debouncedOnChangeValue.current < debounceValue) return;

      // Reset all queries
      void queryClient.invalidateQueries();
      const allQueries = queryClient.getQueryCache().getAll();
      allQueries.forEach((query) => void queryClient.removeQueries({ queryKey: [query.queryKey] }));
    }, debounceValue);
  }, [queryClient]);

  useEffect(() => {
    httpClient.eventEmitter.on("unitIdChanged", onUnitChange);
    httpClient.eventEmitter.on("facilityIdChanged", onUnitChange);
    httpClient.eventEmitter.on("currentRoleChanged", resetAllQueries);

    return () => {
      httpClient.eventEmitter.removeListener("unitIdChanged", onUnitChange);
      httpClient.eventEmitter.removeListener("facilityIdChanged", onUnitChange);
      httpClient.eventEmitter.removeListener("currentRoleChanged", resetAllQueries);
    };
  }, [onUnitChange, resetAllQueries]);
};
