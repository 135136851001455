import * as Sentry from "@sentry/react";

import { Support } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloudIcon from "@mui/icons-material/Cloud";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GavelIcon from "@mui/icons-material/Gavel";
import HomeIcon from "@mui/icons-material/Home";
import LightModeIcon from "@mui/icons-material/LightMode";
import Man4Icon from "@mui/icons-material/Man4";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import PhoneIcon from "@mui/icons-material/Phone";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import SchoolIcon from "@mui/icons-material/School";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import TextSnippetSharpIcon from "@mui/icons-material/TextSnippetSharp";
import WavingHandIcon from "@mui/icons-material/WavingHand";

import { BlockIcon, CrossIcon } from "~/common/components/Icons";
import { IShift } from "~/routes/api/types";

import { Uuid } from "./types";

/** @deprecated */
const VacationIcon = BeachAccessIcon;
/** @deprecated */
const EducationIcon = BookmarkIcon;
/** @deprecated */
const NightShiftIcon = DarkModeIcon;
/** @deprecated */
const DayShiftIcon = LightModeIcon;
/** @deprecated */
const AdminIcon = TextSnippetSharpIcon;

type TIconOptions = { icon: JSX.Element };
export const ShiftAvailableIcons = {
  AccessTime: AccessTimeIcon,
  AttachMoney: AttachMoneyIcon,
  BeachAccess: BeachAccessIcon,
  Block: BlockIcon,
  Bookmark: BookmarkIcon,
  Close: CrossIcon,
  DarkMode: DarkModeIcon,
  Favorite: FavoriteIcon,
  Gavel: GavelIcon,
  Home: HomeIcon,
  LightMode: LightModeIcon,
  Man4: Man4Icon,
  MedicalInformation: MedicalInformationIcon,
  School: SchoolIcon,
  TextSnippetSharp: TextSnippetSharpIcon,
  WavingHand: WavingHandIcon,
  Cloud: CloudIcon,
  ScreenshotMonitor: ScreenshotMonitorIcon,
  Phone: PhoneIcon,
  MarkEmailRead: MarkEmailReadIcon,
  Multi: QueueOutlinedIcon,
  None: undefined,
} as const;
export type LAvailableMuiIconsClassNames = keyof typeof ShiftAvailableIcons;

const FALLBACK_ICON = BookmarkIcon;
export const FLOATING_ICON = Support;

export const OVERTIME_IN_HOURS = 40;
export const OVERTIME_IN_SECONDS = 60 * 60 * OVERTIME_IN_HOURS;

export const iconComponentForShift = (muiIconClassName?: LAvailableMuiIconsClassNames) => {
  if (muiIconClassName) {
    const icon = ShiftAvailableIcons[muiIconClassName];
    if (!icon) Sentry.captureMessage(`iconComponentForShift: ${muiIconClassName} icon not found`);
    else return icon;
  }
  return FALLBACK_ICON;
};

export const SHIFT_ICONS: Record<IShift["key"], TIconOptions> = {
  administrative: { icon: <AdminIcon /> },
  bereavement: { icon: <Man4Icon /> },
  block: { icon: <BlockIcon /> },
  clear: { icon: <CrossIcon /> },
  day: { icon: <DayShiftIcon /> },
  eib: { icon: <AccessTimeIcon /> },
  education: { icon: <EducationIcon /> },
  houseSupervisorDay: { icon: <HomeIcon /> },
  houseSupervisorNight: { icon: <HomeIcon /> },
  juryDuty: { icon: <GavelIcon /> },
  night: { icon: <NightShiftIcon /> },
  orientationDay: { icon: <WavingHandIcon /> },
  orientationNight: { icon: <WavingHandIcon /> },
  otDay: { icon: <AttachMoneyIcon /> },
  otNight: { icon: <AttachMoneyIcon /> },
  pto: { icon: <VacationIcon /> },
  school: { icon: <SchoolIcon /> },
  childCare: { icon: <FavoriteIcon /> },
};

export const NOT_EXISTING_UUID = "77777777-7777-7777-7777-777777777777" as Uuid;

export const BULK_DELETE_KEY = "_delete";
export const BULK_UPDATE_KEY = "_update";
