import { buildApiListQueryHook } from "../_shared/helpers";
import { staleFor } from "../_shared/types";

import { Unit } from "./types";

export const useListUnitsQuery = buildApiListQueryHook<
  Unit.API.List.QueryParams,
  Unit.API.List.Response
>(Unit.API.List.PATH, {
  staleTime: staleFor(5).minutes,
});
