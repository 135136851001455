import React from "react";

import { Box, Typography } from "@mui/material";

import { pxToRem } from "~/common/utils/pxToRem";

import { HeaderCellContainer } from "./HeaderCell.styled";

interface IHeaderCellProps {
  title: string;
}

export const HeaderCell = ({ title }: IHeaderCellProps) => {
  return (
    <HeaderCellContainer
      flexDirection="column"
      alignItems="flex-start"
      sx={{ height: "52px", opacity: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "26px",
          margin: "2px 8px",
          borderRadius: "4px",
        }}
      >
        <Typography
          sx={[
            {
              marginRight: "3px",
              color: "inherit",
              fontSize: pxToRem(17),
              lineHeight: pxToRem(14),
              pt: "5px",
              textWrap: "nowrap",
            },
          ]}
        >
          {title}
        </Typography>
      </Box>
    </HeaderCellContainer>
  );
};
