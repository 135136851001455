import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createFilter } from "redux-persist-transform-filter";

import { EStaffScheduleTypes, ISelectedOpenShiftData } from "#/features/CalendarV2/types";

import { ICalendarState } from "../types";

import { initialState } from "./initialState";

export const calendarV2Slice = createSlice({
  name: "calendarV2",
  initialState,
  reducers: {
    setScheduleType: (state, action: PayloadAction<EStaffScheduleTypes>) => {
      state.scheduleType = action.payload;
    },
    selectTile: (state, action: PayloadAction<string>) => {
      const otherTiles = state.selectedTiles.filter((date) => date !== action.payload);

      if (otherTiles.length === state.selectedTiles.length) {
        state.selectedTiles = [...otherTiles, action.payload];
      } else {
        state.selectedTiles = otherTiles;
      }
    },
    setSelectedTiles: (state, action: PayloadAction<string[]>) => {
      state.selectedTiles = action.payload;
    },
    selectShiftDetailsOpen: (state, action: PayloadAction<string>) => {
      state.shiftDetailsOpen = action.payload;
    },
    setDisplayOpenShifts: (state, action: PayloadAction<boolean>) => {
      state.displayOpenShifts = action.payload;
    },
    clearShiftDetailsOpen: (state) => {
      state.shiftDetailsOpen = null;
    },
    clearSelectedTiles: (state) => {
      state.selectedTiles = [];
    },
    setSelectSchedule: (state, action: PayloadAction<ICalendarState["selectedSchedule"]>) => {
      state.selectedSchedule = action.payload;
    },
    /** @deprecated use setSelectedSchedule instead */
    selectSchedule: (state, action: PayloadAction<ICalendarState["selectedSchedule"]>) => {
      state.selectedSchedule = action.payload;
    },
    setAreShiftNotesExpanded: (state, action: PayloadAction<boolean>) => {
      state.areShiftNotesExpanded = action.payload;
    },
    setIsRequestOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isRequestOpenShiftModalOpen = action.payload;
    },
    setSelectedOpenShiftData: (state, action: PayloadAction<ISelectedOpenShiftData | null>) => {
      state.selectedOpenShiftData = action.payload;
    },
    setIsUnrequestOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isUnrequestOpenShiftModalOpen = action.payload;
    },
    updateSwapShiftData: (
      state,
      action: PayloadAction<Partial<ICalendarState["swapShiftData"]>>,
    ) => {
      if (action.payload.swapping === false) {
        state.swapShiftData = initialState.swapShiftData;
      } else {
        state.swapShiftData = { ...state.swapShiftData, ...action.payload };
      }
    },
    updateDropShiftData: (
      state,
      action: PayloadAction<Partial<ICalendarState["dropShiftData"]>>,
    ) => {
      state.dropShiftData = { ...state.dropShiftData, ...action.payload };
    },
    updateTimeOffRequestModal: (
      state,
      action: PayloadAction<Partial<ICalendarState["timeOffRequestModal"]>>,
    ) => {
      state.timeOffRequestModal = { ...state.timeOffRequestModal, ...action.payload };
    },
  },
});

export const {
  selectSchedule,
  selectTile,
  setSelectedTiles,
  selectShiftDetailsOpen,
  clearSelectedTiles,
  clearShiftDetailsOpen,
  setScheduleType,
  setAreShiftNotesExpanded,
  setIsRequestOpenShiftModalOpen,
  setSelectedOpenShiftData,
  setIsUnrequestOpenShiftModalOpen,
  setDisplayOpenShifts,
  updateSwapShiftData,
  updateDropShiftData,
  updateTimeOffRequestModal,
} = calendarV2Slice.actions;

const calendarPersistFilter = createFilter("calendar", ["displayOpenShifts"]);

const persistConfig = {
  key: "calendar",
  storage,
  transform: [calendarPersistFilter],
};

export default persistReducer(persistConfig, calendarV2Slice.reducer);
