import { APIListResponse } from "../_shared/types";
import { IUnit } from "../unit";

export namespace BeAsyncJob {
  export namespace API {
    export const ROOT_PATH = "/general-queue";

    export namespace Dummy {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        async?: boolean;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace ResetStaffPreferredHours {
      export const PATH = ROOT_PATH + "/reset-staff-preferred-hours";
      export interface QueryParams {
        unitId?: IUnit["id"];
        async?: boolean;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace SendEmail {
      export const PATH = ROOT_PATH + "/send-email";
      export interface QueryParams {
        to: string;
        subject: string;
        text: string;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace UploadPTORequestsCSV {
      export const PATH = ROOT_PATH + "/upload-pto-requests-csv";
      export interface QueryParams {
        async?: boolean;
        file: File;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace CreateBlankSubmissions {
      export const PATH = ROOT_PATH + "/create-blank-submissions";
      export interface QueryParams {
        unitId: IUnit["id"];
      }

      export type Response = APIListResponse<object>;
    }
  }
}
