import {
  DefaultError,
  MutateFunction,
  QueryKey,
  QueryObserverOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import { axiosInstance } from "~/common/packages/httpClient";

import {
  ApiListResponsePaginated,
  ApiResponse,
  AugmentedQueryHook,
  PaginatedListQueryResponse,
} from "./types";

/***************
 * Query hooks *
 * *************/

type TQueryOptions =
  | {
      skip?: boolean;
      retry?: number;
      staleTime?: number;
    }
  | undefined;
type TAvailableNativeQueryOptions<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TQueryFnData = any,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Pick<QueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>, "staleTime">;

/**
 * @description build a query hook for a given api function
 *
 * @param apiFunction - the api function to call
 * @returns a query hook
 *
 * @example const useGetPreferences = buildQueryHook(getPreferencesApi);
 * */
export const buildQueryHook = <TParams, TResponse>(
  apiFunction: (param: TParams, options?: TQueryOptions) => Promise<TResponse>,
  queryKey: string,
  queryOptions?: TAvailableNativeQueryOptions<TResponse>,
) => {
  const useHook = (params: TParams, options?: TQueryOptions) => {
    return useQuery({
      queryKey: [queryKey, JSON.stringify(params) + JSON.stringify(options)],
      queryFn: () => apiFunction(params, options),
      ...queryOptions,
      staleTime: options?.staleTime ?? queryOptions?.staleTime ?? undefined,
    });
  };

  // Enhance the hook with the queryKey
  const result = useHook as AugmentedQueryHook<typeof useHook, typeof apiFunction>;
  result.queryKey = queryKey;
  result.apiFunction = apiFunction;

  return result;
};

/**
 * @description Based on a namespace, build a query hook for a get LIST api endpoint
 *
 * @param path - the path to the api endpoint
 * @returns a query hook
 *
 * @example const useGetShifts = buildApiListQueryHook<IShiftsQueryParams, IShiftsResponse>("/shifts");
 * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildApiListQueryHook = <TQueryParams, TResponseData extends ApiResponse<any>>(
  path: string | ((params: TQueryParams) => string),
  queryOptions?: TAvailableNativeQueryOptions,
) => {
  const apiFunction = async (
    params: TQueryParams,
    options?: TQueryOptions,
  ): Promise<TResponseData["data"]> => {
    if (options?.skip) {
      return [];
    }

    const computedPath = typeof path === "function" ? path(params) : path;

    const response = await axiosInstance.get<TResponseData>(computedPath, {
      params,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data.data;
  };

  const queryKey = "getList_" + path.toString().replace(/\//g, "_");

  return buildQueryHook<TQueryParams, TResponseData["data"]>(apiFunction, queryKey, queryOptions);
};

/**
 * @description Based on a namespace, build a query hook for a get LIST api endpoint with pagination data
 *
 * @param path - the path to the api endpoint
 * @returns a query hook
 *
 * @example const useGetShifts = buildPaginatedApiListQueryHook<IShiftsQueryParams, IShiftsDTO>("/shifts");
 * */
export const buildPaginatedApiListQueryHook = <TQueryParams, TListType>(
  path: string | ((params: TQueryParams) => string),
  queryOptions?: TAvailableNativeQueryOptions,
) => {
  const apiFunction = async (
    params: TQueryParams,
    options?: TQueryOptions,
  ): Promise<PaginatedListQueryResponse<TListType>> => {
    if (options?.skip) {
      return { list: [], pagination: { total: 0, skip: 0, take: 0 } };
    }

    const computedPath = typeof path === "function" ? path(params) : path;

    const response = await axiosInstance.get<ApiListResponsePaginated<TListType>>(computedPath, {
      params,
    });

    return { list: response.data.data, pagination: response.data.pagination };
  };

  const queryKey = "getPaginatedList_" + path.toString().replace(/\//g, "_");

  return buildQueryHook<TQueryParams, PaginatedListQueryResponse<TListType>>(
    apiFunction,
    queryKey,
    queryOptions,
  );
};

/******************
 * Mutation hooks *
 * ****************/

type TMutationOptions<TResponse> = {
  onSuccess?: (response: TResponse) => void;
  onError?: (error: Error) => void;
  onSettled?: (any: unknown) => void;
};

/**
 * @description build a mutation hook for a given api function
 *
 * @param apiFunction
 * @returns a mutation hook
 *
 * @example const useSavePreferences = buildMutationHook(savePreferencesApi);
 */
export const buildMutationHook = <TParams, TResponse>(
  apiFunction: MutateFunction<TResponse, Error, TParams>,
) => {
  return (options: TMutationOptions<TResponse>) =>
    useMutation({
      mutationFn: apiFunction,
      ...options,
    });
};

/**
 * @description Based on a namespace, build a mutation hook for a POST api endpoint
 *
 * @param path - the path to the api endpoint
 * @returns a mutation hook
 *
 * @example const useSavePreferences = buildApiMutationHook<ISavePreferencesParams, ISavePreferencesResponse>("/staff/preferences");
 */

export const buildApiMutationHook = <TParams, TResponse>(
  path: string | ((params: TParams) => string),
  httpVerb: "post" | "patch" | "put" | "delete" | "postForm" = "post",
) =>
  buildMutationHook(async (params: TParams) => {
    const computedPath = typeof path === "function" ? path(params) : path;
    const response = await axiosInstance[httpVerb]<TResponse>(computedPath, params);

    return response.data;
  });

export const buildApiMutationHookForCsv = <TParams, TResponse>(
  path: string | ((params: TParams) => string),
  // only get csv files
  httpVerb: "get",
) =>
  buildMutationHook(async (params: TParams) => {
    const computedPath = typeof path === "function" ? path(params) : path;
    // pass in params as query params, but also need responseType: "blob" for CSV
    const response = await axiosInstance[httpVerb]<TResponse>(computedPath, {
      params,
      responseType: "blob",
    });

    return response.data;
  });
