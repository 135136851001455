import { Controller } from "react-hook-form";

// eslint-disable-next-line deprecate/import
import { Checkbox, FormControlLabel, Switch } from "@mui/material";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

import { ICustomSwitchControlledProps, ISwitchProps } from "./types";

export const CustomSwitchControlled = ({
  control,
  name,
  ...rest
}: ICustomSwitchControlledProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <CustomSwitch
            checked={!!field.value}
            field={field}
            name={name}
            onChange={field.onChange}
            {...rest}
          />
        );
      }}
    />
  );
};

export const switchVariantClasses = {
  switch: Switch,
  checkbox: Checkbox,
} as const;

export const CustomSwitch = ({
  trackingLabel,
  checked,
  label,
  name,
  onChange,
  size = "small",
  style,
  disabled = false,
  switchVariant = "switch",
  className,
  inputRef,
  indeterminate,
  color,
}: ISwitchProps) => {
  const SwitchComponent = switchVariantClasses[switchVariant];

  const track = useAppTracking();

  const onClickWrapper = () => {
    if (trackingLabel === null) return;

    track(Mxp.Event.elementClicked, {
      [Mxp.Property.element.type]: "Switch",
      [Mxp.Property.element.label]: trackingLabel || label?.toString(),
    });
    // onClick is not exposed by the custom Switch component
    //  so we don't need to wrap it for now;
  };

  return (
    <FormControlLabel
      className={className}
      onClick={onClickWrapper}
      checked={checked}
      control={<SwitchComponent size={size} indeterminate={indeterminate} color={color} />}
      label={label}
      name={name}
      onChange={(_e, isChecked) => onChange?.(isChecked)}
      sx={style}
      disabled={disabled}
      inputRef={inputRef}
    />
  );
};
