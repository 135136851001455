import { useQueries } from "@tanstack/react-query";

import { IShift } from "~/routes/api/types";

import { ISchedule } from "../schedule/types";

import { getShiftsByScheduleApi } from "./queries";

export const useShiftTypesForSchedules = (scheduleIds: ISchedule["id"][]) => {
  const results = useQueries({
    queries:
      scheduleIds.map((scheduleId) => {
        return {
          queryKey: ["shiftsBySchedule", scheduleId],
          queryFn: () => getShiftsByScheduleApi(scheduleId || ""),
        };
      }) ?? [],
  });

  // Transform the query results into a dictionary of scheduleId > shiftTypes
  const shiftTypesBySchedule = results.reduce(
    (acc, result, index) => {
      const scheduleId = scheduleIds[index];
      if (result.isSuccess && scheduleId) {
        acc[scheduleId] = result.data;
      }
      return acc;
    },
    {} as { [scheduleId: ISchedule["id"]]: IShift[] },
  );

  return shiftTypesBySchedule;
};

export const useShiftTypesForSchedule = (scheduleId: ISchedule["id"]) => {
  return useShiftTypesForSchedules([scheduleId])?.[scheduleId] || [];
};
